interface Dimensions {
  width: number,
  height: number,
}

export default class ImageLoader {

  imgElement: HTMLImageElement;
  imgCanvas: HTMLCanvasElement;
  imageData: ImageData | undefined;

  constructor(imageUrl: string) {
    this.imgElement = document.createElement("img");
    this.imgCanvas = document.createElement("canvas");
    this.imgElement.src = imageUrl;
  }

  async load(): Promise<ImageData | undefined> {
    return new Promise((resolve, reject) => {
      this.imgElement.onload = () => {
        this.loadCanvas();
        this.loadImageData();
        resolve(this.imageData);
      };
    })
  }

  loadCanvas() {
    this.imgCanvas.width = this.imgElement.width;
    this.imgCanvas.height = this.imgElement.height;
    const ctx = this.imgCanvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(this.imgElement, 0, 0);
    }
  }

  loadImageData() {
    const ctx = this.imgCanvas.getContext('2d');
    if (ctx) {
      this.imageData = ctx.getImageData(0, 0, this.imgCanvas.width, this.imgCanvas.height);
    }
  }

  async dimensions(): Promise<Dimensions> {
    return new Promise((resolve, reject) => {
      this.imgElement.onload = () => {
        this.loadCanvas();
        this.loadImageData();
        resolve({
          width: this.imgElement.naturalWidth,
          height: this.imgElement.naturalHeight,
        });
      };
    })
  }

}