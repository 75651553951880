import * as BABYLON from '@babylonjs/core';
import {
  CurvePath,
  Shape
} from './2dmodels';

class ShapeUtils {
  static area(contour: BABYLON.Vector2[]) {

    const n = contour.length;
    let a = 0.0;

    for (let p = n - 1, q = 0; q < n; p = q++) {
      a += contour[p].x * contour[q].y - contour[q].x * contour[p].y;
    }

    return a * 0.5;
  }

  static isClockWise(pts: BABYLON.Vector2[]) {
    return ShapeUtils.area(pts) < 0;
  }

}

export class ShapePath {

  color?: BABYLON.Color3;
  subPaths: CurvePath[] = [];
  currentPath?: CurvePath;

  moveTo(x: number, y: number) {
    this.currentPath = new CurvePath().moveTo(x, y);
    this.subPaths.push(this.currentPath);
  }

  lineTo(x: number, y: number) {
    if (this.currentPath) {
      this.currentPath.lineTo(x, y);
    } else {
      throw Error("No current path");
    }
  }

  bezierCurveTo(control1X: number, control1Y: number, control2X: number, control2Y: number, x: number, y: number) {
    if (this.currentPath) {
      this.currentPath.bezierCurveTo(control1X, control1Y, control2X, control2Y, x, y);
    } else {
      throw Error("No current path");
    }
  }

  splineThru() {
    // TODO
  }

  toShapesNoHoles(inSubpaths: CurvePath[]): Shape[] {
    const shapes = [];
    for (let i = 0, l = inSubpaths.length; i < l; i++) {
      const tmpPath = inSubpaths[i];
      const tmpShape = new Shape();
      tmpShape.curves = tmpPath.curves;
      shapes.push(tmpShape);
    }
    return shapes;
  }

  isPointInsidePolygon(inPt: BABYLON.Vector2, inPolygon: BABYLON.Vector2[]) {

    const polyLen = inPolygon.length;

    // inPt on polygon contour => immediate success    or
    // toggling of inside/outside at every single! intersection point of an edge
    //  with the horizontal line through inPt, left of inPt
    //  not counting lowerY endpoints of edges and whole edges on that line
    let inside = false;
    for (let p = polyLen - 1, q = 0; q < polyLen; p = q++) {

      let edgeLowPt = inPolygon[p];
      let edgeHighPt = inPolygon[q];

      let edgeDx = edgeHighPt.x - edgeLowPt.x;
      let edgeDy = edgeHighPt.y - edgeLowPt.y;

      if (Math.abs(edgeDy) > Number.EPSILON) {
        // not parallel
        if (edgeDy < 0) {
          edgeLowPt = inPolygon[q]; edgeDx = - edgeDx;
          edgeHighPt = inPolygon[p]; edgeDy = - edgeDy;
        }
        if ((inPt.y < edgeLowPt.y) || (inPt.y > edgeHighPt.y)) continue;
        if (inPt.y === edgeLowPt.y) {
          if (inPt.x === edgeLowPt.x) return true;		// inPt is on contour ?
          // continue;				// no intersection or edgeLowPt => doesn't count !!!
        } else {
          const perpEdge = edgeDy * (inPt.x - edgeLowPt.x) - edgeDx * (inPt.y - edgeLowPt.y);
          if (perpEdge === 0) return true;		// inPt is on contour ?
          if (perpEdge < 0) continue;
          inside = !inside;		// true intersection left of inPt
        }
      } else {
        // parallel or collinear
        if (inPt.y !== edgeLowPt.y) continue;			// parallel
        // edge lies on the same horizontal line as inPt
        if (((edgeHighPt.x <= inPt.x) && (inPt.x <= edgeLowPt.x)) ||
          ((edgeLowPt.x <= inPt.x) && (inPt.x <= edgeHighPt.x))) return true;	// inPt: Point on contour !
        // continue;
      }
    }

    return inside;
  }

  toShapes(isCCW: boolean, noHoles: boolean): Shape[] {
    const isClockWise = ShapeUtils.isClockWise;

    const subPaths = this.subPaths;
    if (subPaths.length === 0) return [];

    if (noHoles === true) return this.toShapesNoHoles(subPaths);


    let solid;
    let tmpPath: CurvePath | undefined;
    let tmpShape: Shape | undefined;
    const shapes = [];

    if (subPaths.length === 1) {

      tmpPath = subPaths[0];
      tmpShape = new Shape();
      tmpShape.curves = tmpPath.curves;
      shapes.push(tmpShape);
      return shapes;

    }

    let holesFirst = !isClockWise(subPaths[0].getPoints());
    holesFirst = isCCW ? !holesFirst : holesFirst;

    // console.log("Holes first", holesFirst);

    const betterShapeHoles: { h: CurvePath, p: BABYLON.Vector2 }[][] = [];
    const newShapes: Array<{ s: Shape, p: BABYLON.Vector2[] } | undefined> = [];
    let newShapeHoles: { h: CurvePath, p: BABYLON.Vector2 }[][] = [];
    let mainIdx = 0;
    let tmpPoints: BABYLON.Vector2[];

    newShapes[mainIdx] = undefined;
    newShapeHoles[mainIdx] = [];

    for (let i = 0, l = subPaths.length; i < l; i++) {

      tmpPath = subPaths[i];
      tmpPoints = tmpPath.getPoints();
      solid = isClockWise(tmpPoints);
      solid = isCCW ? !solid : solid;

      if (solid) {

        if ((!holesFirst) && (newShapes[mainIdx])) mainIdx++;

        const mainNewShape = { s: new Shape(), p: tmpPoints };
        mainNewShape.s.curves = tmpPath.curves;
        newShapes[mainIdx] = mainNewShape;

        if (holesFirst) mainIdx++;
        newShapeHoles[mainIdx] = [];

        //console.log('cw', i);

      } else {

        newShapeHoles[mainIdx].push({ h: tmpPath, p: tmpPoints[0] });

        //console.log('ccw', i);

      }

    }

    // only Holes? -> probably all Shapes with wrong orientation
    if (!newShapes[0]) return this.toShapesNoHoles(subPaths);


    if (newShapes.length > 1) {

      let ambiguous = false;
      const toChange = [];

      for (let sIdx = 0, sLen = newShapes.length; sIdx < sLen; sIdx++) {

        betterShapeHoles[sIdx] = [];

      }

      for (let sIdx = 0, sLen = newShapes.length; sIdx < sLen; sIdx++) {

        const sho = newShapeHoles[sIdx];

        for (let hIdx = 0; hIdx < sho.length; hIdx++) {

          const ho = sho[hIdx];
          let hole_unassigned = true;

          for (let s2Idx = newShapes.length - 1; s2Idx >= 0; s2Idx--) {

            const newShapeS2 = newShapes[s2Idx];
            if (newShapeS2 && this.isPointInsidePolygon(ho.p, newShapeS2.p)) {

              if (sIdx !== s2Idx) toChange.push({ froms: sIdx, tos: s2Idx, hole: hIdx });
              if (hole_unassigned) {

                hole_unassigned = false;
                betterShapeHoles[s2Idx].push(ho);

              } else {

                // ambiguous = true;

              }

            }

          }

          if (hole_unassigned) {

            betterShapeHoles[sIdx].push(ho);

          }

        }

      }
      // console.log("ambiguous: ", ambiguous);

      if (toChange.length > 0) {

        // console.log("to change: ", toChange);
        if (!ambiguous) newShapeHoles = betterShapeHoles;

      }

    }

    let tmpHoles;

    for (let i = 0, il = newShapes.length; i < il; i++) {

      tmpShape = newShapes[i]?.s;
      if (tmpShape) shapes.push(tmpShape);
      tmpHoles = newShapeHoles[i];

      for (let j = 0, jl = tmpHoles.length; j < jl; j++) {

        if (tmpShape)
          tmpShape.holes.push(tmpHoles[j].h);

      }

    }

    //console.log("shape", shapes);

    return shapes;

  }

}