import * as ApiModels from './apiModels';
import {
  dataURLtoBlob
} from './helpers';

import ensureFirebase from '../support/ensureFirebase';
const firebaseApp = ensureFirebase();
import {
  User,
} from 'firebase/auth';

import {
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';

const storage = getStorage(firebaseApp);

export interface ApiClientConfig {
  apiBaseUrl: string;
  hasCheckedAuth: boolean;
  firebaseUser?: User;
}

export type ResponseKind = 'success' | 'notFound' | 'notAuthorized' | 'error';

export interface FetchCreationResponse {
  kind: ResponseKind,
  creation?: ApiModels.Creation,
  labels?: ApiModels.Label[],
  isNewUser?: boolean,
  error?: Error,
}

export interface FetchCreationsResponse {
  kind: ResponseKind,
  creations: ApiModels.Creation[],
  isAtEnd?: boolean,
  error?: Error,
}

export interface FetchVersionsResponse {
  kind: ResponseKind,
  versions: ApiModels.Creation[],
  error?: Error,
}

export interface LabelsInfo {
  creationLabels: ApiModels.Label[],
  allLabels: ApiModels.Label[],
}

export const EmptyLabelsInfo: LabelsInfo = {
  creationLabels: [],
  allLabels: [],
}

export interface FetchLabelsResponse {
  kind: ResponseKind,
  labelsInfo: LabelsInfo,
  error?: Error,
}

export interface FetchLabelResponse {
  kind: ResponseKind,
  label?: ApiModels.Label,
  error?: Error,
}

export interface SubscribeResponse {
  kind: ResponseKind,
  email?: string,
  error?: Error,
}

export interface UpdateCartData {
  items: ApiModels.UpdateCartItem[],
}

export interface CartResponse {
  kind: ResponseKind,
  stripeCustomerId?: string,
  items: ApiModels.ResponseCartItem[],
  error?: Error,
}

export class ApiClient {
  user?: ApiModels.User;
  config: ApiClientConfig;

  constructor(config: ApiClientConfig, user?: ApiModels.User) {
    this.user = user;
    this.config = config;
  }

  hasCheckedAuth(): boolean {
    return this.config.hasCheckedAuth;
  }

  isLoggedIn(): boolean {
    return !!this.config.firebaseUser;
  }

  hasUserInfo(): boolean {
    return !!this.user;
  }

  async fetchCartItems(): Promise<CartResponse> {
    const res = await this.get('cart');
    if (res.status === 404) {
      return { kind: 'notFound', items: [] }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', items: [] }
    } else if (res.status === 200) {
      const json = await res.json();
      return {
        kind: 'success',
        items: json.items as ApiModels.ResponseCartItem[],
        stripeCustomerId: json.stripeCustomerId as string | undefined,
      }
    } else {
      const text = await res.text();
      return { kind: 'error', error: new Error(`${res.status}: ${text}`), items: [] }
    }
  }

  async updateCartItems(items: ApiModels.UpdateCartItem[]): Promise<CartResponse> {
    try {
      const data: UpdateCartData = { items: items };
      const res = await this.post('cart', data);
      if (res.status === 404) {
        return { kind: 'notFound', items: [] }
      } else if (res.status === 403) {
        return { kind: 'notAuthorized', items: [] }
      } else if (res.status === 200) {
        const json = await res.json();
        return {
          kind: 'success',
          items: json.items as ApiModels.ResponseCartItem[],
        }
      } else {
        const text = await res.text();
        return { kind: 'error', error: new Error(`${res.status}: ${text}`), items: [] }
      }
    } catch (err) {
      return { kind: 'error', error: err as Error, items: [] }
    }
  }

  async subscribe(email: string): Promise<SubscribeResponse> {
    try {
      const res = await this.post('subscriptions', { email: email });
      if (res.status === 200) {
        const json = await res.json();
        return {
          kind: 'success',
          email: json.email,
        }
      } else {
        const text = await res.text();
        return {
          kind: 'error',
          error: new Error(`${res.status}: ${text}`)
        }
      }
    } catch (err) {
      return { kind: 'error', error: err as Error }
    }
  }

  async fetchCurrentVersionFor(id: string): Promise<FetchCreationResponse> {
    try {
      const res = await this.get(`creations/${id}`);
      const json = await res.json();
      if (res.status === 404) {
        return { kind: 'notFound', isNewUser: json.isNewUser }
      } else if (res.status === 403) {
        return { kind: 'notAuthorized' }
      } else if (res.status === 200) {
        return { kind: 'success', creation: json.creation as ApiModels.Creation }
      } else {
        const text = await res.text();
        return { kind: 'error', error: new Error(`${res.status}: ${text}`) }
      }
    } catch (err) {
      return { kind: 'error', error: err as Error }
    }
  }

  async fetchCreations(
    before: Date | undefined,
    targetUserId: string | undefined,
    labelId: string | undefined,
  ): Promise<FetchCreationsResponse> {
    const queryParams: { [key: string]: string } = {};
    if (before) queryParams["before"] = before.getTime().toString();
    if (targetUserId) queryParams["user"] = targetUserId;
    if (labelId) queryParams["label"] = labelId;
    const res = await this.get(`creations`, queryParams);
    if (res.status === 404) {
      return { kind: 'notFound', creations: [] }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', creations: [] }
    } else if (res.status === 200) {
      const json = await res.json();
      return json as FetchCreationsResponse;
    } else {
      const text = await res.text();
      return { kind: 'error', creations: [], error: new Error(`${res.status}: ${text}`) }
    }
  }

  async fetchCreationsWithIds(ids: string[]): Promise<ApiModels.Creation[]> {
    const fetches = await Promise.all(ids.map(async ea => {
      return await this.fetchCurrentVersionFor(ea);
    }));
    const creations: ApiModels.Creation[] = [];
    fetches.forEach(ea => {
      if (ea.creation) {
        creations.push(ea.creation);
      }
    });
    return creations;
  }

  async fetchVersion(versionId: string): Promise<FetchCreationResponse> {
    const res = await this.get(`versions/${versionId}`);
    if (res.status === 404) {
      return { kind: 'notFound' }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized' }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', creation: json.creation as ApiModels.Creation }
    } else {
      const text = await res.text();
      return { kind: 'error', error: new Error(`${res.status}: ${text}`) }
    }
  }


  async fetchVersionsFor(creationId: string): Promise<FetchVersionsResponse> {
    const res = await this.get(`creations/${creationId}/versions`);
    if (res.status === 404) {
      return { kind: 'notFound', versions: [] }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', versions: [] }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', versions: json.versions as ApiModels.Creation[] }
    } else {
      const text = await res.text();
      return { kind: 'error', versions: [], error: new Error(`${res.status}: ${text}`) }
    }
  }

  async fetchLabelsFor(creationId: string): Promise<FetchLabelsResponse> {
    const res = await this.get(`creation-labels/${creationId}`);
    if (res.status === 404) {
      return { kind: 'notFound', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', labelsInfo: json.labelsInfo as LabelsInfo }
    } else {
      const text = await res.text();
      return { kind: 'error', labelsInfo: EmptyLabelsInfo, error: new Error(`${res.status}: ${text}`) }
    }
  }

  async fetchLabel(labelId: string): Promise<FetchLabelResponse> {
    const res = await this.get(`labels/${labelId}`);
    if (res.status === 404) {
      return { kind: 'notFound' }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized' }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', label: json.label as ApiModels.Label }
    } else {
      const text = await res.text();
      return { kind: 'error', error: new Error(`${res.status}: ${text}`) }
    }
  }

  async newFromExample(creationId: string): Promise<ApiModels.Creation> {
    const res = await this.post('creations', { exampleId: creationId });
    const json = await res.json();
    return json.creation as ApiModels.Creation;
  }

  async saveLabel(label: ApiModels.Label, creationId: string): Promise<FetchLabelsResponse> {
    const res = await this.post('labels', { label, creationId });
    if (res.status === 404) {
      return { kind: 'notFound', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', labelsInfo: json.labelsInfo as LabelsInfo }
    } else {
      const text = await res.text();
      return { kind: 'error', labelsInfo: EmptyLabelsInfo, error: new Error(`${res.status}: ${text}`) }
    }
  }

  async removeLabel(label: ApiModels.Label, creationId: string): Promise<FetchLabelsResponse> {
    const res = await this.delete(`labels/${label.id}/${creationId}`);
    if (res.status === 404) {
      return { kind: 'notFound', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 403) {
      return { kind: 'notAuthorized', labelsInfo: EmptyLabelsInfo }
    } else if (res.status === 200) {
      const json = await res.json();
      return { kind: 'success', labelsInfo: json.labelsInfo as LabelsInfo }
    } else {
      const text = await res.text();
      return { kind: 'error', labelsInfo: EmptyLabelsInfo, error: new Error(`${res.status}: ${text}`) }
    }
  }

  async saveThumbnailJob(job: ApiModels.ThumbnailJob) {
    await this.post("thumbnailJobs", job);
  }

  async saveThumbnailScreenshotFor(jobId: string, screenshot: string, index: number | undefined) {
    const prefix = `screenshots/${jobId}/`;
    let storagePath: string;
    if (index) {
      const indexPart = index.toString().padStart(2, "0");
      storagePath = `${prefix}}/part-${indexPart}`;
    } else {
      storagePath = `${prefix}}/main.png`;
    }
    const thumbnailRef = ref(storage, storagePath);
    const blob = dataURLtoBlob(screenshot);
    if (blob) await uploadBytes(thumbnailRef, blob);
  }

  async saveCreationVersion(data: ApiModels.Creation): Promise<ApiModels.Creation> {
    const res = await this.post('creations', { creation: data });
    const json = await res.json();
    return json.creation as ApiModels.Creation;
  }

  async cloneCreation(creationId: string): Promise<string> {
    const res = await this.post('clone', { creationId: creationId });
    const json = await res.json();
    return json.cloneId as string;
  }

  async get(endpoint: string, queryParams?: { [key: string]: string }): Promise<Response> {
    return ApiClient.get(endpoint, queryParams || {}, this.config);
  }

  async requestConfig(method: string) {
    const cfg = this.config.firebaseUser ? await ApiClient.requestConfigFor(this.config.firebaseUser, method) : {};
    return Object.assign({}, cfg, { method: method });
  }

  async post(endpoint: string, data: object): Promise<Response> {
    const requestConfig = await this.requestConfig("POST");

    return await fetch(`${this.config.apiBaseUrl}/${endpoint}`, Object.assign({}, requestConfig, {
      body: JSON.stringify(data)
    }));
  }

  async delete(endpoint: string): Promise<Response> {
    const requestConfig = await this.requestConfig('DELETE');
    return fetch(`${this.config.apiBaseUrl}/${endpoint}`, requestConfig);
  }

  static async get(endpoint: string, queryParams: { [key: string]: string }, config: ApiClientConfig): Promise<Response> {
    const requestConfig = await this.requestConfigFor(config.firebaseUser, "GET");
    let url = `${config.apiBaseUrl}/${endpoint}`;
    url += '?' + (new URLSearchParams(queryParams)).toString();
    return fetch(url, Object.assign({}, requestConfig));
  }

  static async requestConfigFor(firebaseUser: User | undefined, method: string): Promise<RequestInit> {
    const cfg: RequestInit = {};
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken();
      if (token) {
        cfg.headers = {
          "Authorization": `Bearer ${token}`
        };
      }
    }
    return Object.assign({}, cfg, { method: method });
  }

  static async fetchUserInfo(config: ApiClientConfig): Promise<ApiModels.User | undefined> {
    return this.get("me", {}, config).then(res => res.json()).catch(err => {
      if (err.status === 404) {
        return undefined;
      } else {
        throw err;
      }
    });
  }

  static async tokenFor(config: ApiClientConfig): Promise<string | undefined> {
    const user = config.firebaseUser;
    if (user) {
      return await user.getIdToken();
    } else {
      return undefined
    }
  }

  static async buildUserFor(config: ApiClientConfig) {
    if (config.firebaseUser) {
      return await this.fetchUserInfo(config);
    } else {
      return undefined;
    }
  }

  static async forFirebaseUser(config: ApiClientConfig): Promise<ApiClient> {
    const fbUser = config.firebaseUser;
    if (fbUser) {
      const user = await this.buildUserFor(config);
      const subscriber = { id: fbUser.uid, email: fbUser.email || "", freeOrderCode: user?.freeOrderCode };
      return new ApiClient(config, subscriber);
    } else {
      return new ApiClient(config, undefined);
    }
  }

  static beforeAuthCheck(apiBaseUrl: string) {
    return new ApiClient({ apiBaseUrl: apiBaseUrl, hasCheckedAuth: false });
  }

}