import * as BABYLON from '@babylonjs/core';
import { debounce } from 'throttle-debounce';

import { Axis, XAxis, YAxis, ZAxis } from './creations/Axis';
import { CreationVersion } from './creations/Creation';
import { selectionBoxName } from './helpers';
import Proportions from './Proportion';

export interface SelectionBoxPoints {
  min: BABYLON.Vector3,
  max: BABYLON.Vector3,
}

export interface InputPoint {
  x: string,
  y: string,
  z: string,
}

export interface InputSelectionBoxPoints {
  min: InputPoint,
  max: InputPoint,
}


interface Props {
  creationVersion: CreationVersion,
  color: BABYLON.Color3,
  minPoint: BABYLON.Vector3,
  maxPoint: BABYLON.Vector3,
  limitedToAxis?: Axis,
  proportions: Proportions,
  onDrag: (minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3) => void,
  onUpdate: (minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3) => void,
}

export function extendSizeFor(minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3, creationExtendSize: BABYLON.Vector3): BABYLON.Vector3 {
  return new BABYLON.Vector3(
    (maxPoint.x - minPoint.x) * creationExtendSize.x,
    (maxPoint.y - minPoint.y) * creationExtendSize.y,
    (maxPoint.z - minPoint.z) * creationExtendSize.z,
  );
}

export function boxDimensionOptionsFor(minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3, creationExtendSize: BABYLON.Vector3) {
  const extendSize = extendSizeFor(minPoint, maxPoint, creationExtendSize);
  return {
    width: extendSize.x * 2,
    height: extendSize.y * 2,
    depth: extendSize.z * 2,
  };
}

export function midPointFor(min: number, max: number, creationExtent: number, creationCenter: number): number {
  const creationMin = creationCenter - creationExtent;
  return creationMin + ((min + ((max - min) / 2)) * creationExtent * 2);
}

export function centerFor(minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3, creationExtendSize: BABYLON.Vector3, creationCenter: BABYLON.Vector3): BABYLON.Vector3 {
  return new BABYLON.Vector3(
    midPointFor(minPoint.x, maxPoint.x, creationExtendSize.x, creationCenter.x),
    midPointFor(minPoint.y, maxPoint.y, creationExtendSize.y, creationCenter.y),
    midPointFor(minPoint.z, maxPoint.z, creationExtendSize.z, creationCenter.z),
  );
}

export default class SelectionBox {
  creationVersion: CreationVersion;
  color: BABYLON.Color3;
  box: BABYLON.Mesh;
  limitedToAxis: Axis | undefined;
  proportions: Proportions;
  dragHandler: (minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3) => void;
  dragEndHandler: (minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3) => void;

  constructor(props: Props) {
    this.creationVersion = props.creationVersion;
    this.color = props.color;
    this.limitedToAxis = props.limitedToAxis;
    this.box = this.add(props.minPoint, props.maxPoint);
    this.proportions = props.proportions;
    this.dragHandler = debounce(20, props.onDrag);
    this.dragEndHandler = props.onUpdate;
  }

  proportionFor(position: number, axisExtendSize: number) {
    const num = (position - (axisExtendSize * -1)) / (axisExtendSize * 2);
    return this.proportions.roundProportion(num);
  }

  scaledExtendSizeFor(axis: Axis, axisExtendSize: number): number {
    const relativeSize = axis.valueFor(this.box.getBoundingInfo().boundingBox.extendSize) / axisExtendSize;
    const scaling = axis.valueFor(this.box.scaling) * relativeSize;
    return axisExtendSize * scaling;
  }

  minFor(axis: Axis, extendSize: BABYLON.Vector3): number {
    const axisExtendSize = axis.valueFor(extendSize);
    const position = axis.valueFor(this.box.position);
    const minPosition = position - this.scaledExtendSizeFor(axis, axisExtendSize);
    return this.proportionFor(minPosition, axisExtendSize);
  }

  maxFor(axis: Axis, extendSize: BABYLON.Vector3): number {
    const axisExtendSize = axis.valueFor(extendSize);
    const position = axis.valueFor(this.box.position);
    const maxPosition = position + this.scaledExtendSizeFor(axis, axisExtendSize);
    return this.proportionFor(maxPosition, axisExtendSize);
  }

  minPointFromMesh(): BABYLON.Vector3 {
    const creationExtendSize = this.creationVersion.decorationMeshesInfo.extendSize;
    return new BABYLON.Vector3(
      this.minFor(new XAxis(), creationExtendSize),
      this.minFor(new YAxis(), creationExtendSize),
      this.minFor(new ZAxis(), creationExtendSize)
    );
  }

  maxPointFromMesh(): BABYLON.Vector3 {
    const creationExtendSize = this.creationVersion.decorationMeshesInfo.extendSize;
    return new BABYLON.Vector3(
      this.maxFor(new XAxis(), creationExtendSize),
      this.maxFor(new YAxis(), creationExtendSize),
      this.maxFor(new ZAxis(), creationExtendSize)
    );
  }

  onDrag() {
    const minPoint = this.minPointFromMesh();
    const maxPoint = this.maxPointFromMesh();
    this.dragHandler(minPoint, maxPoint);
  }

  onDragEnd() {
    const minPoint = this.minPointFromMesh();
    const maxPoint = this.maxPointFromMesh();
    this.dragEndHandler(minPoint, maxPoint);
  }

  axisFactor(): BABYLON.Vector3 {
    const es = this.creationVersion.decorationMeshesInfo.extendSize;
    const factorForExtent = (extent: number) => Math.min(10, 50 / extent);
    if (this.limitedToAxis) {
      return this.limitedToAxis.directionVector.scale(factorForExtent(this.limitedToAxis.valueFor(es)));
    } else {
      return new BABYLON.Vector3(
        factorForExtent(es.x),
        factorForExtent(es.y),
        factorForExtent(es.z),
      );
    }
  }

  add(minPoint: BABYLON.Vector3, maxPoint: BABYLON.Vector3): BABYLON.Mesh {
    const info = this.creationVersion.decorationMeshesInfo;

    const scene = this.creationVersion.scene;
    const existing = scene.getMeshByName(selectionBoxName);
    if (existing) existing.dispose(false, true);

    const creationExtendSize = info.extendSize;
    const options = boxDimensionOptionsFor(minPoint, maxPoint, creationExtendSize)
    const box = BABYLON.MeshBuilder.CreateBox(selectionBoxName, options, scene);
    box.layerMask = 0x10000000;
    const center = centerFor(minPoint, maxPoint, info.extendSize, info.center);
    box.position = center;
    box.visibility = 0.5;
    const material = new BABYLON.StandardMaterial(`${selectionBoxName}-material`, scene);
    material.diffuseColor = this.color;
    box.material = material;


    const utilLayer = new BABYLON.UtilityLayerRenderer(scene);
    const boundingBoxGizmo = new BABYLON.BoundingBoxGizmo(this.color, utilLayer);
    boundingBoxGizmo.scaleBoxSize = 0.075;
    boundingBoxGizmo.rotationSphereSize = 0;
    boundingBoxGizmo.fixedDragMeshScreenSize = true;
    boundingBoxGizmo.scaleDragSpeed = 1;
    boundingBoxGizmo._rootMesh.getChildMeshes().forEach(ea => {
      if (ea instanceof BABYLON.LinesMesh) {
        ea.setEnabled(false);
      }
    });
    boundingBoxGizmo.axisFactor = this.axisFactor();
    boundingBoxGizmo.onScaleBoxDragEndObservable.add(() => this.onDragEnd());
    boundingBoxGizmo.onScaleBoxDragObservable.add(() => this.onDrag())
    boundingBoxGizmo.attachedMesh = box;

    box.onDispose = () => {
      boundingBoxGizmo.dispose();
    }

    return box;
  }

  remove() {
    this.box.dispose();
  }

}