import { CreationVersion } from './Creation';
import { DefaultMaterial, Material } from './Material';

import DirectOptionSettingView from '../../pcomponents/DirectOptionSettingView';
import IncrementalOptionSettingView from '../../pcomponents/IncrementalOptionSettingView';

import { State } from '../states';

import {
  SemanticICONS
} from 'semantic-ui-react';

export interface Setting<T> {
  name: string,
  label: string,
  isMaterial: boolean,
  showProgressState: (value: T) => State | undefined,
}

export interface GenericSetting<T> extends Setting<T> {
  renderFor: (
    creationVersion: CreationVersion,
    onSetCreationVersion: (creationFn: () => Promise<CreationVersion>) => void,
    onSetState: (state: State | undefined) => void
  ) => void,
}

export interface DirectSettingOption {
  kind: string,
  icon: SemanticICONS | string,
  label: string,
}

function capitalize(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

export interface DirectOptionSettingConstructorProps<T extends DirectSettingOption> {
  name: string,
  label?: string,
  options: T[],
  value: T | undefined,
  showProgressState?: (option: T) => State | undefined,
  onSelect: (option: T) => Promise<CreationVersion>,
}

export class DirectOptionSetting<T extends DirectSettingOption> implements GenericSetting<T> {
  name: string;
  label: string;
  isMaterial: boolean = false;
  options: T[];
  value: T | undefined;
  showProgressState: (option: T) => State | undefined;
  onSelect: (option: T) => Promise<CreationVersion>;

  constructor(props: DirectOptionSettingConstructorProps<T>) {
    this.name = props.name;
    this.label = props.label || capitalize(props.name);
    this.options = props.options;
    this.value = props.value;
    this.showProgressState = props.showProgressState || ((_: T) => undefined);
    this.onSelect = props.onSelect;
  }

  renderFor(
    creationVersion: CreationVersion,
    onSetCreationVersion: (creationFn: () => Promise<CreationVersion>) => void,
    onSetState: (state: State | undefined) => void
  ) {
    return (
      <DirectOptionSettingView
        key={`setting-option-${this.name}`}
        creationVersion={creationVersion}
        setting={this}
        onSetCreationVersion={onSetCreationVersion}
        onSetState={onSetState}
      />
    );
  }

}

export interface IncrementalOptionSettingConstructorProps<T> {
  name: string,
  label?: string,
  options: T[],
  value: T | undefined,
  showProgressState?: (option: T) => State | undefined,
  onSelect: (option: T) => CreationVersion,
}

export class IncrementalOptionSetting<T> implements GenericSetting<T> {
  name: string;
  label: string;
  isMaterial: boolean = false;
  options: T[];
  value: T | undefined;
  showProgressState: (value: any) => State | undefined;
  onSelect: (option: T) => CreationVersion;

  constructor(props: IncrementalOptionSettingConstructorProps<T>) {
    this.name = props.name;
    this.label = props.label || capitalize(props.name);
    this.options = props.options;
    this.value = props.value;
    this.showProgressState = props.showProgressState || ((_: T) => undefined);
    this.onSelect = props.onSelect;
  }

  selectedIndex(): number {
    return this.value ? this.options.indexOf(this.value) : -1;
  }

  renderFor(creationVersion: CreationVersion, onSetCreationVersion: (creationFn: () => Promise<CreationVersion>) => void) {
    return (
      <IncrementalOptionSettingView
        key={`setting-option-${this.name}`}
        creationVersion={creationVersion}
        setting={this}
        onSetCreationVersion={onSetCreationVersion}
      />
    );
  }

}

export interface MaterialSettingConstructorProps {
  name: string,
  label?: string,
  options: Material[],
  value: Material | undefined,
  showProgressState?: (option: Material) => State | undefined,
  onSelect: (option: Material) => CreationVersion,
}

export class MaterialSetting implements Setting<Material> {
  name: string;
  label: string;
  isMaterial: boolean = true;
  options: Material[];
  value: Material;
  showProgressState: (value: any) => State | undefined;
  onSelect: (option: Material) => CreationVersion;

  constructor(props: MaterialSettingConstructorProps) {
    this.name = props.name;
    this.label = props.label || capitalize(props.name);
    this.options = props.options;
    this.value = props.value || DefaultMaterial;
    this.showProgressState = props.showProgressState || ((_: Material) => undefined);
    this.onSelect = props.onSelect;
  }

}

export function isMaterialSetting<T>(setting: Setting<any>): setting is MaterialSetting {
  return setting.isMaterial;
}

export function isGenericSetting(setting: Setting<any>): setting is GenericSetting<any> {
  return !setting.isMaterial;
}