import short from 'short-uuid';
import isUUID from 'is-uuid';

const translator = short(short.constants.flickrBase58, {
  consistentLength: false,
});

export default function newID(): string {
  return translator.new();
}

function isShortUUID(str: string): boolean {
  return str.length === 22 && isUUID.v4(translator.toUUID(str));
}

export function isID(str: string): boolean {
  return isUUID.v4(str) || isShortUUID(str);
}