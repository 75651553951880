import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';
import newID from './ids';

export function uniqueName(prefix: string): string {
  return `${prefix}-${newID()}`;
}

export function newModelName(): string {
  return uniqueName('model');
}

export function newMaterialName(): string {
  return uniqueName('material');
}

export function uniqueReadableName(): string {
  return uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals], separator: ' ', style: 'capital' });
}