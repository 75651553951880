import * as BABYLON from '@babylonjs/core';

import {
  IconProps,
  SemanticShorthandItem,
} from 'semantic-ui-react'

import { DecorationMeshesInfo, MaterialMesh, MaterialMeshGroups } from './Creation';
import { getMaterial, Material } from './Material';
import { Axis } from './Axis';
import { ShapesInfo, TraceProfile } from './Profile';
import {
  areEqualish,
  extractNumericAttribute,
  flippedSVG,
  intersect,
  isMeshBigEnough,
  subtract,
  svgElementFor,
  unionAll,
  union,
} from '../helpers';
import { QualityLevel } from '../models';
import newID from '../ids';
import * as ApiModels from '../apiModels';
import { newModelName } from '../uniqueName';

export interface RefinementMeshConstructorProps {
  meshes: MaterialMesh[],
  unionMesh: MaterialMesh,
  blocks: MaterialMesh[],
  blocksCSG?: BABYLON.CSG,
  applicationType: RefinementApplicationType,
}

export class RefinementMesh {
  meshes: MaterialMesh[];
  blocks: MaterialMesh[];
  blocksCSG: BABYLON.CSG;
  unionMesh: MaterialMesh;
  unionCSG: BABYLON.CSG;
  biggerToIntersectCSG: BABYLON.CSG;
  applicationType: RefinementApplicationType;

  constructor(props: RefinementMeshConstructorProps) {
    this.meshes = props.meshes;
    this.blocks = props.blocks;
    this.blocksCSG = props.blocksCSG || unionAll(props.blocks.map(ea => BABYLON.CSG.FromMesh(ea.mesh)));
    this.unionMesh = props.unionMesh;
    this.unionCSG = BABYLON.CSG.FromMesh(this.unionMesh.mesh);
    this.biggerToIntersectCSG = this.blocksCSG.union(this.unionCSG);
    this.applicationType = props.applicationType;
  }

  addMeshNamePrefix(prefix: string) {
    this.meshes.forEach(ea => ea.mesh.name = `${prefix}${ea.mesh.name}`);
    this.unionMesh.mesh.name = `${prefix}${this.unionMesh.mesh.name}`;
  }

  setEnabled(isEnabled: boolean) {
    this.meshes.forEach(ea => ea.mesh.setEnabled(isEnabled));
    this.blocks.forEach(ea => ea.mesh.setEnabled(isEnabled));
    this.unionMesh.mesh.setEnabled(isEnabled);
  }

  dispose() {
    this.meshes.forEach(ea => ea.mesh.dispose());
    this.unionMesh.mesh.dispose();
    this.blocks.forEach(ea => ea.mesh.dispose());
  }

  flippedMesh(m: MaterialMesh): MaterialMesh {
    const flipped = m.mesh.clone(newModelName());
    flipped.makeGeometryUnique();
    flipped.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);
    flipped.bakeCurrentTransformIntoVertices();
    return {
      mesh: flipped,
      material: m.material,
    };
  }

  flipped(): RefinementMesh {
    return new RefinementMesh({
      meshes: this.meshes.map(this.flippedMesh),
      unionMesh: this.flippedMesh(this.unionMesh),
      blocks: this.blocks.map(this.flippedMesh),
      applicationType: this.applicationType,
    });
  }
}

export type RefinementApplicationType = 'surface' | 'shape' | 'material';

export function iconFor(applicationType: RefinementApplicationType): SemanticShorthandItem<IconProps> {
  switch (applicationType) {
    case 'material':
      return 'paint brush'
    case 'shape':
      return 'star half';
    case 'surface':
      return 'star outline';
  }
}

interface RefinementConstructorProps {
  id: string,
  axis: Axis,
  svg: SVGSVGElement | undefined,
  minProportion: number,
  maxProportion: number,
  applicationType: RefinementApplicationType,
  isMirrored: boolean,
  isMirrorFlipped: boolean,
  isDisabled: boolean,
  material: Material | undefined,
  templateNotes: ApiModels.ProfileRefinementTemplateNotes | undefined,
  refinementMeshesCache?: RefinementMesh[] | undefined,
}

export interface RefinementCloneProps {
  id?: string,
  axis?: Axis,
  imageFile?: File,
  svg?: SVGSVGElement,
  rotations?: number,
  minProportion?: number,
  maxProportion?: number,
  applicationType?: RefinementApplicationType,
  isMirrored?: boolean,
  isMirrorFlipped?: boolean,
  isDisabled?: boolean,
  material?: Material,
  templateNotes?: ApiModels.ProfileRefinementTemplateNotes,
  refinementMeshesCache?: RefinementMesh[],
}

export class TraceProfileRefinement {
  id: string;
  axis: Axis;
  svg: SVGSVGElement | undefined;
  shapesInfo: ShapesInfo | undefined;
  minProportion: number;
  maxProportion: number;
  applicationType: RefinementApplicationType;
  isMirrored: boolean;
  isMirrorFlipped: boolean;
  isDisabled: boolean;
  material: Material | undefined;
  templateNotes: ApiModels.ProfileRefinementTemplateNotes | undefined;
  refinementMeshesCache: RefinementMesh[] | undefined;

  constructor(props: RefinementConstructorProps) {
    this.id = props.id;
    this.axis = props.axis;
    this.svg = props.svg;
    this.minProportion = props.minProportion;
    this.maxProportion = props.maxProportion;
    this.applicationType = props.applicationType;
    this.isMirrored = props.isMirrored;
    this.isMirrorFlipped = props.isMirrorFlipped;
    this.isDisabled = props.isDisabled;
    this.material = props.material;
    this.templateNotes = props.templateNotes;
    this.refinementMeshesCache = props.refinementMeshesCache;
  }

  ensurePreparedForRender(profile: TraceProfile, qualityLevel: QualityLevel) {
    let svgToUse = this.svg || profile.svg;
    if (this.shouldUseFlippedSVG()) {
      svgToUse = flippedSVG(svgToUse);
    }
    if (!this.shapesInfo && svgToUse) {
      this.shapesInfo = ShapesInfo.buildFor(svgToUse, profile.rotations, qualityLevel);
    }
  }

  shouldApply(qualityLevel: QualityLevel): boolean {
    return !this.isDisabled && (qualityLevel !== 'Lowest' || this.applicationType !== 'surface');
  }

  svgCenterProportion(parentSVG: SVGSVGElement): BABYLON.Vector2 | undefined {
    let result: BABYLON.Vector2 | undefined;
    const svg = this.svg;
    if (svg) {
      document.body.appendChild(parentSVG);
      const parentBbox = parentSVG.getBBox();
      document.body.removeChild(parentSVG);
      document.body.appendChild(svg);
      const bbox = svg.getBBox();
      document.body.removeChild(svg);
      const xCenter = bbox.x + (bbox.width / 2);
      const xProportion = (parentBbox.x + parentBbox.width - xCenter) / parentBbox.width;
      const yCenter = bbox.y + (bbox.height / 2);
      const yProportion = (parentBbox.y + parentBbox.height - yCenter) / parentBbox.height;
      result = new BABYLON.Vector2(xProportion, yProportion);
    }
    return result;
  }

  positionFor(
    center: BABYLON.Vector3,
    extendSize: BABYLON.Vector3,
    minProportion: number,
    maxProportion: number,
    rotations: number,
    parentSVG?: SVGSVGElement,
  ): BABYLON.Vector3 {
    return this.axis.positionFor(this, center, extendSize, minProportion, maxProportion, rotations, parentSVG);
  }

  dimensionOptionsFor(es: BABYLON.Vector3) {
    const proportion = this.maxProportion - this.minProportion;
    const axis = this.axis;
    return {
      width: axis.widthDimensionFor(es, proportion),
      height: axis.heightDimensionFor(es, proportion),
      depth: axis.depthDimensionFor(es, proportion),
    };
  }

  minPointFor(info: DecorationMeshesInfo): BABYLON.Vector3 {
    return this.axis.directionVector.scale(this.minProportion);
  }

  maxPointFor(info: DecorationMeshesInfo): BABYLON.Vector3 {
    return this.axis.scalingVectorFor(this.maxProportion);
  }

  clone(props: RefinementCloneProps): TraceProfileRefinement {
    const materialToUse = Object.keys(props).includes("material") ? props.material : this.material; // can be set to undefined
    return new TraceProfileRefinement({
      id: props.id || this.id,
      axis: props.axis || this.axis,
      svg: props.svg || this.svg,
      minProportion: props.minProportion === undefined ? this.minProportion : props.minProportion,
      maxProportion: props.maxProportion === undefined ? this.maxProportion : props.maxProportion,
      applicationType: props.applicationType === undefined ? this.applicationType : props.applicationType,
      isMirrored: props.isMirrored === undefined ? this.isMirrored : props.isMirrored,
      isMirrorFlipped: props.isMirrorFlipped === undefined ? this.isMirrorFlipped : props.isMirrorFlipped,
      isDisabled: props.isDisabled === undefined ? this.isDisabled : props.isDisabled,
      material: materialToUse,
      templateNotes: props.templateNotes === undefined ? this.templateNotes : props.templateNotes,
      refinementMeshesCache: props.refinementMeshesCache,
    });
  }

  cloneWithProportions(minProportion: number, maxProportion: number): TraceProfileRefinement {
    return this.clone({
      minProportion: minProportion,
      maxProportion: maxProportion,
    });
  }

  cloneWithAxis(axis: Axis): TraceProfileRefinement {
    return this.clone({
      axis: axis,
    });
  }

  cloneWithoutShapesInfo(): TraceProfileRefinement {
    return this.clone({});
  }

  cloneWithoutCache(): TraceProfileRefinement {
    return this.clone({ refinementMeshesCache: undefined });
  }

  cloneFlipped(): TraceProfileRefinement {
    const svg = this.svg ? flippedSVG(this.svg) : undefined;
    return this.clone({ svg });
  }

  cloneAsReflection(shouldFlip: boolean): TraceProfileRefinement {
    let svg: SVGSVGElement | undefined;
    if (this.svg) {
      svg = shouldFlip ? flippedSVG(this.svg) : this.svg.cloneNode(true) as SVGSVGElement;
    }
    return this.clone({
      minProportion: 1 - this.maxProportion,
      maxProportion: 1 - this.minProportion,
      svg: svg,
    });
  }

  async duplicate(profile: TraceProfile): Promise<TraceProfileRefinement> {
    const newId = newID();
    return await buildRefinementFrom(
      profile.axis,
      newId,
      this.minProportion,
      this.maxProportion,
      this.applicationType,
      this.isMirrored,
      this.isMirrorFlipped,
      this.isDisabled,
      this.material,
      this.svg,
      undefined,
    )
  }

  vectorsForPath(path: BABYLON.Vector2[], shouldReverse: boolean, scale: number): BABYLON.Vector3[] {
    const vectors = this.axis.vectorsFor(path).map(ea => ea.multiplyByFloats(-1, 1, 1).scale(scale));
    return shouldReverse ? vectors.reverse() : vectors;
  }

  minProportionToUse(): number {
    let result = Math.max(0, this.minProportion);
    if (this.applicationType === 'surface') {
      return result >= 0.5 ? 0.5 : 0;
    } else {
      return result;
    }
  }

  maxProportionToUse(): number {
    let result = Math.min(1, this.maxProportion);
    if (this.applicationType === 'surface') {
      return result <= 0.5 ? 0.5 : 1;
    } else {
      return result;
    }
  }

  shouldUseFlippedSVG(): boolean {
    return areEqualish(this.minProportionToUse(), 0) && this.maxProportionToUse() < 1 && !this.isMirrored;
  }

  materialToUse(): Material | undefined {
    return this.applicationType === 'surface' ? undefined : this.material;
  }

  reflection(): TraceProfileRefinement {
    return this.cloneAsReflection(this.isMirrorFlipped);
  }

  depthFor(mainMeshExtendSize: BABYLON.Vector3): number {
    const mainMeshExtent = this.axis.valueFor(mainMeshExtendSize);
    return mainMeshExtent * 2 * (this.maxProportionToUse() - this.minProportionToUse());
  }

  buildMeshesForDepth(
    depth: number,
    scene: BABYLON.Scene,
    boundingBox: BABYLON.BoundingBox,
    scale: number,
    useHoles: boolean,
    rotations: number,
    parentSVG: SVGSVGElement,
  ): MaterialMesh[] {
    const es = boundingBox.extendSize;
    let scaleToUse = scale;
    const svgToUse = this.svg || parentSVG;
    if (svgToUse) {
      const svgWidth = extractNumericAttribute('width', svgToUse);
      const parentWidth = extractNumericAttribute('width', parentSVG);
      if (svgWidth && parentWidth) {
        scaleToUse = scale * parentWidth / svgWidth;
      }
    }
    return (this.shapesInfo?.shapes || []).map(ea => {
      const options = {
        shape: this.vectorsForPath(ea.path, false, scaleToUse),
        holes: useHoles ? ea.holes.map(ea => this.vectorsForPath(ea, true, scaleToUse)) : [],
        depth: depth,
        sideOrientation: BABYLON.Mesh.FRONTSIDE,
        wrap: true
      }
      const name = newModelName();
      const mesh = BABYLON.MeshBuilder.ExtrudePolygon(name, options, scene, require('earcut'));
      mesh.translate(BABYLON.Vector3.Up(), depth / 2);

      mesh.bakeCurrentTransformIntoVertices();
      this.axis.rotate(mesh);
      const position = this.positionFor(boundingBox.center, es, this.minProportionToUse(), this.maxProportionToUse(), rotations, parentSVG);
      if (this.shouldUseFlippedSVG()) {
        const proportion = this.svgCenterProportion(parentSVG);
        const offset = proportion ? Math.abs(0.5 - proportion.x) * this.axis.xAxis().valueFor(es) * 2 : 0;
        const offsetVector = this.axis.xAxis().directionVector.scale(offset);
        position.subtractInPlace(offsetVector);
      }
      mesh.position = position;
      mesh.bakeCurrentTransformIntoVertices();
      return {
        mesh: mesh,
        material: this.materialToUse()
      };
    });
  }

  surfaceDepthFor(mainMeshExtendSize: BABYLON.Vector3): number {
    const extent = this.axis.valueFor(mainMeshExtendSize);
    return Math.min(Math.max(0.01 * extent, 1), 0.5 * extent);
  }

  buildMeshesForSurface(
    scene: BABYLON.Scene,
    mainMesh: MaterialMesh,
    scale: number,
    useHoles: boolean,
    rotations: number,
    parentSVG: SVGSVGElement,
  ): MaterialMesh[] {
    const bb = mainMesh.mesh.getBoundingInfo().boundingBox;
    const es = bb.extendSize;
    const shapeMeshes = this.buildMeshesForDepth(
      this.axis.valueFor(es),
      scene,
      bb,
      scale,
      useHoles,
      rotations,
      parentSVG
    );
    const shapeMeshesCSG = unionAll(shapeMeshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh)));
    shapeMeshes.forEach(ea => ea.mesh.dispose());
    const shifted = mainMesh.mesh.clone(newModelName());
    let shift = this.surfaceDepthFor(es) * -1;
    if (areEqualish(this.maxProportionToUse(), 0.5)) shift = -shift;
    shifted.position = this.axis.directionVector.multiplyByFloats(shift, shift, shift);
    const surfaceSliceCSG = BABYLON.CSG.FromMesh(mainMesh.mesh).subtract(BABYLON.CSG.FromMesh(shifted));
    const fillInCSG = subtract(BABYLON.CSG.FromMesh(shifted), surfaceSliceCSG);
    const resultMeshes = [{
      mesh: union(shapeMeshesCSG, fillInCSG).toMesh(newModelName(), undefined, scene)
    }];
    shifted.dispose();

    return resultMeshes;
  }

  buildMeshes(
    scene: BABYLON.Scene,
    mainMesh: MaterialMesh,
    scale: number,
    useHoles: boolean,
    rotations: number,
    parentSVG: SVGSVGElement,
  ): MaterialMesh[] {
    if (this.applicationType === 'surface') {
      return this.buildMeshesForSurface(
        scene,
        mainMesh,
        scale,
        useHoles,
        rotations,
        parentSVG,
      );
    } else {
      const bb = mainMesh.mesh.getBoundingInfo().boundingBox;
      const es = bb.extendSize;
      return this.buildMeshesForDepth(
        this.depthFor(es),
        scene,
        bb,
        scale,
        useHoles,
        rotations,
        parentSVG
      );
    }
  }

  buildUnionMesh(
    scene: BABYLON.Scene,
    refinementMeshes: MaterialMesh[],
  ): MaterialMesh {
    const csg = unionAll(refinementMeshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh)));
    return {
      mesh: csg.toMesh(newModelName(), undefined, scene),
    };
  }

  buildBlockMeshes(
    scene: BABYLON.Scene,
    mainMesh: MaterialMesh,
    rotations: number,
  ): MaterialMesh[] {
    const bb = mainMesh.mesh.getBoundingInfo().boundingBox;
    const es = bb.extendSize;
    const blocks: BABYLON.Mesh[] = [];
    if (this.minProportionToUse() > 0) {
      const proportion = this.minProportionToUse();
      const block = BABYLON.MeshBuilder.CreateBox(newModelName(), {
        width: this.axis.widthDimensionFor(es, proportion),
        height: this.axis.heightDimensionFor(es, proportion),
        depth: this.axis.depthDimensionFor(es, proportion),
      }, scene);
      block.position = this.positionFor(bb.center, es, 0, proportion, rotations);
      block.bakeCurrentTransformIntoVertices();
      const sectionCSG = intersect(BABYLON.CSG.FromMesh(mainMesh.mesh), BABYLON.CSG.FromMesh(block))
      block.dispose();
      blocks.push(sectionCSG.toMesh(newModelName(), undefined, scene));
    }
    if (this.maxProportionToUse() < 1) {
      const proportion = 1 - this.maxProportionToUse();
      const block = BABYLON.MeshBuilder.CreateBox(newModelName(), {
        width: this.axis.widthDimensionFor(es, proportion),
        height: this.axis.heightDimensionFor(es, proportion),
        depth: this.axis.depthDimensionFor(es, proportion),
      }, scene);
      block.position = this.positionFor(bb.center, es, 1 - proportion, 1, rotations);
      block.bakeCurrentTransformIntoVertices();
      const sectionCSG = intersect(BABYLON.CSG.FromMesh(mainMesh.mesh), BABYLON.CSG.FromMesh(block));
      block.dispose();
      blocks.push(sectionCSG.toMesh(newModelName(), undefined, scene));
    }
    return blocks.map(ea => {
      return {
        mesh: ea,
      };
    });
  }

  mainMeshFor(mainMeshes: MaterialMesh[], scene: BABYLON.Scene): MaterialMesh {
    if (mainMeshes.length === 1) {
      return mainMeshes[0];
    } else {
      const mainMeshUnion = unionAll(mainMeshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh))).toMesh(newModelName(), undefined, scene);
      return {
        mesh: mainMeshUnion,
      }
    }
  }

  buildMesh(
    scene: BABYLON.Scene,
    mainMeshes: MaterialMesh[],
    scale: number,
    useHoles: boolean,
    rotations: number,
    parentSVG: SVGSVGElement,
  ): RefinementMesh | undefined {
    const mainMesh = this.mainMeshFor(mainMeshes, scene);
    const meshes = this.buildMeshes(scene, mainMesh, scale, useHoles, rotations, parentSVG);
    if (meshes.length > 0) {
      const blocks = this.buildBlockMeshes(scene, mainMesh, rotations);
      const union = this.buildUnionMesh(scene, meshes);
      let standaloneMeshes = meshes;
      if (!this.materialToUse()) {
        standaloneMeshes = [];
        meshes.forEach(ea => ea.mesh.dispose());
      } else {
        standaloneMeshes = standaloneMeshes.map(ea => {
          const m = intersect(BABYLON.CSG.FromMesh(ea.mesh), BABYLON.CSG.FromMesh(mainMesh.mesh)).toMesh(newModelName(), undefined, scene);
          ea.mesh.dispose();
          return {
            mesh: m,
            material: ea.material,
          };
        });
      }
      return new RefinementMesh({
        meshes: standaloneMeshes,
        unionMesh: union,
        blocks: blocks,
        applicationType: this.applicationType,
      })
    } else {
      return undefined;
    }
  }

  buildMaterialMeshes(
    existing: MaterialMeshGroups,
    unionCSG: BABYLON.CSG,
    scene: BABYLON.Scene,
    profile: TraceProfile,
  ): MaterialMeshGroups {
    const parentShapesInfo = profile.shapesInfo;
    const parentSVG = profile.svg;
    if (parentShapesInfo && parentSVG) {
      const bb = existing.boundingBox();
      const axisNamesToConsider = this.axis.perpendicularAxisNames;
      const targetExtent = this.axis.maxPerpendicularExtentFor(bb.extendSize, axisNamesToConsider);
      const myExtent = parentShapesInfo.maxExtentFor(this.axis, targetExtent) / 2;
      const scale = targetExtent.value / myExtent;
      // Hack to avoid degenerate cases with CSG on shapes with holes (their winding order is reversed which breaks CSG)
      // Could potentially replace by doing the holes themselves with CSG but maybe there's a better solution
      const useHoles = true; //areEqualish(this.minProportionToUse(), 0) && areEqualish(this.maxProportionToUse(), 1);
      const meshes = this.buildMeshesForDepth(this.depthFor(bb.extendSize), scene, bb, scale, useHoles, profile.rotations || 0, parentSVG);
      const meshCSG = unionAll(meshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh)));
      const result = new MaterialMeshGroups([]);
      const intersectionCSG = intersect(meshCSG, unionCSG);
      const intersection = intersectionCSG.toMesh(newModelName(), undefined, scene);
      result.add([{
        material: this.material,
        mesh: intersection,
      }]);
      existing.groups.forEach(eaGroup => {
        const newGroup: MaterialMesh[] = [];
        eaGroup.forEach(ea => {
          if (ea.mesh.subMeshes) {
            const csg = BABYLON.CSG.FromMesh(ea.mesh);
            const diff = subtract(csg, meshCSG).toMesh(newModelName(), undefined, scene);
            if (diff.subMeshes && isMeshBigEnough(diff)) {
              newGroup.push({
                material: ea.material,
                mesh: diff,
              });
            }

            ea.mesh.dispose();
          }
        });
        result.add(newGroup);
      });

      meshes.forEach(ea => ea.mesh.dispose());
      return result;
    } else {
      return existing;
    }
  }

  refinementMeshesFor(
    profile: TraceProfile,
    mainMeshes: MaterialMesh[],
    scale: number,
    useHoles: boolean,
    qualityLevel: QualityLevel,
    scene: BABYLON.Scene,
    useCache: boolean,
  ): RefinementMesh[] {
    if (useCache && this.refinementMeshesCache) {
      this.refinementMeshesCache.forEach(ea => ea.setEnabled(true));
      return this.refinementMeshesCache;
    }

    const refMeshes: RefinementMesh[] = [];
    let toRender: TraceProfileRefinement = this;
    if (this.applicationType === 'surface') {
      if (this.isMirrorFlipped)
        toRender = this.cloneAsReflection(true);
    } else if (this.isMirrorFlipped) {
      toRender = this.cloneFlipped();
    }
    toRender.ensurePreparedForRender(profile, qualityLevel);
    const refMesh = toRender.buildMesh(scene, mainMeshes, scale, useHoles, profile.rotations || 0, profile.svg);
    if (refMesh) refMeshes.push(refMesh);
    if (toRender.isMirrored) {
      const reflection = toRender.reflection();
      reflection.ensurePreparedForRender(profile, qualityLevel)
      const reflectionMesh = reflection.buildMesh(scene, mainMeshes, scale, useHoles, profile.rotations || 0, profile.svg);
      if (reflectionMesh) refMeshes.push(reflectionMesh);
    }

    if (useCache) {
      refMeshes.forEach(ea => ea.addMeshNamePrefix('cache-'));
      this.refinementMeshesCache = refMeshes;
    }
    return refMeshes;
  }

  toApiProps(): ApiModels.ProfileRefinement {
    return {
      id: this.id,
      minProportion: this.minProportion,
      maxProportion: this.maxProportion,
      applicationType: this.applicationType,
      isSurface: this.applicationType === 'surface',
      isMirrored: this.isMirrored,
      isMirrorFlipped: this.isMirrorFlipped,
      isDisabled: this.isDisabled,
      material: this.material?.kind,
      svg: this.svg?.outerHTML,
      templateNotes: this.templateNotes,
    }
  }
}



export async function buildRefinementFrom(
  axis: Axis,
  refinementId: string,
  minProportion: number,
  maxProportion: number,
  applicationType: RefinementApplicationType,
  isMirrored: boolean,
  isMirrorFlipped: boolean,
  isDisabled: boolean,
  material: Material | undefined,
  svg: SVGSVGElement | undefined,
  templateNotes: ApiModels.ProfileRefinementTemplateNotes | undefined,
): Promise<TraceProfileRefinement> {
  return new TraceProfileRefinement({
    id: refinementId,
    axis,
    svg,
    minProportion,
    maxProportion,
    applicationType,
    isMirrored,
    isMirrorFlipped,
    isDisabled,
    material,
    templateNotes,
  });
}

export async function buildRefinementsFrom(
  axis: Axis,
  data: ApiModels.ProfileRefinement[] | undefined,
): Promise<TraceProfileRefinement[]> {
  if (data) {
    return await Promise.all(data.map(async ea => {
      return await buildRefinementFrom(
        axis,
        ea.id,
        ea.minProportion,
        ea.maxProportion,
        ApiModels.applicationTypeFor(ea),
        !!ea.isMirrored,
        !!ea.isMirrorFlipped,
        !!ea.isDisabled,
        ea.material ? getMaterial(ea.material) : undefined,
        svgElementFor(ea.svg),
        ea.templateNotes,
      );
    }));
  } else {
    return [];
  }
}
