import {
  SemanticICONS
} from 'semantic-ui-react';
import * as BABYLON from '@babylonjs/core';

import {
  getDownloadURL,
  StorageReference
} from 'firebase/storage';

import { Backing } from './Backing';
import { CreationVersion, MaterialMesh } from './Creation';
import { Axis, AxisExtent, newAxisNamed } from './Axis';
import { buildRefinementFrom, RefinementApplicationType, RefinementMesh, TraceProfileRefinement } from './TraceProfileRefinement';
import TemplateCreation from './TemplateCreation';
import * as TwoDModels from '../2dmodels';
import boundingBoxForAll from '../boundingBoxForAll';
import {
  extremesIn,
  Extremes,
  imageFileRefFor,
  intersect,
  rotatedSVG,
  svgElementFor,
  targetModelSize,
} from '../helpers';
import newID from '../ids';
import * as ApiModels from '../apiModels';
import { newModelName } from '../uniqueName';
import { bboxFor, buildTraceFor, desiredWidth, QualityLevel, Trace } from '../models';
import { DirectSettingOption } from './Setting';
import Shaper from '../Shaper';


export const ProfileKinds = ["rectangular", "elliptical", "trace"] as const;
export type ProfileKind = typeof ProfileKinds[number];
export const DefaultProfileKind = "rectangular";

export class ShapesInfo {
  shapes: TwoDModels.PointShape[];
  shapePoints: BABYLON.Vector2[];
  extremes: Extremes;
  requiredAspectRatio: number;

  constructor(shapes: TwoDModels.PointShape[], svg: SVGSVGElement) {
    this.shapes = shapes;
    this.shapePoints = this.shapes.length > 0 ? this.shapes.map(ea => ea.path).reduce((acc, ea) => acc.concat(ea)) : [];
    this.extremes = extremesIn(this.shapePoints);
    const bbox = bboxFor(svg);
    this.requiredAspectRatio = bbox.width / bbox.height;
  }

  maxExtentFor(axis: Axis, axisExtent: AxisExtent): number {
    const e = this.extremes;
    const translatedAxisName = axis.translateToXY(axisExtent.axis.name);
    if (translatedAxisName === "x") {
      return e.maxX - e.minX;
    } else {
      return e.maxY - e.minY;
    }
  }

  outsideShape(): BABYLON.Vector3[] {
    const vectorArrays = this.shapes.map(eaShape => eaShape.path.map(ea => new BABYLON.Vector3(ea.x, -ea.y, 0)));
    const shape = vectorArrays.reduce((acc, ea) => acc.concat(ea));
    return shape;
  }

  hasIslands(): boolean {
    return this.shapes.length > 1;
  }

  hasHoles(): boolean {
    return this.shapes.some(ea => ea.holes.length > 0);
  }

  static buildFor(svg: SVGSVGElement, rotations: number | undefined, qualityLevel: QualityLevel): ShapesInfo {
    const isCCW = false;
    const rotated = rotatedSVG(svg, rotations);
    const shapes = new Shaper(rotated, isCCW, qualityLevel).buildShapes();
    return new ShapesInfo(shapes, svg);
  }

}

export interface ProfileDescription extends DirectSettingOption {
  kind: ProfileKind,
  icon: SemanticICONS | string,
  label: string,
}

export abstract class Profile {
  abstract description: ProfileDescription;
  axis: Axis;
  refinements: TraceProfileRefinement[] = [];
  backing: Backing | undefined;
  abstract requiredAspectRatio(): number | undefined;
  abstract outsideShape(): BABYLON.Vector3[];

  constructor(axis: Axis) {
    this.axis = axis;
  }

  abstract cloneWithAxis(axis: Axis): Profile;
  abstract cloneWithoutShapesInfo(): Profile;
  abstract cloneWithoutCache(): Profile;
  cloneForSquaredComponent(creationVersion: CreationVersion): Profile {
    return this;
  }

  ensurePreparedForRender(qualityLevel: QualityLevel) {
    // do nothing by default
  }

  abstract intersectedMeshes(
    scene: BABYLON.Scene,
    intersectWith: BABYLON.Mesh[],
    profilesSeen: Profile[],
    profilesRemaining: Profile[],
    useHoles: boolean,
  ): BABYLON.Mesh[];

  hasSurfaceRefinement(): boolean {
    return this.refinements.some(ea => ea.applicationType === 'surface');
  }

  refinementWithId(refinementId: string): TraceProfileRefinement | undefined {
    return undefined;
  }

  buildMeshes(
    scene: BABYLON.Scene,
    intersectWith: BABYLON.Mesh[],
    profilesSeen: Profile[],
    profilesRemaining: Profile[],
    useHoles: boolean,
  ): MaterialMesh[] {
    let meshes = this.intersectedMeshes(scene, intersectWith, profilesSeen, profilesRemaining, useHoles);
    if (profilesRemaining.length === 0) {
      return meshes.map(ea => {
        return {
          mesh: ea,
        }
      });
    } else {
      return profilesRemaining[0].buildMeshes(scene, meshes, profilesSeen.concat([this]), profilesRemaining.slice(1), useHoles);
    }
  }

  axisExtentFor(intersectWith: BABYLON.Mesh[], axisNamesToConsider: string[]): AxisExtent {
    const bb = boundingBoxForAll(intersectWith);
    return this.axis.maxPerpendicularExtentFor(bb.extendSize, axisNamesToConsider);
  }

  toApiProps(): ApiModels.Profile {
    return {
      kind: this.description.kind,
      backing: this.backing?.toApiProps(),
    }
  }

  normalizedRatio(): number | undefined {
    let r = this.requiredAspectRatio();
    if (r && r < 1) {
      r = 1 / r;
    }
    return r;
  }

  sortKey(): number {
    return this.normalizedRatio() || Number.NEGATIVE_INFINITY;
  }

  abstract hasIslands(): boolean;
  abstract hasHoles(): boolean;

  shouldSuggestSolidBacking(): boolean {
    return (this.hasHoles() || this.hasIslands()) &&
      this.refinements.length === 0 &&
      this.backing === undefined;
  }

}


export const RectangularProfileDescription: ProfileDescription = {
  kind: "rectangular",
  icon: "square",
  label: "Squared",
};

export class RectangularProfile extends Profile {

  description = RectangularProfileDescription;
  requiredAspectRatio() {
    return 1;
  }

  cloneWithAxis(axis: Axis): RectangularProfile {
    return new RectangularProfile(axis);
  }

  cloneWithoutShapesInfo(): RectangularProfile {
    return this;
  }

  cloneWithoutCache(): RectangularProfile {
    return this;
  }

  baseMesh(scene: BABYLON.Scene): BABYLON.Mesh {
    return BABYLON.MeshBuilder.CreateBox(newModelName(), {
      height: 1,
      size: 1
    }, scene);
  }

  combinedAxisMesh(scene: BABYLON.Scene): BABYLON.Mesh {
    return this.baseMesh(scene);
  }

  outsideShape(): BABYLON.Vector3[] {
    const rect = BABYLON.Polygon.Rectangle(-0.5, -0.5, 0.5, 0.5);
    const shape = rect.map(ea => new BABYLON.Vector3(ea.x, -ea.y, 0));
    return shape;
  }

  hasIslands(): boolean {
    return false;
  }

  hasHoles(): boolean {
    return false;
  }

  intersectedMeshes(
    scene: BABYLON.Scene,
    intersectWith: BABYLON.Mesh[],
    profilesSeen: Profile[],
    profilesRemaining: Profile[],
    useHoles: boolean,
  ): BABYLON.Mesh[] {
    const otherExtendSize = boundingBoxForAll(intersectWith).extendSize;
    return intersectWith.map(ea => {
      let mesh = this.baseMesh(scene);
      this.axis.rotate(mesh);
      mesh.scaling = otherExtendSize.scale(2);
      mesh.bakeCurrentTransformIntoVertices();
      const old = mesh;
      mesh = intersect(BABYLON.CSG.FromMesh(mesh), BABYLON.CSG.FromMesh(ea)).toMesh(newModelName(), undefined, scene);
      old.dispose();
      ea.dispose();
      return mesh;
    });
  }

  scaleForIntersection(mesh: BABYLON.Mesh, intersectWith: BABYLON.Mesh[], axisNamesToConsider: string[]) {
    const targetExtent = this.axisExtentFor(intersectWith, axisNamesToConsider);
    const myExtent = 0.5;
    const scale = targetExtent.value / myExtent;
    mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
    mesh.bakeCurrentTransformIntoVertices();
  }

  buildMeshes(
    scene: BABYLON.Scene,
    intersectWith: BABYLON.Mesh[],
    profilesSeen: Profile[],
    profilesRemaining: Profile[],
    useHoles: boolean,
  ): MaterialMesh[] {
    const isCombined = profilesRemaining.length > 0 && profilesRemaining.every(ea => ea.description.kind === this.description.kind);
    const meshes = intersectWith.map(ea => {
      let mesh = isCombined ? this.combinedAxisMesh(scene) : this.baseMesh(scene);
      this.axis.rotate(mesh);
      const axisNamesToConsider: string[] = [];
      profilesSeen.forEach(ea => axisNamesToConsider.push(...ea.axis.perpendicularAxisNames));
      this.scaleForIntersection(mesh, intersectWith, axisNamesToConsider);
      const old = mesh;
      mesh = intersect(BABYLON.CSG.FromMesh(mesh), BABYLON.CSG.FromMesh(ea)).toMesh(newModelName(), undefined, scene);
      old.dispose();
      ea.dispose();
      return mesh;
    });
    intersectWith.forEach(ea => ea.dispose());
    if (isCombined || profilesRemaining.length === 0) {
      return meshes.map(ea => {
        return {
          mesh: ea
        };
      });
    } else {
      return profilesRemaining[0].buildMeshes(scene, meshes, profilesSeen.concat([this]), profilesRemaining.slice(1), useHoles);
    }
  }

}

// export const EllipticalProfileDescription: ProfileDescription = {
//   kind: "elliptical",
//   icon: "circle",
//   label: "Rounded",
// };

// export class EllipticalProfile extends Profile {

//   description = EllipticalProfileDescription;
//   requiredAspectRatio = undefined;

//   baseMesh(scene: BABYLON.Scene): BABYLON.Mesh {
//     return BABYLON.MeshBuilder.CreateCylinder(newModelName(), {
//       diameter: 1,
//       height: 1,
//     }, scene);
//   }

//   combinedAxisMesh(scene: BABYLON.Scene): BABYLON.Mesh {
//     return BABYLON.MeshBuilder.CreateSphere(newModelName(), {
//       diameter: 1
//     }, scene);
//   }

//   scaleForIntersection(mesh: BABYLON.Mesh, intersectWith: BABYLON.Mesh, axisNamesToConsider: string[]) {
//     const targetExtent = this.axisExtentFor(intersectWith, axisNamesToConsider);
//     const myExtent = 0.5;
//     const scale = targetExtent.value / myExtent;
//     mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
//     mesh.bakeCurrentTransformIntoVertices();
//   }

//   outsideShape(): BABYLON.Vector3[] {
//     const circle = BABYLON.Polygon.Circle(1);
//     const shape = circle.map(ea => new BABYLON.Vector3(ea.x, -ea.y, 0));
//     return shape;
//   }

//   intersectedMesh(
//     scene: BABYLON.Scene,
//     intersectWith: BABYLON.Mesh | undefined,
//     profilesSeen: Profile[],
//     profilesRemaining: Profile[],
//   ): BABYLON.Mesh {
//     const firstProfile = profilesSeen[0];
//     if (firstProfile) {
//       const shapeAlreadySeen = firstProfile.outsideShape();

//       intersectWith?.dispose();
//       const mesh = BABYLON.MeshBuilder.CreateLathe(newModelName(), {
//         shape: shapeAlreadySeen.filter(ea => ea.x >= 0),
//         closed: true,
//         cap: BABYLON.Mesh.CAP_ALL,
//         sideOrientation: BABYLON.Mesh.FRONTSIDE,
//       }, scene);

//       return mesh;
//     } else {
//       return this.combinedAxisMesh(scene);
//     }

//   }

// }

export const TraceProfileDescription: ProfileDescription = {
  kind: "trace",
  icon: "picture",
  label: "Based on a picture",
};

interface TraceProfileConstructorProps {
  axis: Axis,
  rotations: number | undefined,
  originalImageFile: File | undefined,
  svg: SVGSVGElement,
  initialSVG: SVGSVGElement | undefined,
  refinements: TraceProfileRefinement[],
  backing: Backing | undefined,
  templateNotes: ApiModels.ProfileTemplateNotes | undefined,
}

interface TraceProfileCloneProps {
  axis?: Axis,
  rotations?: number,
  originalImageFile?: File,
  svg?: SVGSVGElement,
  initialSVG?: SVGSVGElement,
  refinements?: TraceProfileRefinement[],
  backing?: Backing,
  templateNotes?: ApiModels.ProfileTemplateNotes,
}

export interface NewProfileProps {
  desc: ProfileDescription,
  currentProfile: Profile,
  refinements?: TraceProfileRefinement[],
  backing?: Backing,
  selectedTrace?: Trace,
  refinementId?: string,
  rotations?: number,
  initialSVG?: SVGSVGElement,
}

export async function newProfilePropsWithBacking(
  backing: Backing,
  description: ProfileDescription,
  currentProfile: Profile,
  svg: SVGSVGElement,
  rotations: number | undefined,
  templateCreation: TemplateCreation | undefined,
): Promise<NewProfileProps> {
  const newProps: NewProfileProps = {
    desc: description,
    currentProfile,
    backing,
    rotations,
  };
  if (backing.shouldAddRefinements) {
    let backingSVG: SVGSVGElement | undefined;
    const templateProfile = templateCreation?.profileFor(currentProfile.axis);
    if (templateProfile && templateProfile.backing?.kind === backing.kind) {
      backingSVG = svgElementFor(templateProfile.svg);
    }
    if (!backingSVG) {
      backingSVG = backing.svgFor(svg);
    }
    if (backingSVG) {
      const refinement = new TraceProfileRefinement({
        id: newID(),
        axis: currentProfile.axis,
        svg: svg,
        minProportion: 0.8,
        maxProportion: 1,
        applicationType: 'surface',
        isMirrored: false,
        isMirrorFlipped: false,
        isDisabled: false,
        material: undefined,
        templateNotes: undefined,
      });
      const newTrace = await buildTraceFor(backingSVG)
      Object.assign(newProps, {
        initialSVG: svg,
        currentProfile: currentProfile,
        selectedTrace: newTrace,
        refinements: [refinement],
      });
    }
  } else {
    const newTrace = await buildTraceFor(svg);
    Object.assign(newProps, {
      backing: backing,
      initialSVG: svg,
      refinements: currentProfile.refinements,
      selectedTrace: newTrace,
    });
  }

  return newProps;
}

export class TraceProfile extends Profile {

  description = TraceProfileDescription;

  rotations: number | undefined;
  originalImageFile: File | undefined;
  svg: SVGSVGElement;
  initialSVG: SVGSVGElement | undefined;
  shapesInfo: ShapesInfo | undefined;
  refinements: TraceProfileRefinement[];
  backing: Backing | undefined;
  templateNotes: ApiModels.ProfileTemplateNotes | undefined;

  constructor(props: TraceProfileConstructorProps) {
    super(props.axis);
    this.rotations = props.rotations;
    this.originalImageFile = props.originalImageFile;
    this.svg = props.svg;
    this.initialSVG = props.initialSVG;
    this.refinements = props.refinements;
    this.backing = props.backing;
    this.templateNotes = props.templateNotes;
  }

  traceRotationsForReferenceProfile(referenceProfile: TraceProfile): number {
    const myRotationsReversed = (this.rotations || 0) * -1;
    const referenceRotations = referenceProfile.rotations || 0;

    switch (this.axis.name) {
      case 'x':
        if (referenceProfile.axis.name === 'y') {
          return referenceRotations - myRotationsReversed - 1;
        } else {
          return myRotationsReversed;
        }
      case 'y':
        if (referenceProfile.axis.name === 'x') {
          return referenceRotations - myRotationsReversed - 1;
        } else {
          return myRotationsReversed;
        }
      case 'z':
        if (referenceProfile.axis.name === 'x') {
          return myRotationsReversed;
        } else {
          return referenceRotations - myRotationsReversed;
        }
    }
  }

  requiredAspectRatio(): number | undefined {
    return this.shapesInfo?.requiredAspectRatio;
  }

  newShapesInfo(qualityLevel: QualityLevel): ShapesInfo {
    return ShapesInfo.buildFor(this.svg, this.rotations, qualityLevel);
  }

  ensurePreparedForRender(qualityLevel: QualityLevel) {
    if (!this.shapesInfo) {
      this.shapesInfo = this.newShapesInfo(qualityLevel);
    }
  }

  clone(props: TraceProfileCloneProps): TraceProfile {
    const axisToUse = props.axis || this.axis;
    return new TraceProfile({
      axis: axisToUse,
      rotations: props.rotations || this.rotations,
      originalImageFile: props.originalImageFile || this.originalImageFile,
      svg: props.svg || this.svg,
      initialSVG: props.initialSVG || this.initialSVG,
      refinements: props.refinements || this.refinements.map(ea => ea.cloneWithAxis(axisToUse)),
      backing: Object.keys(props).includes('backing') ? props.backing : this.backing,
      templateNotes: props.templateNotes || this.templateNotes,
    });
  }

  cloneWithAxis(axis: Axis): TraceProfile {
    return this.clone({
      axis: axis,
      refinements: this.refinements.map(ea => ea.cloneWithAxis(axis)),
    });
  }

  cloneWithRefinements(refinements: TraceProfileRefinement[]): TraceProfile {
    const props: TraceProfileCloneProps = { refinements };
    if (refinements.length === 0 && this.backing && this.backing.kind !== 'chose-none') {
      props.backing = undefined;
    }
    return this.clone(props);
  }

  cloneWithoutShapesInfo(): TraceProfile {
    const newRefinements = this.refinements.map(ea => ea.cloneWithoutShapesInfo());
    return this.clone({ refinements: newRefinements });
  }

  cloneWithoutCache(): TraceProfile {
    const newRefinements = this.refinements.map(ea => ea.cloneWithoutCache());
    return this.clone({ refinements: newRefinements });
  }

  cloneForSquaredComponent(creationVersion: CreationVersion): Profile {
    const bbox = creationVersion.decorationMeshes.boundingBox();
    const prevDepth = this.axis.valueFor(bbox.extendSize);
    const newDepth = newAxisNamed(this.axis.translateToXY('x')).valueFor(bbox.extendSize);
    const scale = prevDepth / newDepth;
    const adjustedRefinements = this.refinements.map(ea => {
      const min = ea.minProportionToUse();
      const max = ea.maxProportionToUse();
      if (max >= 1) {
        const newMin = Math.min(1 - ((1 - min) * scale), 0.95);
        return ea.cloneWithProportions(newMin, max);
      } else if (min <= 1) {
        const newMax = Math.max(max * scale, 0.05);
        return ea.cloneWithProportions(min, newMax);
      } else {
        return ea;
      }
    })
    return this.clone({
      refinements: adjustedRefinements,
    });
  }

  refinementWithId(refinementId: string): TraceProfileRefinement | undefined {
    return this.refinements.find(ea => ea.id === refinementId);
  }

  async newRefinement(
    applicationType: RefinementApplicationType,
    templateRefinement: ApiModels.ProfileRefinement | undefined,
  ): Promise<TraceProfileRefinement> {
    const ref = templateRefinement ?
      await buildRefinementFrom(
        this.axis,
        newID(),
        templateRefinement.minProportion,
        templateRefinement.maxProportion,
        ApiModels.applicationTypeFor(templateRefinement),
        false, // change this in its own step
        false, // change this in its own step
        false,
        undefined, // change this in its own step
        undefined, // change this in its own step
        templateRefinement.templateNotes,
      ) :
      new TraceProfileRefinement({
        id: newID(),
        axis: this.axis,
        svg: this.initialSVG,
        minProportion: 0.8,
        maxProportion: 1,
        applicationType: applicationType,
        isMirrored: false,
        isMirrorFlipped: false,
        isDisabled: false,
        material: undefined,
        templateNotes: undefined
      });
    return ref;
  }

  rotatedAspectRatio(): number | undefined {
    const r = this.requiredAspectRatio();
    if (r) {
      if ((this.rotations || 0) % 2 === 1) {
        return 1 / r;
      } else {
        return r;
      }
    } else {
      return undefined;
    }
  }

  toApiProps(): ApiModels.Profile {
    return Object.assign({}, super.toApiProps(), {
      rotations: this.rotations,
      refinements: this.refinements.map(ea => ea.toApiProps()),
      svg: this.svg.outerHTML,
      initialSVG: this.initialSVG?.outerHTML,
      templateNotes: this.templateNotes,
    });
  }

  maxExtentFor(axisExtent: AxisExtent): number | undefined {
    return this.shapesInfo?.maxExtentFor(this.axis, axisExtent);
  }

  outsideShape(): BABYLON.Vector3[] {
    return this.shapesInfo ? this.shapesInfo.outsideShape() : [];
  }

  rotationsForLatheShape(): number {
    const baseRotations = this.rotations || 0;
    switch (this.axis.name) {
      case 'z':
        return baseRotations;
      case 'y':
        return baseRotations;
      case 'x':
        return baseRotations - 1;
    }
  }

  hasIslands(): boolean {
    return !!this.shapesInfo && this.shapesInfo.hasIslands();
  }

  hasHoles(): boolean {
    return !!this.shapesInfo && this.shapesInfo.hasHoles();
  }

  vectorsForPath(path: BABYLON.Vector2[], shouldReverse: boolean, scale: number): BABYLON.Vector3[] {
    const vectors = this.axis.vectorsFor(path).map(ea => ea.multiplyByFloats(-1, 1, 1).scale(scale));
    return shouldReverse ? vectors.reverse() : vectors;
  }

  intersectedMeshes(
    scene: BABYLON.Scene,
    intersectWith: BABYLON.Mesh[],
    profilesSeen: Profile[],
    profilesRemaining: Profile[],
    useHoles: boolean,
  ): BABYLON.Mesh[] {
    const defaultDepth = 1000;
    let scale = defaultDepth / desiredWidth();
    if (!this.shapesInfo) return [];
    if (intersectWith.length > 0) {
      const axisNamesToConsider = this.axis.perpendicularAxisNames.filter(eaAxisName => {
        return profilesSeen.some(ea => ea.axis.perpendicularAxisNames.includes(eaAxisName));
      });
      const targetExtent = this.axisExtentFor(intersectWith, axisNamesToConsider);
      const myExtent = this.shapesInfo.maxExtentFor(this.axis, targetExtent) / 2;
      scale = targetExtent.value / myExtent;
    }
    const depth = profilesSeen.length === 0 && profilesRemaining.length === 0 ? targetModelSize * scale : defaultDepth;
    const meshes = this.shapesInfo.shapes.map(ea => {
      const options = {
        shape: this.vectorsForPath(ea.path, false, scale),
        holes: useHoles ? ea.holes.map(ea => this.vectorsForPath(ea, true, scale)) : [],
        depth: depth,
        sideOrientation: BABYLON.Mesh.FRONTSIDE,
        wrap: true
      }
      const name = newModelName();
      const mesh = BABYLON.MeshBuilder.ExtrudePolygon(name, options, scene, require('earcut'));
      mesh.translate(BABYLON.Vector3.Up(), depth / 2);
      mesh.bakeCurrentTransformIntoVertices();
      this.axis.rotate(mesh);
      return mesh;
    });
    if (meshes.length > 0) {
      if (intersectWith.length > 0) {
        const intersectedWithCSGs = intersectWith.filter(ea => !!ea.subMeshes).map(ea => BABYLON.CSG.FromMesh(ea));
        const intersected: BABYLON.Mesh[] = [];
        meshes.forEach(ea => {
          if (ea.subMeshes) {
            intersectedWithCSGs.forEach(eaIntersectWithCSG => {
              intersected.push(intersect(BABYLON.CSG.FromMesh(ea), eaIntersectWithCSG).toMesh(newModelName(), undefined, scene));
            });
          }
        });
        intersectWith.forEach(ea => ea.dispose());
        meshes.forEach(ea => ea.dispose());
        return intersected;
      } else {
        return meshes;
      }
    } else {
      return intersectWith;
    }
  }

  refinementMeshesFor(
    scene: BABYLON.Scene,
    mainMeshes: MaterialMesh[],
    useHoles: boolean,
    qualityLevel: QualityLevel,
    useCache: boolean,
  ): RefinementMesh[] {

    const refinementMeshes: RefinementMesh[] = [];
    let refinementsToUse = this.refinements.filter(ea => {
      return ea.applicationType !== 'material' && ea.shouldApply(qualityLevel);
    });
    if (refinementsToUse.length > 0 && this.shapesInfo) {
      const axisNamesToConsider = this.axis.perpendicularAxisNames;
      const targetExtent = this.axisExtentFor(mainMeshes.map(ea => ea.mesh), axisNamesToConsider);
      const myExtent = this.shapesInfo.maxExtentFor(this.axis, targetExtent) / 2;
      const scale = targetExtent.value / myExtent;
      refinementsToUse.forEach(ea => {
        refinementMeshes.push(...ea.refinementMeshesFor(this, mainMeshes, scale, useHoles, qualityLevel, scene, useCache));
      });
    }
    return refinementMeshes;
  }

}

export interface Profiles {
  [axis: string]: Profile,
}

export function profileFileRefFor(componentId: string, axis: Axis) {
  const id = `${componentId}-profile-${axis.name}`;
  return imageFileRefFor(id);
}

async function imageFileFor(ref: StorageReference): Promise<File | undefined> {
  const url: string | undefined = await getDownloadURL(ref).catch(() => undefined);
  if (url) {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const contentType = res.headers.get('Content-Type') || undefined;
      return new File([blob], ref.name, { type: contentType });
    } catch (err) {
      return undefined;
    }

  } else {
    return undefined;
  }
}

export async function profileFileFor(componentId: string, axis: Axis): Promise<File | undefined> {
  const ref = profileFileRefFor(componentId, axis);
  return await imageFileFor(ref);
}

export async function buildProfile(
  axis: Axis,
  kind: ProfileKind | undefined,
  rotations: number | undefined,
  originalImageFile: File | undefined,
  svg: SVGSVGElement | undefined,
  initialSVG: SVGSVGElement | undefined,
  refinements: TraceProfileRefinement[],
  backing: Backing | undefined,
  templateNotes: ApiModels.ProfileTemplateNotes | undefined,
): Promise<Profile | undefined> {
  if (svg) {
    return new TraceProfile({ axis, rotations, originalImageFile, svg, initialSVG, refinements, backing: backing, templateNotes });
  } else {
    return undefined;
  }
}