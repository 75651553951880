import * as BABYLON from '@babylonjs/core';

import { TraceProfile } from './Profile';
import { TraceProfileRefinement } from './TraceProfileRefinement';

import { InputPoint } from '../SelectionBox';

const x = BABYLON.Vector3.Right();
const y = BABYLON.Vector3.Up();
const z = BABYLON.Vector3.Forward();

const scaleFactor = 1000;

export type AxisName = "x" | "y" | "z";

export interface AxisExtent {
  axis: Axis,
  value: number,
}

export abstract class Axis {
  abstract name: AxisName;
  abstract direction: string;
  abstract shortDirection: string;
  abstract perpendicularAxisNames: AxisName[];
  abstract directionVector: BABYLON.Vector3;
  abstract valueFor(vector: BABYLON.Vector3): number;
  abstract setValueFor(vector: BABYLON.Vector3, value: number): number;
  abstract setInputValueFor(inputPoint: InputPoint, value: string): string;
  abstract pathPointsFor(boundingBox: BABYLON.BoundingBox): BABYLON.Vector3[];
  abstract vectorsFor(shapePath: BABYLON.Vector2[]): BABYLON.Vector3[];
  abstract rotate(mesh: BABYLON.Mesh): BABYLON.Mesh;
  abstract translateToXY(axisName: AxisName): AxisName;
  errorCantTranslateToXY(axisName: AxisName) {
    return new Error(`${this.name} axis can't translate ${axisName} to XY`);
  }
  extensionScaling = this.scalingVectorFor(scaleFactor);
  abstract alphaDegrees: number;
  abstract alphaDegreesOpposite: number;
  abstract betaDegrees: number;
  abstract betaDegreesOpposite: number;
  abstract aspectRatioForProfiles(profiles: TraceProfile[]): number | undefined;

  abstract scalingVectorFor(scale: number): BABYLON.Vector3;

  abstract otherSymmetricAxis(): Axis;

  xAxis(): Axis {
    return newAxisNamed(this.translateToXY('x'));
  }

  positionFor(
    refinement: TraceProfileRefinement,
    creationCenter: BABYLON.Vector3,
    creationExtendSize: BABYLON.Vector3,
    minProportion: number,
    maxProportion: number,
    rotations: number,
    parentSVG?: SVGSVGElement,
  ): BABYLON.Vector3 {

    const creationMinPosition = this.valueFor(creationCenter) - this.valueFor(creationExtendSize);
    const creationExtent = this.valueFor(creationExtendSize) * 2;
    const minPosition = creationMinPosition + (creationExtent * minProportion);
    const maxPosition = creationMinPosition + (creationExtent * maxProportion);
    const extent = maxPosition - minPosition;
    const positionAlongThisAxis = minPosition + (extent / 2);

    const result = creationCenter.clone();
    this.setValueFor(result, positionAlongThisAxis);

    const svgCenterProportion = parentSVG ? refinement.svgCenterProportion(parentSVG) : undefined;
    if (svgCenterProportion) {
      this.perpendicularAxisNames.forEach(axisName => {
        const xyName = this.translateToXY(axisName);
        let proportion: number = 0.5;
        switch (rotations) {
          case 0:
            if (xyName === "x") {
              proportion = svgCenterProportion.x;
            } else {
              proportion = svgCenterProportion.y;
            }
            break;
          case 1:
            if (xyName === "x") {
              proportion = 1 - svgCenterProportion.y;
            } else {
              proportion = svgCenterProportion.x;
            }
            break;
          case 2:
            if (xyName === "x") {
              proportion = 1 - svgCenterProportion.x;
            } else {
              proportion = 1 - svgCenterProportion.y;
            }
            break;
          case 3:
            if (xyName === "x") {
              proportion = svgCenterProportion.y;
            } else {
              proportion = 1 - svgCenterProportion.x;
            }
            break;
        }
        const axis = newAxisNamed(axisName);
        const creationMinPosition = axis.valueFor(creationCenter) - axis.valueFor(creationExtendSize);
        let position = creationMinPosition + (axis.valueFor(creationExtendSize) * 2 * proportion);
        if (axisName === 'z') position = -position;
        axis.setValueFor(result, position);
      })
    }
    return result;
  }

  widthDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return extendSize.x * 2;
  }

  heightDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return extendSize.y * 2;
  }

  depthDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return extendSize.z * 2;
  }

  radiansFor(degrees: number): number {
    return BABYLON.Angle.FromDegrees(degrees).radians();
  }

  alpha(): number {
    return this.radiansFor(this.alphaDegrees);
  }
  alphaOpposite(): number {
    return this.radiansFor(this.alphaDegreesOpposite);
  }
  beta(): number {
    return this.radiansFor(this.betaDegrees);
  }
  betaOpposite(): number {
    return this.radiansFor(this.betaDegreesOpposite);
  }

  perpendicularAxes(): Axis[] {
    return this.perpendicularAxisNames.map(newAxisNamed);
  }

  maxPerpendicularExtentFor(extendSize: BABYLON.Vector3, axisNamesToConsider: string[]): AxisExtent {
    const extentsToUse: AxisExtent[] = [];
    axisNamesToConsider.forEach(ea => {
      switch (ea) {
        case "x":
          extentsToUse.push({
            axis: new XAxis(),
            value: extendSize.x
          });
          break;
        case "y":
          extentsToUse.push({
            axis: new YAxis(),
            value: extendSize.y
          });
          break;
        case "z":
          extentsToUse.push({
            axis: new ZAxis(),
            value: extendSize.z
          });
          break;
      }
    });
    return extentsToUse.sort((a, b) => a.value > b.value ? -1 : 1)[0]
  }
}

export class XAxis extends Axis {
  name: AxisName = "x";
  direction = "front or back";
  shortDirection = "front";
  alphaDegrees = 0;
  alphaDegreesOpposite = 180;
  betaDegrees = 90;
  betaDegreesOpposite = 90;
  directionVector = new BABYLON.Vector3(1, 0, 0);
  perpendicularAxisNames: AxisName[] = ["y", "z"];

  otherSymmetricAxis(): Axis {
    return new YAxis();
  }

  scaleBy(vector: BABYLON.Vector3, scale: number) {
    return new BABYLON.Vector3(scale, 1, 1).multiply(vector);
  }

  scalingVectorFor(scale: number): BABYLON.Vector3 {
    return new BABYLON.Vector3(scale, 1, 1);
  }

  widthDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return super.widthDimensionFor(extendSize, proportion) * proportion;
  }

  valueFor(vector: BABYLON.Vector3): number {
    return vector.x;
  }
  setValueFor(vector: BABYLON.Vector3, value: number) {
    return vector.x = value;
  }
  setInputValueFor(inputPoint: InputPoint, value: string): string {
    return inputPoint.x = value;
  }
  pathPointsFor(boundingBox: BABYLON.BoundingBox): BABYLON.Vector3[] {
    return [
      new BABYLON.Vector3(boundingBox.minimum.x, 0, 0),
      new BABYLON.Vector3(boundingBox.maximum.x, 0, 0),
    ]
  }
  vectorsFor(shapePath: BABYLON.Vector2[]): BABYLON.Vector3[] {
    return shapePath.map(ea => (new BABYLON.Vector3(ea.x, 0, ea.y)));
  }
  rotate(mesh: BABYLON.Mesh): BABYLON.Mesh {
    mesh.rotate(BABYLON.Vector3.Right(), BABYLON.Angle.FromDegrees(90).radians());
    mesh.rotate(BABYLON.Vector3.Backward(), BABYLON.Angle.FromDegrees(90).radians());
    mesh.bakeCurrentTransformIntoVertices();
    return mesh;
  }
  translateToXY(axisName: AxisName): AxisName {
    switch (axisName) {
      case "z": return "x";
      case "y": return "y";
      case "x": return "x";
    }
  }

  aspectRatioForProfiles(profiles: TraceProfile[]): number | undefined {
    const yProfile = profiles.find(ea => ea.axis.name === "y");
    const zProfile = profiles.find(ea => ea.axis.name === "z");
    const yAspectRatio = yProfile?.rotatedAspectRatio();
    const zAspectRatio = zProfile?.rotatedAspectRatio();
    if (yAspectRatio && zAspectRatio) {
      const yProfileX = 1;
      const yProfileY = yProfileX / yAspectRatio;
      const zProfileX = yProfileX;
      const zProfileY = zProfileX / zAspectRatio;
      return yProfileY / zProfileY;
    } else {
      return undefined;
    }
  }
}
export class YAxis extends Axis {
  name: AxisName = "y";
  direction = "top or bottom";
  shortDirection = "top";
  alphaDegrees = 90;
  alphaDegreesOpposite = 90;
  betaDegrees = 0;
  betaDegreesOpposite = 180;
  perpendicularAxisNames: AxisName[] = ["z", "x"]; // TODO: hm
  directionVector = new BABYLON.Vector3(0, 1, 0);

  otherSymmetricAxis(): Axis {
    return new ZAxis();
  }

  scalingVectorFor(scale: number): BABYLON.Vector3 {
    return new BABYLON.Vector3(1, scale, 1);
  }

  heightDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return super.heightDimensionFor(extendSize, proportion) * proportion;
  }

  valueFor(vector: BABYLON.Vector3): number {
    return vector.y;
  }
  setValueFor(vector: BABYLON.Vector3, value: number) {
    return vector.y = value;
  }
  setInputValueFor(inputPoint: InputPoint, value: string): string {
    return inputPoint.y = value;
  }
  pathPointsFor(boundingBox: BABYLON.BoundingBox): BABYLON.Vector3[] {
    return [
      new BABYLON.Vector3(0, 0, boundingBox.minimum.z),
      new BABYLON.Vector3(0, 0, boundingBox.maximum.z),
    ]
  }
  vectorsFor(shapePath: BABYLON.Vector2[]): BABYLON.Vector3[] {
    return shapePath.map(ea => (new BABYLON.Vector3(ea.x, 0, ea.y)));
  }
  rotate(mesh: BABYLON.Mesh): BABYLON.Mesh {
    return mesh;
  }
  translateToXY(axisName: AxisName): AxisName {
    switch (axisName) {
      case "x": return "x";
      case "z": return "y";
      default: throw this.errorCantTranslateToXY(axisName);
    }
  }

  aspectRatioForProfiles(profiles: TraceProfile[]): number | undefined {
    const xProfile = profiles.find(ea => ea.axis.name === "x");
    const zProfile = profiles.find(ea => ea.axis.name === "z");
    const xAspectRatio = xProfile?.rotatedAspectRatio();
    const zAspectRatio = zProfile?.rotatedAspectRatio();
    if (xAspectRatio && zAspectRatio) {
      const xProfileX = 1;
      const xProfileY = xProfileX / xAspectRatio;
      const zProfileY = xProfileY;
      const zProfileX = zProfileY * zAspectRatio;
      return zProfileX / xProfileX;
    } else {
      return undefined;
    }
  }

}
export class ZAxis extends Axis {
  name: AxisName = "z";
  direction = "side";
  shortDirection = this.direction;
  alphaDegrees = 90;
  alphaDegreesOpposite = 270;
  betaDegrees = 90;
  betaDegreesOpposite = 90;
  perpendicularAxisNames: AxisName[] = ["x", "y"];
  directionVector = new BABYLON.Vector3(0, 0, 1);

  otherSymmetricAxis(): Axis {
    return new XAxis();
  }

  scalingVectorFor(scale: number): BABYLON.Vector3 {
    return new BABYLON.Vector3(1, 1, scale);
  }

  depthDimensionFor(extendSize: BABYLON.Vector3, proportion: number) {
    return super.depthDimensionFor(extendSize, proportion) * proportion;
  }

  valueFor(vector: BABYLON.Vector3): number {
    return vector.z;
  }
  setValueFor(vector: BABYLON.Vector3, value: number) {
    return vector.z = value;
  }
  setInputValueFor(inputPoint: InputPoint, value: string): string {
    return inputPoint.z = value;
  }
  pathPointsFor(boundingBox: BABYLON.BoundingBox): BABYLON.Vector3[] {
    return [
      new BABYLON.Vector3(0, 0, boundingBox.minimum.z),
      new BABYLON.Vector3(0, 0, boundingBox.maximum.z),
    ]
  }
  vectorsFor(shapePath: BABYLON.Vector2[]): BABYLON.Vector3[] {
    return shapePath.map(ea => (new BABYLON.Vector3(ea.x, 0, ea.y)));
  }
  rotate(mesh: BABYLON.Mesh): BABYLON.Mesh {
    mesh.rotate(BABYLON.Vector3.Right(), BABYLON.Angle.FromDegrees(90).radians());
    mesh.bakeCurrentTransformIntoVertices();
    return mesh;
  }
  translateToXY(axisName: AxisName): AxisName {
    switch (axisName) {
      case "x": return "x";
      case "y": return "y";
      default: throw this.errorCantTranslateToXY(axisName);
    }
  }

  aspectRatioForProfiles(profiles: TraceProfile[]): number | undefined {
    const xProfile = profiles.find(ea => ea.axis.name === "x");
    const yProfile = profiles.find(ea => ea.axis.name === "y");
    const xAspectRatio = xProfile?.rotatedAspectRatio();
    const yAspectRatio = yProfile?.rotatedAspectRatio();
    if (xAspectRatio && yAspectRatio) {
      const xProfileX = 1;
      const xProfileY = xProfileX / xAspectRatio;
      const yProfileY = xProfileX;
      const yProfileX = yProfileY * yAspectRatio;
      return yProfileX / xProfileY;
    } else {
      return undefined;
    }
  }

}

export function newAxisNamed(name: AxisName): Axis {
  if (name === "x") {
    return new XAxis();
  } else if (name === "y") {
    return new YAxis();
  } else {
    return new ZAxis();
  }
}