import * as BABYLON from '@babylonjs/core';

import { PointShape } from './2dmodels';
import { extremesIn, Extremes } from './helpers';
import { QualityLevel } from './models';
import { ShapePath } from './ShapePath';

import { Curve, CurveCubic, CurveQuadratic, Point, toPoints } from 'svg-points';

import simplifyJS from 'simplify-js'

function isCurveCubic(c: Curve | undefined): c is CurveCubic {
  return !!c && (c as CurveCubic).type === "cubic";
}

function isCurveQuadratic(c: Curve | undefined): c is CurveQuadratic {
  return !!c && (c as CurveQuadratic).type === "quadratic";
}

export default class Shaper {

  svg: SVGSVGElement;
  isCCW: boolean;
  qualityLevel: QualityLevel;

  constructor(svg: SVGSVGElement, isCCW: boolean, qualityLevel: QualityLevel) {
    this.svg = svg;
    this.isCCW = isCCW;
    this.qualityLevel = qualityLevel;
  }

  adjust(points: BABYLON.Vector2[], extremes: Extremes): BABYLON.Vector2[] {
    const center = extremes.center();
    return points.map(ea => {
      return new BABYLON.Vector2(ea.x - center.x, ea.y - center.y);
    });
  }

  tolerance(): number {
    switch (this.qualityLevel) {
      case 'High':
        return 1;
      case 'Medium':
        return 3;
      case 'Low':
        return 5;
      case 'Lowest':
        return 10;
    }
  }

  simplify(points: Point[]): Point[] {
    return simplifyJS(points, this.tolerance(), true);
  }

  buildShapes(): PointShape[] {
    const path = this.svg.getElementsByTagName("path").item(0);
    const pathString = path?.getAttribute('d');
    if (pathString) {
      const points: Point[] = toPoints({
        type: 'path',
        d: path?.getAttribute('d') || ""
      });

      let toSimplify: Point[] = [];
      const simplified: Point[] = [];
      points.forEach(ea => {
        if (ea.moveTo) {
          simplified.push(...this.simplify(toSimplify));
          simplified.push(ea);
          toSimplify = [];
        } else {
          toSimplify.push(ea);
        }
      });
      simplified.push(...this.simplify(toSimplify));
      const shapePath = new ShapePath();
      simplified.forEach(ea => {
        const curve = ea.curve;
        if (isCurveCubic(curve)) {
          shapePath.bezierCurveTo(curve.x1, curve.y1, curve.x2, curve.y2, ea.x, ea.y);
        } else if (ea.moveTo) {
          shapePath.moveTo(ea.x, ea.y);
        } else if (isCurveQuadratic(curve)) {
          throw Error("quadratic");
        } else {
          shapePath.lineTo(ea.x, ea.y);
        }
      });

      const shapes = shapePath.toShapes(this.isCCW, false);
      const resolution = 5;
      const shapePoints = shapes.map(ea => ea.getPoints(resolution)).reduce((acc, ea) => acc.concat(ea));
      const extremes = extremesIn(shapePoints);
      const pointShapes: PointShape[] = shapes.map(eaShape => {
        return {
          path: this.adjust(eaShape.getPoints(resolution), extremes),
          holes: eaShape.holes.map(ea => ea.getPoints(resolution)).map(ea => this.adjust(ea, extremes))
        }
      });
      const extremesAfter = extremesIn(pointShapes[0].path);
      return pointShapes;
    } else {
      return [];
    }
  }
}