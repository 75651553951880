import * as BABYLON from '@babylonjs/core';
import concaveman from 'concaveman';
import { toPath } from 'svg-points';

import { uniqueReadableName } from '../uniqueName';

import { Backing } from './Backing';
import { Component } from './Component';
import {
  NewProfileProps,
  Profile,
  TraceProfile,
} from './Profile';
import { TraceProfileRefinement } from './TraceProfileRefinement';
import boundingBoxForAll from '../boundingBoxForAll';
import { newSVGWithPath, unionAll } from '../helpers';
import { desiredHeight, desiredWidth, QualityLevel, scaleToDesiredExtent, TracePadding } from '../models';
import { newModelName } from '../uniqueName';
import modelMeshesFor from '../modelMeshesFor';
import * as ApiModels from '../apiModels';
import newID from '../ids';
import { allMaterials, DefaultMaterialKind, getMaterial, Material } from './Material';
import { MaterialSetting } from './Setting';

export const Thresholds = [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240] as const;
export type Threshold = typeof Thresholds[number];
export const DefaultThreshold: Threshold = 120;

export const Turdsizes = [1, 20, 100] as const;
export type Turdsize = typeof Turdsizes[number];
export const DefaultTurdsize: Turdsize = 20;

export interface VersionInfo {
  versionId: string,
  parentId: string | undefined,
  createdAt: Date,
}

export interface CreationConstructorProps {
  id: string,
  userId: string | undefined,
}

export class Creation {
  id: string;
  userId: string | undefined;

  constructor(props: CreationConstructorProps) {
    this.id = props.id;
    this.userId = props.userId;
  }
}

export interface CreationVersionConstructorProps {
  creation: Creation,
  id: string,
  parentVersionId?: string,
  userId?: string,
  name?: string,
  createdAt?: Date,
  scene: BABYLON.Scene,
  components: Component[],
  decorationMaterial?: Material,
  decorationMeshes?: MaterialMeshGroups,
  decorationMeshesInfo?: DecorationMeshesInfo,
  resetMaterials?: boolean,
  useNightLighting?: boolean,
  renderQualityLevel: QualityLevel,
  thumbnailGifUrl?: string | undefined,
  thumbnailVideoUrl?: string | undefined,
  thumbnailPngUrl?: string | undefined,
  templateCreationId?: string,
  templateNotes?: ApiModels.CreationTemplateNotes,
}

export interface CreationVersionCloneProps {
  creation?: Creation,
  id?: string,
  parentVersionId?: string,
  name?: string,
  scene?: BABYLON.Scene,
  components?: Component[],
  decorationMaterial?: Material,
  decorationMeshes?: MaterialMeshGroups,
  resetMaterials?: boolean,
  useNightLighting?: boolean,
  renderQualityLevel?: QualityLevel,
  thumbnailGifUrl?: string | undefined,
  thumbnailVideoUrl?: string | undefined,
  thumbnailPngUrl?: string | undefined,
  templateCreationId?: string,
  templateNotes?: ApiModels.CreationTemplateNotes,
}

interface RenderProps {
  decorationMaterial?: Material,
  decorationMeshes?: MaterialMeshGroups,
  decorationMeshesInfo?: DecorationMeshesInfo,
  wireframe?: boolean,
}

export interface DecorationMeshesInfo {
  extendSize: BABYLON.Vector3,
  center: BABYLON.Vector3
}

function buildInfoFor(meshes: MaterialMeshGroups): DecorationMeshesInfo {
  const bb = meshes.boundingBox();
  return {
    extendSize: bb.extendSize,
    center: bb.center
  }
}

export interface MaterialMesh {
  mesh: BABYLON.Mesh,
  material?: Material,
}

export class MaterialMeshGroups {
  groups: MaterialMesh[][];

  constructor(groups?: MaterialMesh[][]) {
    this.groups = groups || [];
  }

  babylonMeshes(): BABYLON.Mesh[] {
    return this.materialMeshes().map(ea => ea.mesh);
  }

  validBabylonMeshes(): BABYLON.Mesh[] {
    return this.babylonMeshes();
  }

  hasValidBabylonMeshes(): boolean {
    return this.validBabylonMeshes().length > 0;
  }

  materialMeshes(): MaterialMesh[] {
    return this.groups.flatMap(eaGroup => eaGroup.filter(ea => ea.mesh.subMeshes !== undefined));
  }

  boundingBox(): BABYLON.BoundingBox {
    return boundingBoxForAll(this.validBabylonMeshes());
  }

  suggestedYProfileSVG(): SVGSVGElement | undefined {
    const xs: number[] = [];
    const zs: number[] = [];
    const points: number[][] = [];
    this.babylonMeshes().forEach(ea => {
      const data = ea.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      if (data) {
        for (let i = 0; i < data.length / 3; i += 3) {
          const x = Math.round(data[3 * i]);
          const z = Math.round(data[(3 * i) + 2]);
          if (!xs.includes(x) || !zs.includes(z)) {
            xs.push(-x);
            zs.push(z);
            points.push([x, z]);
          }
        }
      }
    });
    points.push(points[0]);
    const shapePoints = concaveman(points, 10);
    const path = toPath(shapePoints.map(ea => {
      return { x: ea[0], y: ea[1] }
    }));
    const svg = newSVGWithPath(path);
    return scaleToDesiredExtent(svg)
  }

  unionCSG(): BABYLON.CSG {
    return unionAll(this.validBabylonMeshes().map(ea => BABYLON.CSG.FromMesh(ea)));
  }

  mergedWith(other: MaterialMeshGroups): MaterialMeshGroups {
    return new MaterialMeshGroups(this.groups.concat(other.groups));
  }

  withUnionedGroups(creationVersion: CreationVersion): MaterialMeshGroups {
    const newGroups: MaterialMesh[][] = [];
    this.groups.forEach(eaGroup => {
      if (eaGroup.length > 0) {
        const validMeshes = eaGroup.filter(ea => ea.mesh.subMeshes !== undefined);
        const union = unionAll(validMeshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh))).toMesh(newModelName(), undefined, creationVersion.scene);
        const material = eaGroup[0].material;
        (material || creationVersion.decorationMaterial).appliedToBaseMesh(union);
        newGroups.push([{
          mesh: union,
          material: material,
        }]);
        validMeshes.forEach(ea => ea.mesh.dispose());
      }
    });
    return new MaterialMeshGroups(newGroups);
  }

  intersectInPlace(csg: BABYLON.CSG, scene: BABYLON.Scene) {
    this.materialMeshes().forEach(ea => {
      const bb = ea.mesh.getBoundingInfo().boundingBox;
      const es = bb.extendSizeWorld;
      const box = BABYLON.MeshBuilder.CreateBox(newModelName(), {
        width: es.x * 2,
        height: es.y * 2,
        depth: es.z * 2,
      }, scene);
      box.position = bb.centerWorld;
      const boxCSG = BABYLON.CSG.FromMesh(box);
      box.dispose();
      const newMesh = csg.intersect(boxCSG.intersect(BABYLON.CSG.FromMesh(ea.mesh))).toMesh(newModelName(), undefined, scene);
      ea.mesh.dispose();
      ea.mesh = newMesh;
    });
  }

  add(group: MaterialMesh[]) {
    this.groups.push(group);
  }
}

export class CreationVersion {

  creation: Creation;
  id: string;
  parentVersionId: string | undefined;
  name: string;
  scene: BABYLON.Scene;
  decorationMeshes!: MaterialMeshGroups;
  didBuildDecorationMeshes!: boolean;
  decorationMeshesInfo!: DecorationMeshesInfo;
  useNightLighting: boolean;
  components: Component[];
  decorationMaterial: Material;
  createdAt: Date;
  thumbnailGifUrl: string | undefined;
  thumbnailVideoUrl: string | undefined;
  thumbnailPngUrl: string | undefined;
  templateCreationId: string | undefined;
  templateNotes: ApiModels.CreationTemplateNotes | undefined;
  kind: string = "simple-figure";
  label = "Figurine";
  lastRenderTimeMS: number | undefined;
  renderQualityLevel: QualityLevel;

  constructor(props: CreationVersionConstructorProps) {
    this.creation = props.creation;
    this.id = props.id;
    this.parentVersionId = props.parentVersionId;
    this.name = props.name || uniqueReadableName();
    this.scene = props.scene;
    this.useNightLighting = !!props.useNightLighting;
    this.components = props.components;
    this.decorationMaterial = this.calculateDecorationMaterial(props);
    this.renderQualityLevel = props.renderQualityLevel;
    this.render(props);
    this.createdAt = props.createdAt || new Date();
    this.thumbnailGifUrl = props.thumbnailGifUrl;
    this.thumbnailVideoUrl = props.thumbnailVideoUrl;
    this.thumbnailPngUrl = props.thumbnailPngUrl;
    this.templateCreationId = props.templateCreationId;
    this.templateNotes = props.templateNotes;
  }

  render(props: RenderProps) {
    const ts = new Date();
    const meshesToKeep = props.decorationMeshes?.babylonMeshes() || [];
    modelMeshesFor(this.scene).forEach(eaModelMesh => {
      const isToKeep = meshesToKeep.findIndex(ea => ea.name === eaModelMesh.name) !== -1;
      const isCache = eaModelMesh.name.startsWith("cache-");
      if (!isToKeep) {
        if (isCache) {
          eaModelMesh.setEnabled(false);
        } else {
          eaModelMesh.dispose();
        }
      }
    });
    this.didBuildDecorationMeshes = !props.decorationMeshes;
    this.decorationMeshes = props.decorationMeshes || this.buildComponentMeshes();
    // this.decorationMeshes.babylonMeshes().forEach(ea => ea.increaseVertices(3));
    this.decorationMaterial.appliedTo(this.decorationMeshes?.materialMeshes());
    if (props.wireframe) this.decorationMeshes.babylonMeshes().forEach(ea => ea.material ? ea.material.wireframe = true : undefined);
    this.decorationMeshesInfo = props.decorationMeshesInfo || buildInfoFor(this.decorationMeshes);
    this.lastRenderTimeMS = new Date().getTime() - ts.getTime();
    // console.log(`rendering took ${this.lastRenderTimeMS}ms`);
  }

  totalVertices(): number | undefined {
    return this.decorationMeshes?.babylonMeshes().reduce((acc, ea) => {
      return acc + (ea.isEnabled() ? ea.getTotalVertices() : 0);
    }, 0);
  }

  component(): Component {
    return this.components[0];
  }

  buildComponentMeshes(): MaterialMeshGroups {
    let result = new MaterialMeshGroups([]);
    this.components.forEach(ea => {
      const meshGroups = ea.buildMeshesWithMaterials(this.scene, this.renderQualityLevel);
      ea.fixUpNormalsFor(meshGroups);
      result = result.mergedWith(meshGroups);
    });

    return result;
  }

  calculateDecorationMaterial(props: CreationVersionConstructorProps): Material {
    if (props.resetMaterials || !props.decorationMaterial) {
      return getMaterial(DefaultMaterialKind);
    } else {
      return props.decorationMaterial;
    }
  }

  adjustCamera(camera: BABYLON.ArcRotateCamera) {
    camera.setTarget(BABYLON.Vector3.Zero());
  }

  cameraRadiusFor(profile: Profile): number {
    const bb = boundingBoxForAll(this.decorationMeshes.babylonMeshes());
    const relevantAxes = profile.axis.perpendicularAxes();
    const extents = relevantAxes.map(ea => {
      return {
        axisName: profile.axis.translateToXY(ea.name),
        extent: ea.valueFor(bb.extendSize),
      }
    });
    const xExtent = extents.find(ea => ea.axisName === "x")?.extent;
    const yExtent = extents.find(ea => ea.axisName === "y")?.extent;
    if (xExtent && yExtent) {
      if (yExtent > (2 / 3) * xExtent) {
        return (desiredHeight() - TracePadding * 2) * 8 / (100 - yExtent);
      } else {
        return (desiredWidth() - TracePadding * 2) * 3 / (100 - xExtent);
      }
    } else {
      return 100;
    }
  }

  materialSettings(): MaterialSetting[] {
    const s = new Array<MaterialSetting>();
    s.push(new MaterialSetting({
      name: 'main-material',
      label: 'Image',
      value: this.decorationMaterial,
      options: allMaterials(),
      onSelect: (option: Material) => this.cloneKeepingDecorationMeshes({
        decorationMaterial: option,
      }),
      // showProgressState: (_: Material) => States.changingMaterial,
    }));
    return s;
  }

  clone(props: CreationVersionCloneProps, keepThumbnails?: boolean): CreationVersion {
    return new CreationVersion(Object.assign({}, this.toProps(!!keepThumbnails), { id: newID(), parentVersionId: this.id }, props));
  }

  cloneWithQualityLevel(qualityLevel: QualityLevel): CreationVersion {
    const newComponent = this.component().cloneWithoutShapesInfo();
    return this.clone({ renderQualityLevel: qualityLevel, components: [newComponent] });
  }

  cloneKeepingDecorationMeshes<T extends CreationVersionCloneProps>(props: T): CreationVersion {
    return this.clone(Object.assign({}, props, {
      decorationMeshes: this.decorationMeshes,
      decorationMeshesInfo: this.decorationMeshesInfo,
    }));
  }

  async cloneWithProfileFrom(props: NewProfileProps, scene: BABYLON.Scene, qualityLevel?: QualityLevel): Promise<CreationVersion> {
    const newComponent = await this.component().cloneWithProfileFrom(props);
    const renderQualityLevel = qualityLevel || this.renderQualityLevel;
    return this.clone({ components: [newComponent], scene: scene ? scene : this.scene, renderQualityLevel });
  }

  async cloneWithRefinements(
    refinements: TraceProfileRefinement[],
    currentProfile: TraceProfile,
    scene?: BABYLON.Scene,
  ): Promise<CreationVersion> {
    const newComponent = await this.component().cloneWithRefinements(refinements, currentProfile);
    return this.clone({ components: [newComponent], scene: scene || this.scene });
  }

  apiProfileFor(profile: Profile): ApiModels.Profile {
    const rotations = profile instanceof TraceProfile ? profile.rotations : undefined;
    return {
      kind: profile.description.kind,
      rotations: rotations,
    };
  }

  apiComponents(): ApiModels.Component[] {
    return this.components.map(ea => ea.toApiProps())
  }

  toApiProps(): ApiModels.Creation {
    return {
      creationId: this.creation.id,
      versionId: this.id,
      parentVersionId: this.parentVersionId,
      userId: this.creation.userId,
      name: this.name,
      decorationMaterial: this.decorationMaterial?.kind,
      kind: this.kind,
      components: this.apiComponents(),
      updatedAt: this.createdAt.getTime().toString(),
      useNightLighting: this.useNightLighting,
      thumbnailGifUrl: this.thumbnailGifUrl,
      thumbnailVideoUrl: this.thumbnailVideoUrl,
      thumbnailPngUrl: this.thumbnailPngUrl,
      renderQualityLevel: this.renderQualityLevel,
      templateCreationId: this.templateCreationId,
      templateNotes: this.templateNotes,
    };
  }

  toProps(keepThumbnails: boolean): CreationVersionConstructorProps {
    const props = {
      creation: this.creation,
      id: this.id,
      parentVersionId: this.parentVersionId,
      name: this.name,
      scene: this.scene,
      components: this.components,
      decorationMaterial: this.decorationMaterial,
      useNightLighting: this.useNightLighting,
      templateCreationId: this.templateCreationId,
      templateNotes: this.templateNotes,
      renderQualityLevel: this.renderQualityLevel,
      createdAt: new Date(),
    };
    if (keepThumbnails) {
      Object.assign(props, {
        thumbnailGifUrl: this.thumbnailGifUrl,
        thumbnailVideoUrl: this.thumbnailVideoUrl,
        thumbnailPngUrl: this.thumbnailPngUrl,
      })
    }
    return props;
  }

  exportScalingFactor(meshes: BABYLON.Mesh[]): number {
    return 2.5;
  }

}