import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router';

import { ApiClient, ApiClientConfig } from '../support/api';
import SignInView from './SignInView';

import ensureFirebase from '../support/ensureFirebase';
const firebaseApp = ensureFirebase();
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
  User,
  UserCredential,
} from "firebase/auth";
const auth = getAuth(firebaseApp);

interface Props {
  children: React.ReactElement | null,
  apiClient: ApiClient,
  onAuthStateChanged: (apiClient: ApiClient) => void,
  isSignInOptional: boolean,
  signInPrompt?: string,
  creationId?: string | undefined,
  templateId?: string | undefined,
}

function WithAuth(props: Props) {

  const router = useRouter();

  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);

  useEffect(() => {
    const unregisterAuthObserver = (onAuthStateChanged(
      auth,
      async (firebaseUser) => {
        let userForConfig = firebaseUser || undefined;
        if (!userForConfig) {
          const credential = await maybeSignInWithEmailLink(firebaseUser);
          userForConfig = credential?.user;
        }
        const config = Object.assign({}, props.apiClient.config, {
          hasCheckedAuth: true,
          firebaseUser: userForConfig,
        });
        await updateApiClient(config, 20);
      }
    ));
    return () => {
      unregisterAuthObserver();
    }
  }, []);

  async function updateApiClient(config: ApiClientConfig, triesLeft: number) {
    const apiClient = await ApiClient.forFirebaseUser(config);
    // if (apiClient.isLoggedIn() && triesLeft > 0) {
    //   if (apiClient.hasUserInfo()) {
    //     props.onAuthStateChanged(apiClient);
    //   } else {
    //     setTimeout(async () => {
    //       updateApiClient(config, triesLeft - 1);
    //     }, 500);
    //     props.onAuthStateChanged(apiClient);
    //   }
    // } else {
    props.onAuthStateChanged(apiClient);
    // }
  }

  async function maybeSignInWithEmailLink(user: User | null): Promise<UserCredential | undefined> {
    if (!user && isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email && !props.isSignInOptional) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Just to be sure this is your link, please enter the email address where you received it');
      }
      if (email) {
        try {
          const credential = await signInWithEmailLink(auth, email);
          window.localStorage.setItem('emailForSignIn', email);
          const queryParams = new URLSearchParams(location.search)
          queryParams.delete('oobCode');
          queryParams.delete('apiKey');
          queryParams.delete('mode');
          queryParams.delete('lang');
          const newUrl = {
            pathname: window.location.pathname,
            query: queryParams.toString(),
          }
          router.replace(newUrl, undefined, { shallow: true });
          return credential;
        } catch (err) {
          // TODO: user-friendly handling of expired links and invalid emails
          if ((err as any).code === 'auth/invalid-action-code') {
            setIsLinkExpired(true);
          }
          console.error(err);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        }
      }
    }
    return undefined;
  }

  if (props.apiClient.hasCheckedAuth()) {
    if (props.apiClient.isLoggedIn() || props.isSignInOptional) {
      return props.children;
    } else {
      const prompt = isLinkExpired ? 'Your signin link is expired. We can send you another one!' : props.signInPrompt;
      return <SignInView
        prompt={prompt}
        creationId={props.creationId}
        templateId={props.templateId}
      />
    }
  } else {
    return null;
  }

}

export default WithAuth;