import * as BABYLON from '@babylonjs/core';

import { capitalized } from '../helpers';
import { MaterialMesh } from './Creation';

const LightMaterialKinds = [
  "gold",
  "silver",
  "white",
  "yellow",
  "glowing-blue"
];

export const MaterialKinds = [
  "gold",
  "silver",
  "black",
  "charcoal",
  "light-grey",
  "white",
  "red",
  "orange",
  "green",
  "blue",
  "light-blue",
  "purple",
  "pink",
  "brown",
  "light-brown",
  "yellow",
  "glowing-blue"
] as const;
export type MaterialKind = typeof MaterialKinds[number];
export const DefaultMaterialKind: MaterialKind = 'silver';

interface MaterialProps {
  kind: MaterialKind,
  label?: string,
  color: BABYLON.Color3,
}

export abstract class Material {
  kind: MaterialKind;
  label: string;
  color: BABYLON.Color3;
  abstract isMetallic: boolean;
  isLight(): boolean {
    return LightMaterialKinds.includes(this.kind);
  }

  constructor(props: MaterialProps) {
    this.kind = props.kind;
    this.label = props.label || capitalized(props.kind);
    this.color = props.color;
  }

  appliedTo(meshes: MaterialMesh[]): MaterialMesh[] {
    return meshes.map(ea => this.appliedToMesh(ea))
  }
  appliedToMesh(mesh: MaterialMesh): MaterialMesh {
    const toApply = mesh.material || this;
    toApply.appliedToBaseMesh(mesh.mesh);
    return mesh;
  }
  abstract appliedToBaseMesh(mesh: BABYLON.Mesh): BABYLON.Mesh;

}

export class StandardMaterial extends Material {
  isMetallic: boolean = false;

  appliedToBaseMesh(mesh: BABYLON.Mesh): BABYLON.Mesh {
    const scene = mesh.getScene();
    const material = new BABYLON.StandardMaterial(`${mesh.name}-material`, scene);
    material.diffuseColor = this.color;
    material.backFaceCulling = true;
    material.roughness = 0.6;
    mesh.material = material;
    return mesh;
  }
}

export class MetallicMaterial extends Material {

  isMetallic: boolean = true;

  appliedToBaseMesh(mesh: BABYLON.Mesh): BABYLON.Mesh {
    const scene = mesh.getScene();
    const material = new BABYLON.PBRMetallicRoughnessMaterial(`${mesh.name}-material`, scene);
    material.baseColor = this.color;
    material.backFaceCulling = true;
    material.metallic = 1.0;
    material.roughness = 0.3;
    material.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("/textures/environment.dds", scene);
    mesh.material = material;
    return mesh;
  }
}

export class GlowingMaterial extends Material {
  isMetallic: boolean = false;

  appliedToBaseMesh(mesh: BABYLON.Mesh): BABYLON.Mesh {
    const scene = mesh.getScene();
    const material = new BABYLON.StandardMaterial(`${mesh.name}-material`, scene);
    material.emissiveColor = this.color;
    mesh.material = material;
    var gl = new BABYLON.GlowLayer("glow", scene);
    gl.intensity = 0.1;
    gl.addIncludedOnlyMesh(mesh);
    return mesh;
  }

}

export function getMaterial(kind: MaterialKind): Material {
  return allMaterials().find(ea => ea.kind === kind) || white;
}

const white = new StandardMaterial({ kind: "white", color: BABYLON.Color3.White() });

export function allMaterials(): Material[] {
  return [
    new MetallicMaterial({ kind: "gold", color: new BABYLON.Color3(1.0, 0.766, 0.336) }),
    new MetallicMaterial({ kind: "silver", color: new BABYLON.Color3(0.766, 0.766, 0.766) }),
    new StandardMaterial({ kind: "black", color: BABYLON.Color3.FromHexString("#000000") }),
    new StandardMaterial({ kind: "charcoal", color: BABYLON.Color3.FromHexString("#555555") }),
    new StandardMaterial({ kind: "light-grey", label: 'Light grey', color: BABYLON.Color3.FromHexString("#C0C0C0") }),
    new StandardMaterial({ kind: "red", color: BABYLON.Color3.FromHexString("#E30B0B") }),
    new StandardMaterial({ kind: "orange", color: BABYLON.Color3.FromHexString("#EE5A00") }),
    new StandardMaterial({ kind: "yellow", color: BABYLON.Color3.FromHexString("#FAF052") }),
    new StandardMaterial({ kind: "green", color: BABYLON.Color3.FromHexString("#70B930") }),
    new StandardMaterial({ kind: "blue", color: BABYLON.Color3.FromHexString("#0C78E5") }),
    new StandardMaterial({ kind: "light-blue", label: 'Light blue', color: BABYLON.Color3.FromHexString("#0096FF") }),
    new StandardMaterial({ kind: "purple", color: BABYLON.Color3.FromHexString("#9F49E2") }),
    new StandardMaterial({ kind: "pink", color: BABYLON.Color3.FromHexString("#EA56F4") }),
    new StandardMaterial({ kind: "brown", color: BABYLON.Color3.FromHexString("#843E13") }),
    new StandardMaterial({ kind: "light-brown", label: 'Light brown', color: BABYLON.Color3.FromHexString("#DFA866") }),
    new GlowingMaterial({ kind: "glowing-blue", label: "Glow-in-the-dark blue", color: BABYLON.Color3.FromHexString("#4FFFFF") }),
    white,
  ]
}

export function defaultComplementaryMaterialFor(material: Material | undefined): Material {
  if (material) {
    if (!LightMaterialKinds.includes(material.kind)) {
      return getMaterial('silver');
    }
  }
  return getMaterial('charcoal');
}

export const DefaultMaterial = white;