import * as BABYLON from '@babylonjs/core';
import { debounce } from 'throttle-debounce';
import hull from 'hull.js';
import { GetServerSidePropsContext } from 'next'
import svgpath from 'svgpath';
import svgPathReverse from 'svg-path-reverse';
import { svgToPngBase64 } from './svgToPng';
import { toPath, toPoints } from 'svg-points';

import { Axis } from './creations/Axis';
import { Component } from './creations/Component';
import { Material } from './creations/Material';
import { Profile, TraceProfile } from './creations/Profile';
import { Bitmap, desiredHeight, desiredWidth, lateralSymmetryFor } from './models';

import ensureFirebase from '../support/ensureFirebase';
const firebaseApp = ensureFirebase();

import {
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
const storage = getStorage(firebaseApp);

import { useRef } from 'react';

export function isArcRotateCamera(cam: BABYLON.Camera): cam is BABYLON.ArcRotateCamera {
  return !!(cam as BABYLON.ArcRotateCamera).radius;
}

export function partition<T>(arr: T[], pred: (ea: T) => boolean): T[][] {
  return arr.reduce((result: T[][], ea: T) => {
    result[pred(ea) ? 0 : 1].push(ea);
    return result;
  }, [[], []]);
}

export function capitalized(str: string): string {
  if (str.length === 0) {
    return str;
  } else {
    return str[0].toUpperCase() + str.slice(1);
  }
}

export class Extremes {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;

  constructor(minX: number, maxX: number, minY: number, maxY: number) {
    this.minX = minX;
    this.maxX = maxX;
    this.minY = minY;
    this.maxY = maxY;
  }

  center(): BABYLON.Vector2 {
    return new BABYLON.Vector2(this.maxX - ((this.maxX - this.minX) / 2), this.maxY - ((this.maxY - this.minY) / 2));
  }

  width(): number {
    return this.maxX - this.minX;
  }

  height(): number {
    return this.maxY - this.minY;
  }

  isFinite(): boolean {
    return Number.isFinite(this.width()) && Number.isFinite(this.height());
  }

  matches(other: Extremes): boolean {
    return areEqualish(this.minX, other.minX) &&
      areEqualish(this.maxX, other.maxX) &&
      areEqualish(this.minY, other.minY) &&
      areEqualish(this.maxY, other.maxY);
  }
}

export function extremesIn(points: BABYLON.Vector2[]): Extremes {
  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;
  points.forEach(ea => {
    if (minX > ea.x) minX = ea.x;
    if (minY > ea.y) minY = ea.y;
    if (maxX < ea.x) maxX = ea.x;
    if (maxY < ea.y) maxY = ea.y;
  });
  return new Extremes(minX, maxX, minY, maxY)
}

export const targetModelSize = 100;

export const cameraName = "camera1";

export function animateCamera(
  camera: BABYLON.ArcRotateCamera,
  toAlpha: number,
  toBeta: number,
  toRadius: number,
  toFrame: number,
  scene: BABYLON.Scene
) {

  var animCamAlpha = new BABYLON.Animation("animCam", "alpha", 30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

  var keysAlpha = [];
  keysAlpha.push({
    frame: 0,
    value: camera.alpha
  });
  keysAlpha.push({
    frame: toFrame,
    value: toAlpha
  });

  var animCamBeta = new BABYLON.Animation("animCam", "beta", 30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

  var keysBeta = [];
  keysBeta.push({
    frame: 0,
    value: camera.beta
  });
  keysBeta.push({
    frame: toFrame,
    value: toBeta
  });

  var animCamRadius = new BABYLON.Animation("animCam", "radius", 30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

  var keysRadius = [];
  keysRadius.push({
    frame: 0,
    value: camera.radius
  });
  keysRadius.push({
    frame: toFrame,
    value: toRadius
  });

  animCamAlpha.setKeys(keysAlpha);
  animCamBeta.setKeys(keysBeta);
  animCamRadius.setKeys(keysRadius);

  camera.animations.push(animCamAlpha);
  camera.animations.push(animCamBeta);
  camera.animations.push(animCamRadius);
  scene.beginAnimation(camera, 0, toFrame, false, 1, function () { });

}

function matchesAngle(a1: number, a2: number): boolean {
  return Math.abs(a1 - a2) < 0.02;
}

interface AnimateCameraProps {
  scene: BABYLON.Scene,
  profile?: Profile,
  refinementId?: string,
  axis?: Axis,
  alpha?: number,
  beta?: number,
  radius?: number,
  toFrame?: number,
  shouldFlip?: boolean,
}

export async function animateCameraFor(props: AnimateCameraProps) {
  const { scene, alpha, axis, profile, refinementId, beta, radius, toFrame, shouldFlip } = props;
  const axisToUse = axis || profile?.axis;
  const rotations = (profile instanceof TraceProfile && profile.rotations) || 0;
  const refinement = refinementId ? profile?.refinementWithId(refinementId) : undefined;
  const lateralSymmetry = profile instanceof TraceProfile ? await lateralSymmetryFor(profile.svg) : undefined;
  const shouldFlyToOtherSide =
    refinement &&
    lateralSymmetry &&
    lateralSymmetry > 0.99 &&
    (((1 - refinement.maxProportionToUse()) > refinement.minProportionToUse()) !== refinement.isMirrorFlipped);
  const camera = scene.getCameraByName(cameraName);
  if (camera && isArcRotateCamera(camera)) {
    let alphaToUse = alpha || camera.alpha;
    let betaToUse = beta || camera.beta;
    if (axisToUse) {
      const shouldFlipForRefinement = refinement !== undefined && refinement.shouldUseFlippedSVG()
      alphaToUse = shouldFlipForRefinement ? axisToUse.alphaOpposite() : axisToUse.alpha();
      betaToUse = beta || (shouldFlipForRefinement ? axisToUse.betaOpposite() : axisToUse.beta());
      if (shouldFlip && matchesAngle(camera.alpha, alphaToUse) && matchesAngle(camera.beta, betaToUse)) {
        alphaToUse = axisToUse.alphaOpposite();
        betaToUse = axisToUse.betaOpposite();
      }
    }
    const radiusToUse = radius || camera.radius;
    const toFrameToUse = toFrame || 20;
    animateCamera(
      camera,
      alphaToUse - (rotations * Math.PI / 2) + (shouldFlyToOtherSide ? Math.PI : 0),
      betaToUse,
      radiusToUse,
      toFrameToUse,
      scene
    );
  }

}

export async function focusViewOn(axis: Axis, scene: BABYLON.Scene) {
  return animateCameraFor({ scene, axis, shouldFlip: true });
}

export function arrayEquals<T>(a: T[], b: T[]) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

export interface Cancelable<T> {
  promise: Promise<T>,
  cancel: () => void,
}

export interface MaybeCanceled {
  isCanceled: boolean,
}

export function makeCancelable<T>(promise: Promise<T>): Cancelable<T | MaybeCanceled> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T | MaybeCanceled>((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? resolve({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? resolve({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export function addCameraTo(
  scene: BABYLON.Scene,
  targetModelSize: number,
  radiusFactor: number,
  canvas: HTMLCanvasElement,
): BABYLON.ArcRotateCamera {
  const target = BABYLON.Vector3.Zero();
  const alpha = BABYLON.Angle.FromDegrees(45).radians();
  const beta = BABYLON.Angle.FromDegrees(70).radians();
  const radius = targetModelSize * radiusFactor;
  var camera = new BABYLON.ArcRotateCamera(cameraName, alpha, beta, radius, target, scene);
  camera.layerMask = 0x0FFFFFFF | 0x10000000;
  camera.setTarget(target);
  camera.wheelPrecision = 10;
  camera.attachControl(canvas, true);
  camera.upperRadiusLimit = radius * 4;
  camera.lowerRadiusLimit = radius * -4;
  // const engine = scene.getEngine();
  // engine.registerView(canvas, camera, true);
  return camera;
}

export const primaryLightName = "primaryLight";
export const primaryLightIntensity = 1;
export const secondaryLightName = "secondaryLight";
export const secondaryLightIntensity = 0.7;

export function addLightTo(scene: BABYLON.Scene) {
  new BABYLON.HemisphericLight(primaryLightName, new BABYLON.Vector3(-1, 10, -1), scene);
  new BABYLON.HemisphericLight(secondaryLightName, new BABYLON.Vector3(1, 1, 1), scene);
}

export interface SetUpViewProps {
  canvas: HTMLCanvasElement | undefined | null,
  scene: BABYLON.Scene | undefined,
  onSceneReady: (scene: BABYLON.Scene) => void,
  useCanvasFocus: boolean,
  radiusFactor: number,
}

export function pauseRender(scene: BABYLON.Scene) {
  scene.getEngine().stopRenderLoop();
}

export function ensureRender(scene: BABYLON.Scene) {
  const engine = scene.getEngine();
  if (engine.activeRenderLoops.length === 0) {
    engine.runRenderLoop(() => {
      if (scene.activeCamera) {
        scene.render();
      }
    });
  }
}

export function setUpView(props: SetUpViewProps): () => void {
  const antialias = true;
  const engineOptions: BABYLON.EngineOptions = {
    disableWebGL2Support: false,
  };
  const adaptToDeviceRatio = true;
  const sceneOptions = {};
  const { canvas, radiusFactor } = props;
  let cleanUp = () => { };
  if (canvas && !props.scene) {
    const engine = new BABYLON.Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
    const scene = new BABYLON.Scene(engine, sceneOptions);
    const onSceneReady = (scene: BABYLON.Scene) => {
      addCameraTo(scene, targetModelSize, radiusFactor, canvas);
      addLightTo(scene);
      props.onSceneReady(scene);
    }
    if (scene.isReady()) {
      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce(scene => onSceneReady(scene));
    }

    const resize = debounce(500, () => {
      scene.getEngine().resize();
    });
    const pause = () => pauseRender(scene);
    const resume = () => ensureRender(scene);

    resume();

    let focusTarget: HTMLCanvasElement | Window | undefined;
    if (window) {
      window.addEventListener('resize', resize);
      focusTarget = props.useCanvasFocus ? canvas : window;
      focusTarget.addEventListener('blur', pause);
      focusTarget.addEventListener('focus', resume);
    }

    cleanUp = () => {
      scene.dispose();
      scene.getEngine().dispose();
      if (window) {
        window.removeEventListener('resize', resize);
      }
      if (focusTarget) {
        focusTarget.removeEventListener('blur', pause);
        focusTarget.removeEventListener('focus', resume);
      }
    }
  }

  return cleanUp;
}

export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

export function dataURLtoBlob(dataUrl: string): Blob | undefined {
  const arr = dataUrl.split(',');
  const match = arr[0]?.match(/:(.*?);/);
  if (match) {
    const mime = match[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  } else {
    return undefined;
  }
}

export function dataURLToFile(dataUrl: string, name: string): File | undefined {
  const blob = dataURLtoBlob(dataUrl);
  return blob ? new File([blob], name, { type: blob.type }) : undefined;
}

export const selectionBoxName = "SelectionBox";

export function imageFileRefFor(id: string) {
  return ref(storage, `images/${id}`);
}

export async function uploadImageFile(file: File, name: string) {
  const ref = imageFileRefFor(name);
  await uploadBytes(ref, file);
}

export function imageFilenameForProfile(profile: Profile, component: Component): string {
  return `${component.id}-profile-${profile.axis.name}`;
}

export function svgElementFor(svgString: string | undefined): SVGSVGElement | undefined {
  if (svgString) {
    const elt: HTMLDivElement = document.createElement('div');
    if (svgString) {
      elt.innerHTML = svgString;
    }
    const svg = elt.getElementsByTagName("svg").item(0);
    return svg ? svg : undefined;
  } else {
    return undefined;
  }
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K) {
  return list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}

function processSVGPathFor(svg: SVGSVGElement, callback: (pathData: string) => string): SVGSVGElement {
  const result = svg.cloneNode(true) as SVGSVGElement;
  const paths = result.getElementsByTagName('path');
  for (let i = 0; i < paths.length; i++) {
    const path = paths.item(i);
    const pathData = path?.getAttribute("d");
    if (path && pathData) {
      const processed = callback(pathData);
      path.setAttribute("d", processed);
    }
  }
  return result;
}

export function scaledSVG(svg: SVGSVGElement, factor: number): SVGSVGElement {
  const result = processSVGPathFor(svg, pathData => {
    return svgpath(pathData).scale(factor).toString();
  })
  const svgWidth = extractNumericAttribute('width', svg);
  if (svgWidth) {
    result.setAttribute('width', (svgWidth * factor).toString());
  }
  const svgHeight = extractNumericAttribute('height', svg);
  if (svgHeight) {
    result.setAttribute('height', (svgHeight * factor).toString());
  }
  return result;
}

export function roundedSVGFor(svg: SVGSVGElement, precision?: number): SVGSVGElement {
  return processSVGPathFor(svg, pathData => {
    return svgpath(pathData).round(precision || 0).toString();
  })
}

export function extractNumericAttribute(attr: string, svg: SVGSVGElement): number | undefined {
  const strVal = svg.getAttribute(attr);
  if (strVal) {
    const parsed = parseFloat(strVal);
    return Number.isNaN(parsed) ? undefined : parsed;
  } else {
    return undefined;
  }
}

export function areEqualish(num1: number, num2: number): boolean {
  return Math.abs(num1 - num2) < Number.EPSILON;
}

export async function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export function useLocalContext<T>(data: T) {
  const ctx = useRef(data);
  ctx.current = data;
  return ctx;
}

export function canPlayVideo(): boolean {
  return !!document.createElement('video').canPlayType;
}

export function csgFor(mesh: BABYLON.Mesh): BABYLON.CSG {
  if (mesh.subMeshes) {
    return BABYLON.CSG.FromMesh(mesh);
  } else {
    return new BABYLON.CSG();
  }
}

function isBadPolygon(polygon: any): boolean {
  const a = polygon.vertices[0].pos;
  const b = polygon.vertices[1].pos;
  const c = polygon.vertices[2].pos;
  if (c.subtract(a).lengthSquared() === 0) return true;
  if (b.subtract(a).lengthSquared() === 0) return true;
  return false;
}

// Extra super sketchy workaround for problem in BJS CSG lib
function removeBadPolygonsFrom(csg: BABYLON.CSG) {
  (csg as any).polygons = (csg as any).polygons.filter((ea: any) => !isBadPolygon(ea));
}

function csgApply(
  csg1: BABYLON.CSG,
  csg2: BABYLON.CSG,
  fn: (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => BABYLON.CSG): BABYLON.CSG {
  removeBadPolygonsFrom(csg1);
  removeBadPolygonsFrom(csg2);
  return fn(csg1, csg2);
}

export function union(csg1: BABYLON.CSG, csg2: BABYLON.CSG): BABYLON.CSG {
  return csgApply(csg1, csg2, (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => csg1.union(csg2));
}

export function intersect(csg1: BABYLON.CSG, csg2: BABYLON.CSG): BABYLON.CSG {
  return csgApply(csg1, csg2, (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => csg1.intersect(csg2));
}

export function subtract(csg1: BABYLON.CSG, csg2: BABYLON.CSG): BABYLON.CSG {
  return csgApply(csg1, csg2, (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => csg1.subtract(csg2));
}

function binaryCSGApply(csgs: BABYLON.CSG[], fn: (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => BABYLON.CSG): BABYLON.CSG {
  if (csgs.length === 0) {
    return new BABYLON.CSG();
  } else if (csgs.length === 1) {
    return csgs[0];
  } else {
    const split = Math.floor(csgs.length / 2);
    const left = binaryCSGApply(csgs.slice(0, split), fn);
    const right = binaryCSGApply(csgs.slice(split, csgs.length), fn);
    if (left) {
      if (right) {
        return csgApply(left, right, fn);
      } else {
        return left;
      }
    } else {
      return new BABYLON.CSG();
    }
  }
}

export function unionAll(csgs: BABYLON.CSG[]): BABYLON.CSG {
  return binaryCSGApply(csgs, (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => csg1.union(csg2));
}

export function intersectAll(csgs: BABYLON.CSG[]): BABYLON.CSG {
  return binaryCSGApply(csgs, (csg1: BABYLON.CSG, csg2: BABYLON.CSG) => csg1.intersect(csg2));
}

export function isMeshBigEnough(mesh: BABYLON.Mesh): boolean {
  const es = mesh.getBoundingInfo().boundingBox.extendSize;
  return es.x > BABYLON.Epsilon && es.y > BABYLON.Epsilon && es.z > BABYLON.Epsilon;
}

export function rotatedSVG(svg: SVGSVGElement, rotations: number | undefined): SVGSVGElement {
  const rotationsToUse = rotations || 0;
  const rotationDegrees = rotationsToUse * 90;
  const result = svg.cloneNode(true) as SVGSVGElement;
  const paths = result.getElementsByTagName('path');
  for (let i = 0; i < paths.length; i++) {
    const path = paths.item(i);
    const pathData = path?.getAttribute("d");
    if (path && pathData) {
      const rotated = svgpath(pathData).rotate(rotationDegrees);
      path.setAttribute("d", rotated.toString());
    }
  }
  return result;
}

export function flippedSVG(svg: SVGSVGElement): SVGSVGElement {
  const result = svg.cloneNode(true) as SVGSVGElement;
  const paths = result.getElementsByTagName('path');
  for (let i = 0; i < paths.length; i++) {
    const path = paths.item(i);
    const pathData = path?.getAttribute("d");
    if (path && pathData) {
      const translate = extractNumericAttribute('width', svg);
      const flipped = svgpath(pathData).transform(`translate(${translate},0) scale(-1, 1)`);
      path.setAttribute("d", svgPathReverse.reverse(flipped.toString()).toString());
    }
  }

  return result;
}

export function formatAmountForStripe(
  amount: number,
  currency: string
): number {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency: boolean = true
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100)
}

export async function fetchPostJSON(url: string, data?: {}) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    })
    return await response.json() // parses JSON response into native JavaScript objects
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message)
    }
    throw err
  }
}

export function setCachingFor(ctx: GetServerSidePropsContext) {
  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, maxage=0, stale-while-revalidate'
  );
}

export async function svgUrlFor(
  svg: SVGSVGElement,
  material?: Material,
  dimensionsSVG?: SVGSVGElement
): Promise<string> {
  const svgToUse = svg.cloneNode(true) as SVGSVGElement;
  const path = svgToUse.firstChild as HTMLElement;
  if (material && path) {
    path.setAttribute('fill', material.color.toHexString());
    path.setAttribute('stroke', '#555555');
    path.setAttribute('stroke-width', '10px');
  }
  return await svgToPngBase64(svgToUse.outerHTML, dimensionsSVG);
}

export function newSVGWithPath(pathString: string): SVGSVGElement {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svg.setAttribute("version", "1.1");
  svg.setAttribute("width", desiredWidth().toString());
  svg.setAttribute("height", desiredHeight().toString());
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", pathString);
  path.setAttribute("stroke", "none");
  path.setAttribute("fill", "#555555");
  path.setAttribute("fill-rule", "evenodd");
  svg.appendChild(path);
  return svg;
}

export function fixUpNormalsForMesh(mesh: BABYLON.Mesh) {
  const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (positions) {
    let normals: any = [];
    BABYLON.VertexData.ComputeNormals(positions, mesh.getIndices(), normals, { useRightHandedSystem: false });
    mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals);
  }
}