import * as BABYLON from '@babylonjs/core';
import sec from 'smallest-enclosing-circle';
import { toPath, toPoints } from 'svg-points';
import { pointInSvgPath } from 'point-in-svg-path';
import concaveman from 'concaveman';

import * as ApiModels from '../apiModels';
import {
  extremesIn
} from '../helpers';

export type BackingKind = 'fill-holes' | 'hull' | 'circle' | 'chose-none';

export abstract class Backing {
  abstract kind: BackingKind;
  templateNotes: ApiModels.BackingTemplateNotes | undefined;
  abstract key(): string;
  abstract svgPathFor(d: string): string;
  shouldAddRefinements = true;

  constructor(templateNotes: ApiModels.BackingTemplateNotes | undefined) {
    this.templateNotes = templateNotes;
  }

  svgFor(svg: SVGSVGElement): SVGSVGElement | undefined {
    const svgPath = svg.firstChild as HTMLElement;
    const d = svgPath?.getAttribute('d');
    if (d) {
      const newPath = this.svgPathFor(d);
      const backingSVG = svg.cloneNode(true) as SVGSVGElement;
      if (backingSVG.firstChild) {
        (backingSVG.firstChild as HTMLElement).setAttribute('d', newPath);
      }
      return backingSVG;
    } else {
      return undefined;
    }
  }

  toApiProps(): ApiModels.Backing {
    return {
      kind: this.kind,
      templateNotes: this.templateNotes,
    }
  }
}

export class NoBacking extends Backing {
  kind: BackingKind = 'chose-none';
  shouldAddRefinements = false;
  key(): string {
    return `no-backing-type`;
  }
  svgPathFor(d: string): string {
    return d;
  }
}

export class FillHolesBacking extends Backing {

  kind: BackingKind = 'fill-holes';

  key(): string {
    return `fill-holes-backing-type`;
  }

  svgPathFor(d: string): string {
    const trimmed = d.trim();
    const outerParts: string[] = [];
    let currentOuterPart = '';
    let index = 0;
    let isDoneOuter = false;
    while (index < trimmed.length && !isDoneOuter) {
      if (currentOuterPart.length > 0 && trimmed[index] === 'M') {
        outerParts.push(currentOuterPart);
        currentOuterPart = '';
        const movePointOffset = trimmed.indexOf('L', index + 1);
        const movePointParts = trimmed.slice(index + 1, movePointOffset).split(' ');
        const moveX = parseFloat(movePointParts[0]);
        const moveY = parseFloat(movePointParts[1]);
        outerParts.forEach(ea => {
          if (pointInSvgPath(ea, moveX, moveY)) {
            isDoneOuter = true;
          }
        });
      } else {
        currentOuterPart += trimmed[index];
      }
      index++;
    }
    if (outerParts.length < 2) {
      return outerParts.join();
    } else {
      return new HullBacking(100, this.templateNotes).svgPathFor(d);
    }
  }
}

export abstract class PointsBacking extends Backing {
  abstract pointsFor(points: number[][]): number[][];

  svgPathFor(d: string): string {
    const points = toPoints({ type: 'path', d: d }).map(ea => [ea.x, ea.y]);
    const backingPoints = this.pointsFor(points);
    return toPath(backingPoints.map(ea => {
      return {
        x: ea[0],
        y: ea[1],
      }
    }));
  }

}

export const Concavities = [500, Number.POSITIVE_INFINITY] as const;
export type Concavity = typeof Concavities[number];
export const DefaultConcavity = Concavities[Concavities.length - 1];

export class HullBacking extends PointsBacking {
  kind: BackingKind = 'hull';
  concavity: Concavity;

  constructor(concavity: Concavity, templateNotes: ApiModels.BackingTemplateNotes | undefined) {
    super(templateNotes);
    this.concavity = concavity;
  }

  key(): string {
    return `hull-backing-type-${this.concavity}`;
  }

  pointsFor(points: number[][]): number[][] {
    const result = concaveman(points, this.concavity, 40);
    result.push(result[0]);
    return result;
  }

  toApiProps(): ApiModels.Backing {
    return Object.assign({}, super.toApiProps(), {
      concavity: this.concavity,
    });
  }
}

export class CircleBacking extends PointsBacking {
  kind: BackingKind = 'circle';

  key(): string {
    return "circle-backing-type";
  }

  pointsFor(points: number[][]): number[][] {
    const extremes = extremesIn(points.map(ea => new BABYLON.Vector2(ea[0], ea[1])));
    const pointsToUse = [
      { x: extremes.minX, y: extremes.minY },
      { x: extremes.maxX, y: extremes.minY },
      { x: extremes.minX, y: extremes.maxY },
      { x: extremes.maxX, y: extremes.maxY },
    ];
    const circle = sec(pointsToUse);
    const result: number[][] = [];
    const incr = 2 * Math.PI / 128;
    for (let theta = 0; theta <= 2 * Math.PI; theta += incr) {
      result.push([
        circle.x + (circle.r * Math.cos(theta)),
        circle.y + (circle.r * Math.sin(theta)),
      ]);
    }
    return result;
  }
}