import * as BABYLON from '@babylonjs/core';

import concaveman from 'concaveman';
import svgpath from 'svgpath';
import { toPath, toPoints } from 'svg-points';

import { AxisName } from './creations/Axis';
import { DefaultThreshold, DefaultTurdsize } from '../support/creations/Creation';
import { Profile } from './creations/Profile';
import { newSVGWithPath, scaledSVG } from './helpers';
import { SelectionBoxPoints } from '../support/SelectionBox';
import Tracer from '../support/Tracer';


export interface Part {
  path: string,
  name: string,
  rotation?: BABYLON.Vector3,
  color?: BABYLON.Color3,
  position?: BABYLON.Vector3
}

export const parts: Part[] = [
  {
    path: 'MarioStar-Solid.stl',
    name: 'star',
    color: BABYLON.Color3.Yellow()
  },
  {
    path: 'MarioStar-Eyes.stl',
    name: 'eyes',
    color: BABYLON.Color3.Black(),
    rotation: new BABYLON.Vector3(BABYLON.Angle.FromDegrees(270).radians(), 0, 0),
    position: new BABYLON.Vector3(0, 16, -5)
  }
]

export class Bitmap {
  w: number;
  h: number;
  buffer: ArrayBuffer;
  data: Int8Array;

  constructor(w: number, h: number) {
    this.w = w;
    this.h = h;
    this.buffer = new ArrayBuffer(w * h);
    this.data = new Int8Array(this.buffer);
  }

  indexFor(x: number, y: number): number {
    return y * this.w + x;
  }

  get(x: number, y: number): number {
    return this.data[this.indexFor(x, y)];
  }

  set(x: number, y: number, value: number) {
    this.data[this.indexFor(x, y)] = value;
  }

  lateralSymmetry(darkCount: number): number {
    const actualSymmetric = new Bitmap(this.w, this.h);
    for (let y = 0; y < this.h; y++) {
      for (let x = 0; x < this.w; x++) {
        const index = y * this.w + x;
        if (x <= this.w / 2) {
          actualSymmetric.data[index] = this.data[index];
        } else {
          actualSymmetric.data[index] = this.data[index - ((x % (this.w / 2)) * 2)];
        }
      }
    }
    return 1 - (this.diffCountFor(actualSymmetric) / darkCount)
  }

  verticalSymmetry(darkCount: number): number {
    const actualSymmetric = new Bitmap(this.w, this.h);
    for (let y = 0; y < this.h; y++) {
      for (let x = 0; x < this.w; x++) {
        const index = y * this.w + x;
        if (y <= this.h / 2) {
          actualSymmetric.data[index] = this.data[index];
        } else {
          const mirrorY = y - (y % (this.h / 2) * 2);
          const mirrorIndex = mirrorY * this.w + x;
          actualSymmetric.data[index] = this.data[mirrorIndex];
        }
      }
    }
    return 1 - (this.diffCountFor(actualSymmetric) / darkCount)
  }

  transposed(): Bitmap {
    const tHeight = this.w;
    const tWidth = this.h;
    const t = new Bitmap(tWidth, tHeight);
    for (let y = 0; y < tHeight; y++) {
      for (let x = 0; x < tWidth; x++) {
        t.set(x, y, this.get(y, x));
      }
    }
    return t;
  }

  rotated90(): Bitmap {
    const transposed = this.transposed();
    const rotated = new Bitmap(transposed.w, transposed.h);
    for (let y = 0; y < transposed.h; y++) {
      for (let x = 0; x < transposed.w; x++) {
        rotated.set(transposed.w - x, y, transposed.get(x, y));
      }
    }
    return rotated;
  }

  rotated(rotations: number): Bitmap {
    if (rotations === 0) {
      return this;
    } else {
      return this.rotated90().rotated(rotations - 1);
    }
  }

  distinctRotations(): number[] {
    const rotations = [0];
    const one = this.rotated90();
    const two = one.rotated90();
    const three = two.rotated90();
    if (this.similarityWith(one) < 0.98) {
      rotations.push(1);
      if (one.similarityWith(three) < 0.98) {
        rotations.push(3);
      }
    }
    if (this.similarityWith(two) < 0.98) {
      rotations.push(2);
    }
    return rotations;
  }

  diffCountFor(other: Bitmap): number {
    let diffCount = 0;
    const data1 = this.data;
    const data2 = other.data;
    for (let i = 0; i < data1.length; i++) {
      if (data1[i] != data2[i]) diffCount++;
    }
    return diffCount;
  }

  similarityWith(other: Bitmap): number {
    if (this.w !== other.w || this.h !== other.h) {
      return 0;
    } else {
      const diffCount = this.diffCountFor(other);
      let darkCount = 0;
      const data1 = this.data;
      const data2 = other.data;
      for (let i = 0; i < data1.length; i++) {
        if (data1[i] || data2[i]) {
          darkCount++;
        }
      }
      return 1 - (diffCount / darkCount);
    }
  }

  static buildFromSVG(svg: SVGSVGElement): Promise<Bitmap> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const xml = window.btoa(unescape(encodeURIComponent(svg.outerHTML)));
      img.src = "data:image/svg+xml;base64," + xml;
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          const bm = new Bitmap(canvas.width, canvas.height);
          var imgdataobj = ctx.getImageData(0, 0, bm.w, bm.h);
          const l = imgdataobj.data.length;
          for (let i = 0, j = 0; i < l; i += 4, j++) {
            bm.data[j] = (imgdataobj.data[i + 3]) > 0 ? 1 : 0;
          }
          resolve(bm);
        } else {
          reject(`Problem generating bitmap for svg: ${svg.outerHTML}`);
        }
      };
    });
  }
}

export function hullFor(svg: SVGSVGElement, concavity?: number): SVGSVGElement | undefined {
  const svgPath = svg.firstChild as HTMLElement;
  const d = svgPath?.getAttribute('d');
  if (d) {
    const points = toPoints({ type: 'path', d: d }).map(ea => [ea.x, ea.y]);
    const hullPoints = concaveman(points, concavity || 10);
    const path = toPath(hullPoints.map(ea => {
      return {
        x: ea[0],
        y: ea[1],
      }
    }));
    return newSVGWithPath(path);
  } else {
    return undefined;
  }
}

export function darkCountFor(bm1: Bitmap, bm2: Bitmap): number {
  let darkCount = 0;
  const data1 = bm1.data;
  const data2 = bm2.data;
  for (let i = 0; i < data1.length; i++) {
    if (data1[i] || data2[i]) {
      darkCount++;
    }
  }
  return darkCount;
}

export async function symmetryFor(svg: SVGSVGElement, fn: (bm: Bitmap) => (darkCount: number) => number): Promise<number> {
  const hull = hullFor(svg);
  if (hull) {
    const hullBM = await Bitmap.buildFromSVG(hull);
    const bm = await Bitmap.buildFromSVG(svg);
    const darkCount = darkCountFor(bm, hullBM);
    return fn(bm)(darkCount);
  } else {
    return 0;
  }
}

export async function lateralSymmetryFor(svg: SVGSVGElement): Promise<number> {
  return symmetryFor(svg, bm => bm.lateralSymmetry.bind(bm));
}

export async function verticalSymmetryFor(svg: SVGSVGElement): Promise<number> {
  return symmetryFor(svg, bm => bm.verticalSymmetry.bind(bm));
}

export interface TraceConfig {
  threshold: number,
  turdsize: number,
  scale?: number,
  shouldSmooth: boolean,
  shouldUseStroke: boolean,
}

export class Trace {
  svg: SVGSVGElement;
  bitmap: Bitmap;

  constructor(svg: SVGSVGElement, bitmap: Bitmap) {
    this.svg = svg;
    this.bitmap = bitmap;
  }

  async scaled(factor: number): Promise<Trace> {
    const newSVG = scaledSVG(this.svg, factor);
    const newBitmap = await Bitmap.buildFromSVG(newSVG);
    return new Trace(newSVG, newBitmap);
  }

  numPoints(): number {
    const pathString = this.svg.getElementsByTagName('path')?.item(0)?.getAttribute('d');
    const points = pathString ? toPoints({ type: 'path', d: pathString }) : undefined;
    return points?.length || Number.POSITIVE_INFINITY;
  }

}

export async function buildTraceFor(svg: SVGSVGElement): Promise<Trace> {
  const bitmap = await Bitmap.buildFromSVG(svg);
  return new Trace(svg, bitmap);
}

export async function buildNewTraceFrom(imageUrl: string): Promise<Trace | undefined> {
  const trace = await new Tracer(imageUrl).trace({
    threshold: DefaultThreshold,
    turdsize: DefaultTurdsize,
    shouldSmooth: false,
    shouldUseStroke: false,
  });
  return trace ? trace : undefined;
}

export const TracePadding = 100;

export function scale(): number {
  return 2; // not using window.devicePixelRatio
}

export function desiredTraceExtent() {
  return 1000 * scale();
}
export function desiredWidth() {
  return desiredTraceExtent();
}

export function desiredHeight() {
  return Math.round(desiredTraceExtent() * (2 / 3));
}

export interface SVGAdjustment {
  scale: number,
  translateX: number,
  translateY: number,
}

export function bboxFor(svg: SVGSVGElement): DOMRect {
  document.body.appendChild(svg);
  const bbox = svg.getBBox();
  document.body.removeChild(svg);
  return bbox;
}

export interface ScaleForOptions {
  targetHeight?: number,
  targetWidth?: number,
  useMaxScale?: boolean
}

export function scaleFor(bbox: DOMRect, options: ScaleForOptions) {
  const { targetHeight, targetWidth } = options;
  const scaleForHeight = targetHeight ? (targetHeight - (TracePadding * 2)) / bbox.height : undefined;
  const scaleForWidth = targetWidth ? (targetWidth - (TracePadding * 2)) / bbox.width : undefined;
  if (scaleForHeight !== undefined) {
    if (scaleForWidth !== undefined) {
      return Math.min(scaleForHeight, scaleForWidth);
    } else {
      return scaleForHeight;
    }
  } else {
    if (scaleForWidth !== undefined) {
      return scaleForWidth;
    } else {
      return 1;
    }
  }
}

export function adjustmentFor(svg: SVGSVGElement): SVGAdjustment {
  const targetHeight = desiredHeight();
  const targetWidth = desiredWidth();
  const bbox = bboxFor(svg);
  const scale = scaleFor(bbox, { targetHeight, targetWidth })
  const centerX = bbox.x + (bbox.width / 2);
  const centerY = bbox.y + (bbox.height / 2);
  const translateX = (targetWidth / 2) - (centerX * scale);
  const translateY = (targetHeight / 2) - (centerY * scale);
  return {
    scale,
    translateX,
    translateY,
  }
}

export function adjust(svg: SVGSVGElement, adjustment: SVGAdjustment): SVGSVGElement {
  const adjusted = svg.cloneNode(true) as SVGSVGElement;
  const path = adjusted?.getElementsByTagName('path')[0];
  const pathData = path?.getAttribute('d');
  if (path && pathData) {
    const updatedPath = svgpath(pathData).
      scale(adjustment.scale).
      translate(adjustment.translateX, adjustment.translateY).
      round(0).
      toString();
    path.setAttribute('d', updatedPath);
    adjusted.setAttribute('height', desiredHeight().toString());
    adjusted.setAttribute('width', desiredWidth().toString());
  }
  return adjusted;
}

export function scaleToDesiredExtent(svg: SVGSVGElement | undefined): SVGSVGElement | undefined {
  if (svg) {
    const adjustment = adjustmentFor(svg);
    return adjust(svg, adjustment);
  } else {
    return undefined;
  }
}

export function scalingFactorFor(width: number, height: number, originalPadding: number): number {
  const byWidth = (desiredTraceExtent() - (originalPadding * 2)) / width;
  const byHeight = (desiredTraceExtent() - (originalPadding * 2)) / height;
  return Math.min(byWidth, byHeight);
}

export interface ProfileUpdateRequest {
  profile: Profile,
  isRotationRequested: boolean,
  refinementId: string | undefined,
}

export interface CurrentSetting {
  profileAxisName?: AxisName,
  refinementId?: string,
  materialOverrideId?: string,
  selectionBoxPoints?: SelectionBoxPoints,
}

export type TraceMakerState = 'template-intro' |
  'editor' |
  'rotations' |
  'choosingComponentKind' |
  'choosingImageTrace' |
  'choosingBacking' |
  'n/a';

export type QualityLevel = 'High' | 'Medium' | 'Low' | 'Lowest';

export type OrderStatus = "not-set" | "none" | "starting" | "choosing-scale" | "just-paid" | "just-requested-model" | "cart";

export type ScaleObjectId = 'metric-ruler' | 'imperial-ruler' | 'banana';

export function formatDimension(dim: number, scaleObjectId: ScaleObjectId) {
  if (scaleObjectId === 'imperial-ruler') {
    return Math.round(dim * 10 / 25.4) / 10 + "in"
  } else {
    return Math.round(dim) / 10 + "cm";
  }
}

export type ExportType = 'STL' | 'OBJ';

export interface ExportConfig {
  scale: number,
  exportType: ExportType,
  maxTriangles: number,
  pinDiameter: number,
  isDryRun: boolean,
  isSplit: boolean,
}

export interface ExportConfigInputData {
  scale?: string,
  exportType?: ExportType,
  maxTriangles?: string,
  pinDiameter?: string,
  isDryRun?: boolean,
  isSplit?: boolean,
}
