import * as BABYLON from '@babylonjs/core';

abstract class Curve {

  abstract getPoint(t: number): BABYLON.Vector2;

  isLineCurve(): boolean { return false }
  isEllipseCurve(): boolean { return false }
  isSplineCurve(): boolean { return false }
  isLineCurve3(): boolean { return false }

  getPoints(divisions: number): BABYLON.Vector2[] {
    const points = [];

    for (let d = 0; d <= divisions; d++) {
      points.push(this.getPoint(d / divisions));
    }

    return points;
  }
}

export class LineCurve extends Curve {
  v1: BABYLON.Vector2;
  v2: BABYLON.Vector2;

  isLineCurve(): boolean {
    return true;
  }

  getPoint(t: number): BABYLON.Vector2 {

    if (t === 1) {

      return this.v2.clone();

    } else {

      const point = this.v2.clone();
      point.subtractInPlace(this.v1);
      point.multiplyInPlace(new BABYLON.Vector2(t, t));
      point.addInPlace(this.v1);
      return point;

    }

  }

  constructor(v1: BABYLON.Vector2, v2: BABYLON.Vector2) {
    super();
    this.v1 = v1;
    this.v2 = v2;
  }
}

export class CubicBezierCurve extends Curve {
  v1: BABYLON.Vector2;
  cp1: BABYLON.Vector2;
  cp2: BABYLON.Vector2;
  v2: BABYLON.Vector2;

  getPoint(t: number): BABYLON.Vector2 {
    const x = (1 - t) * (1 - t) * (1 - t) * this.v1.x + 3 * (1 - t) * (1 - t) * t * this.cp1.x + 3 * (1 - t) * t * t * this.cp2.x + t * t * t * this.v2.x;
    const y = (1 - t) * (1 - t) * (1 - t) * this.v1.y + 3 * (1 - t) * (1 - t) * t * this.cp1.y + 3 * (1 - t) * t * t * this.cp2.y + t * t * t * this.v2.y;
    return new BABYLON.Vector2(x, y);
  }

  constructor(v1: BABYLON.Vector2, cp1: BABYLON.Vector2, cp2: BABYLON.Vector2, v2: BABYLON.Vector2) {
    super();
    this.v1 = v1;
    this.cp1 = cp1;
    this.cp2 = cp2;
    this.v2 = v2;
  }
}

export class CurvePath {
  curves: Curve[] = [];
  autoClose: boolean = false;
  currentPoint: BABYLON.Vector2 = new BABYLON.Vector2();

  getPoints(divisions?: number) {

    const points: BABYLON.Vector2[] = [];
    let last: BABYLON.Vector2 | undefined;

    for (let i = 0, curves = this.curves; i < curves.length; i++) {

      const curve = curves[i];
      // const resolution = (curve && curve.isEllipseCurve) ? divisions * 2
      //   : (curve && (curve.isLineCurve || curve.isLineCurve3)) ? 1
      //     : (curve && curve.isSplineCurve) ? divisions * curve.points.length
      //       : divisions;
      const resolution = curve.isLineCurve() ? 1 : divisions || 5;

      const pts = curve.getPoints(resolution);

      for (let j = 0; j < pts.length; j++) {

        const point = pts[j];

        if (last && last.equals(point)) continue; // ensures no consecutive points are duplicates

        points.push(point);
        last = point;

      }

    }

    if (this.autoClose && points.length > 1 && !points[points.length - 1].equals(points[0])) {

      points.push(points[0]);

    }

    return points;

  }

  moveTo(x: number, y: number) {
    this.currentPoint.set(x, y);
    return this;
  }

  lineTo(x: number, y: number) {
    const curve = new LineCurve(this.currentPoint.clone(), new BABYLON.Vector2(x, y));
    this.curves.push(curve);
    this.currentPoint.set(x, y);
    return this;
  }

  bezierCurveTo(aCP1x: number, aCP1y: number, aCP2x: number, aCP2y: number, aX: number, aY: number) {

    const curve = new CubicBezierCurve(
      this.currentPoint.clone(),
      new BABYLON.Vector2(aCP1x, aCP1y),
      new BABYLON.Vector2(aCP2x, aCP2y),
      new BABYLON.Vector2(aX, aY)
    );

    this.curves.push(curve);

    this.currentPoint.set(aX, aY);

    return this;

  }

}

export class Shape extends CurvePath {

  holes: CurvePath[] = [];

  getPointsHoles(divisions: number): BABYLON.Vector2[][] {
    return this.holes.map(ea => ea.getPoints(divisions));
  }

  extractPoints(divisions: number): {
    shape: BABYLON.Vector2[];
    holes: BABYLON.Vector2[][];
  } {
    return {
      shape: this.getPoints(divisions),
      holes: this.getPointsHoles(divisions)
    }
  }

}

export interface PointShape {
  path: BABYLON.Vector2[],
  holes: BABYLON.Vector2[][];
}