import { Bitmap, Trace, TraceConfig } from './models';
import ImageLoader from './ImageLoader';
import { roundedSVGFor, scaledSVG } from './helpers';

export default class Tracer {

  imageUrl: string;

  constructor(imageUrl: string) {
    this.imageUrl = imageUrl;
  }

  async traceAll(configs: TraceConfig[]): Promise<Trace[]> {
    const tracesOrNulls = await Promise.all(configs.map(ea => {
      return this.trace(ea);
    }));
    const traces = tracesOrNulls.filter((ea: Trace | null): ea is Trace => ea !== null);
    return (await this.usefulIn(traces)).sort((a, b) => a.numPoints() < b.numPoints() ? -1 : 1);
  }

  async usefulIn(unfiltered: Trace[]): Promise<Trace[]> {
    let usefuls: Trace[] = [];
    unfiltered.reverse().forEach(ea => {
      const eaNumPoints = ea.numPoints();
      const matchIndex = usefuls.findIndex(eaUseful => ea.bitmap.similarityWith(eaUseful.bitmap) > 0.98);
      if (matchIndex === -1) {
        if (eaNumPoints < 1500) usefuls.push(ea);
      } else if (usefuls[matchIndex].numPoints() > eaNumPoints) {
        usefuls[matchIndex] = ea;
      }
    });
    usefuls = usefuls.filter(ea => !ea.bitmap.data.every(ea => ea === 0) && !ea.bitmap.data.every(ea => ea === 0));
    return usefuls;
  }

  async trace(config: TraceConfig): Promise<Trace | null> {
    const svgString = await this.svgString(config);
    const elt: HTMLDivElement = document.createElement('div');
    if (svgString) {
      elt.innerHTML = svgString;
    }
    let svg = elt.getElementsByTagName("svg").item(0);
    if (svg) {
      svg = roundedSVGFor(svg);
      if (config.scale) {
        svg = scaledSVG(svg, config.scale);
      }
      const bitmap = await Bitmap.buildFromSVG(svg);
      return new Trace(svg, bitmap);
    } else {
      return null;
    }
  }

  async alphamaxToUse(shouldSmooth: boolean): Promise<number> {
    const dimensions = await new ImageLoader(this.imageUrl).dimensions();
    const area = dimensions.width * dimensions.height;
    if (shouldSmooth) {
      if (area > 1000000) {
        return 0.25;
      } else if (area > 500000) {
        return 0.5;
      } else if (area > 100000) {
        return 0.75;
      } else {
        return 1;
      }
    } else {
      return 0.1;
    }
  }

  async svgString(config: TraceConfig): Promise<string | null> {
    const Tracer = require('../support/baseTracer');
    const tracer = Tracer.default(document);
    const alphamax = await this.alphamaxToUse(config.shouldSmooth);
    return new Promise((resolve, reject) => {
      tracer.loadImageFromUrl(this.imageUrl);
      tracer.setParameter({
        turnpolicy: "minority",
        turdsize: config.turdsize,
        alphamax: alphamax,
        blackOnWhite: false,
        threshold: config.threshold,
        optcurve: true,
        opttolerance: 1,
        shouldUseStroke: config.shouldUseStroke,
      });
      tracer.process(() => {
        resolve(tracer.getSVG(1));
      });
    });
  }
}