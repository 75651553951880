import * as BABYLON from '@babylonjs/core';

import { Backing } from './Backing';
import { CreationVersion, MaterialMesh, MaterialMeshGroups } from './Creation';
import { getMaterial, Material } from './Material';
import { Axis, AxisName, XAxis, YAxis, ZAxis } from './Axis';
import { buildProfile, NewProfileProps, profileFileFor, Profile, ShapesInfo, TraceProfile } from './Profile';
import { buildRefinementsFrom, RefinementMesh, TraceProfileRefinement } from './TraceProfileRefinement';
import {
  fixUpNormalsForMesh,
  intersect,
  intersectAll,
  isMeshBigEnough,
  newSVGWithPath,
  subtract,
  targetModelSize,
  unionAll,
} from '../helpers';
import * as ApiModels from '../apiModels';
import boundingBoxForAll from '../boundingBoxForAll';
import { newModelName } from '../uniqueName';
import { adjust, adjustmentFor, lateralSymmetryFor, QualityLevel } from '../models';
import { boxDimensionOptionsFor, centerFor } from '../SelectionBox';

export interface MaterialOverrideConstructorProps {
  id: string,
  material: Material,
  minPoint: BABYLON.Vector3,
  maxPoint: BABYLON.Vector3,
  templateNotes: ApiModels.MaterialOverrideTemplateNotes | undefined,
}

export interface MaterialOverrideCloneProps {
  material?: Material,
  minPoint?: BABYLON.Vector3,
  maxPoint?: BABYLON.Vector3,
  templateNotes?: ApiModels.MaterialOverrideTemplateNotes | undefined,
}

export class MaterialOverride {
  id: string;
  material: Material;
  minPoint: BABYLON.Vector3;
  maxPoint: BABYLON.Vector3;
  templateNotes: ApiModels.MaterialOverrideTemplateNotes | undefined;

  constructor(props: MaterialOverrideConstructorProps) {
    this.id = props.id;
    this.material = props.material;
    this.minPoint = props.minPoint;
    this.maxPoint = props.maxPoint;
    this.templateNotes = props.templateNotes;
  }

  clone(props: MaterialOverrideCloneProps) {
    return new MaterialOverride({
      id: this.id,
      material: props.material || this.material,
      minPoint: props.minPoint || this.minPoint,
      maxPoint: props.maxPoint || this.maxPoint,
      templateNotes: props.templateNotes || this.templateNotes,
    })
  }

  toApiProps(): ApiModels.MaterialOverride {
    return {
      id: this.id,
      material: this.material.kind,
      minPoint: {
        x: this.minPoint.x,
        y: this.minPoint.y,
        z: this.minPoint.z,
      },
      maxPoint: {
        x: this.maxPoint.x,
        y: this.maxPoint.y,
        z: this.maxPoint.z,
      },
      templateNotes: this.templateNotes,
    }
  }

  buildMeshes(existing: MaterialMeshGroups, unionCSG: BABYLON.CSG, scene: BABYLON.Scene): MaterialMeshGroups {
    const bb = existing.boundingBox();
    const box = BABYLON.MeshBuilder.CreateBox(newModelName(), boxDimensionOptionsFor(this.minPoint, this.maxPoint, bb.extendSize), scene);
    box.position = centerFor(this.minPoint, this.maxPoint, bb.extendSize, bb.center);
    box.bakeCurrentTransformIntoVertices();
    const result = new MaterialMeshGroups([]);
    const boxCSG = BABYLON.CSG.FromMesh(box);
    const intersectionCSG = intersect(boxCSG, unionCSG);
    const intersection = intersectionCSG.toMesh(newModelName(), undefined, scene);
    result.add([{
      material: this.material,
      mesh: intersection,
    }]);
    existing.groups.forEach(eaGroup => {
      const newGroup: MaterialMesh[] = [];
      eaGroup.forEach(ea => {
        if (ea.mesh.subMeshes) {
          const csg = BABYLON.CSG.FromMesh(ea.mesh);
          const diff = subtract(csg, boxCSG).toMesh(newModelName(), undefined, scene);
          if (diff.subMeshes && isMeshBigEnough(diff)) {
            newGroup.push({
              material: ea.material,
              mesh: diff,
            });
          }

          ea.mesh.dispose();
        }
      });
      result.add(newGroup);
    });

    box.dispose();
    return result;
  }

  static newForCreation(creation: CreationVersion, id: string) {
    return new MaterialOverride({
      id: id,
      material: creation.decorationMaterial.isLight() ? getMaterial("charcoal") : getMaterial("silver"),
      minPoint: new BABYLON.Vector3(0, 0, 0),
      maxPoint: new BABYLON.Vector3(1, 1, 0.5),
      templateNotes: undefined,
    })
  }
}

class CSGWork {
  toIntersect: BABYLON.CSG[] = [];
  toSubtract: BABYLON.CSG[] = [];

  addToIntersect(csg: BABYLON.CSG) {
    this.toIntersect.push(csg);
  }

  addToSubtract(csg: BABYLON.CSG) {
    this.toSubtract.push(csg);
  }

  runOn(csg: BABYLON.CSG): BABYLON.CSG {
    let result = intersectAll([csg].concat(this.toIntersect));
    if (this.toSubtract.length > 0) {
      const subtractUnion = unionAll(this.toSubtract);
      result = subtract(result, subtractUnion);
    }
    return result;
  }
}

export type ComponentKind = "xyz" | "lathe" | "squared";

export interface ComponentCloneProps {
  materialOverrides?: MaterialOverride[],
  orderedMaterialRefinementIds?: string[],
  templateNotes?: ApiModels.ComponentTemplateNotes,
  choseDoneProfiles?: boolean,
  useProfileMeshesCache?: boolean,
}

export interface ComponentConstructorProps extends ComponentCloneProps {
  id: string,
  templateNotes?: ApiModels.ComponentTemplateNotes,
  choseDoneProfiles?: boolean,
  profileMeshesCache?: MaterialMesh[],
}

export abstract class Component {
  id: string;
  materialOverrides: MaterialOverride[];
  orderedMaterialRefinementIds: string[];
  choseDoneProfiles: boolean;
  templateNotes: ApiModels.ComponentTemplateNotes | undefined;
  profileMeshesCache: MaterialMesh[] | undefined;

  abstract getMainProfile(): Profile | undefined;
  abstract orderedAxes(): Axis[];
  abstract kind: ComponentKind;
  abstract buildMeshes(scene: BABYLON.Scene, qualityLevel: QualityLevel): MaterialMeshGroups;
  abstract allProfiles(): Profile[];
  abstract shouldChooseComponentKind(profile: Profile): Promise<boolean>;
  abstract isSymmetric: boolean;

  constructor(props: ComponentConstructorProps) {
    this.id = props.id;
    this.materialOverrides = props.materialOverrides || [];
    this.orderedMaterialRefinementIds = props.orderedMaterialRefinementIds || [];
    this.choseDoneProfiles = !!props.choseDoneProfiles;
    this.templateNotes = props.templateNotes;
    this.profileMeshesCache = props.profileMeshesCache;
  }

  abstract suggestedYProfileSVGFor(creationVersion: CreationVersion): SVGSVGElement | undefined;

  hasSurfaceRefinement(): boolean {
    return this.allProfiles().some(ea => ea.hasSurfaceRefinement());
  }

  hasShapeRefinementWithMaterial(): boolean {
    return this.allProfiles().some(eaProfile => {
      return eaProfile.refinements.some(eaRef => {
        return eaRef.applicationType === 'shape' && eaRef.material !== undefined;
      });
    });
  }

  ensureRefinementIds() {
    let newOrderedMaterialRefinementIds = this.orderedMaterialRefinementIds;
    const refinements = this.explicitMaterialRefinements();
    refinements.forEach(({ profile, refinement }) => {
      if (!newOrderedMaterialRefinementIds.includes(refinement.id)) {
        newOrderedMaterialRefinementIds.unshift(refinement.id);
      }
    });
    newOrderedMaterialRefinementIds = newOrderedMaterialRefinementIds.filter(eaId => {
      return refinements.findIndex(eaRef => eaRef.refinement.id === eaId) !== -1;
    });
    this.orderedMaterialRefinementIds = newOrderedMaterialRefinementIds;
  }

  asLatheComponent(): LatheComponent {
    const profiles = this.allProfiles();
    const mainProfile = profiles[profiles.length - 1];
    let otherProfile = profiles.length > 1 ? profiles[0] : profiles[2];
    return new LatheComponent({
      id: this.id,
      mainProfile: mainProfile,
      otherProfile: otherProfile,
    });
  }

  newSquareSVG(): SVGSVGElement {
    return newSVGWithPath("M434 667L434 1233 1001 1233 1567 1233 1567 667 1567 100 1001 100 434 100 434 667");
  }

  newCircleSVG(): SVGSVGElement {
    return newSVGWithPath("M977 101C840 109 725 155 626 242L612 254 596 270C553 314 520 360 491 418L480 439 475 455C452 512 439 567 434 628L433 649 433 665C433 964 660 1209 961 1231L980 1233 1000 1233 1021 1233 1039 1231C1424 1202 1667 816 1527 458L1521 442 1510 420C1481 360 1448 314 1404 269L1389 253 1375 242C1280 159 1168 111 1046 102L1034 101 1009 101C994 100 980 100 977 101");
  }

  asSquaredComponent(creationVersion: CreationVersion): SquaredComponent {
    const profiles = this.allProfiles();
    const mainProfile = profiles[profiles.length - 1];
    let otherProfile = profiles.length > 1 ? profiles[0] : profiles[2];
    return new SquaredComponent({
      id: this.id,
      mainProfile: mainProfile.cloneForSquaredComponent(creationVersion),
      otherProfile: otherProfile,
      choseDoneProfiles: false,
    });
  }

  supportsRefinementsFor(profile: Profile): boolean {
    return true;
  }

  applyRefinedMesh(
    refMesh: RefinementMesh,
    remainderWork: CSGWork,
    totalShapeWork: CSGWork,
    useCache: boolean,
  ) {
    if (refMesh.applicationType !== 'material') {
      if (refMesh.meshes.length > 0) {
        remainderWork.addToIntersect(refMesh.biggerToIntersectCSG);
        remainderWork.addToSubtract(refMesh.unionCSG);
      } else {
        remainderWork.addToIntersect(refMesh.biggerToIntersectCSG);
      }
      if (this.hasShapeRefinementWithMaterial()) totalShapeWork.addToIntersect(refMesh.biggerToIntersectCSG);
    }
    if (useCache) {
      refMesh.setEnabled(false);
    } else {
      refMesh.dispose();
    }
  }

  shouldFixUpNormals(): boolean {
    return true;
  }

  fixUpNormalsFor(meshGroups: MaterialMeshGroups) {
    if (this.shouldFixUpNormals()) {
      meshGroups.groups.forEach(eaGroup => {
        eaGroup.forEach(ea => {
          fixUpNormalsForMesh(ea.mesh);
        });
      });
    }
  }

  explicitMaterialRefinements() {
    const result: { profile: TraceProfile, refinement: TraceProfileRefinement }[] = [];
    this.allProfiles().forEach(eaProfile => {
      if (eaProfile instanceof TraceProfile) {
        const materialRefinements = eaProfile.refinements.filter(ea => ea.applicationType === 'material');
        materialRefinements.forEach(ea => {
          result.push({
            profile: eaProfile,
            refinement: ea,
          });
        });
      }
    });
    return result;
  }

  materialRefinements() {
    return this.explicitMaterialRefinements();
  }

  orderedMaterialRefinements() {
    return this.materialRefinements().sort((a, b) => {
      return this.orderedMaterialRefinementIds.indexOf(a.refinement.id) > this.orderedMaterialRefinementIds.indexOf(b.refinement.id) ? 1 : -1;
    });
  }

  buildMeshesWithMaterials(scene: BABYLON.Scene, qualityLevel: QualityLevel): MaterialMeshGroups {
    let meshes = this.buildMeshes(scene, qualityLevel);
    const refs = this.orderedMaterialRefinements().filter(ea => ea.refinement.material !== undefined).reverse();
    if (meshes.hasValidBabylonMeshes() && (this.materialOverrides.length > 0 || refs.length > 0)) {
      const unionCSG = meshes.unionCSG();
      refs.forEach(({ profile, refinement }) => {
        if (refinement.shouldApply(qualityLevel)) {
          const toRender: TraceProfileRefinement[] = [refinement];
          if (refinement.isMirrored) {
            toRender.push(refinement.cloneAsReflection(refinement.isMirrorFlipped));
          }
          profile.ensurePreparedForRender(qualityLevel);
          toRender.forEach(eaToRender => {
            eaToRender.ensurePreparedForRender(profile, qualityLevel);
            meshes = eaToRender.buildMaterialMeshes(meshes, unionCSG, scene, profile);
          });
        }
      });
      this.materialOverrides.forEach(ea => {
        meshes = ea.buildMeshes(meshes, unionCSG, scene);
      });
    }

    return meshes;
  }

  isNew(): boolean {
    return this.allProfiles().length === 0;
  }

  isDoneProfiles(): boolean {
    return this.nextMissingAxis() === undefined || this.choseDoneProfiles;
  }

  nextMissingAxis(): Axis | undefined {
    const withProfile = this.allProfiles().map(ea => ea.axis.name);
    for (let i = 0; i < this.orderedAxes().length; i++) {
      let axis = this.orderedAxes()[i];
      if (withProfile.indexOf(axis.name) === -1) {
        return axis;
      }
    }
    return undefined;
  }

  profileForName(axisName: string): Profile | undefined {
    return this.allProfiles().find(ea => ea.axis.name === axisName);
  }

  profileFor(axis: Axis): Profile | undefined {
    return this.profileForName(axis.name);
  }

  refinementWithId(refinementId: string): TraceProfileRefinement | undefined {
    const profiles = this.allProfiles();
    for (const profile of profiles) {
      const refinement = profile.refinementWithId(refinementId);
      if (refinement) return refinement;
    }
    return undefined;
  }

  async newProfileFrom(props: NewProfileProps): Promise<Profile | undefined> {
    const { desc, refinementId, selectedTrace, currentProfile, refinements, backing } = props;
    let refinementsToUse = refinements || [];
    let rotationsToUse = props.rotations;
    let originalFile: File | undefined;
    let svg: SVGSVGElement | undefined;
    let initialSVG: SVGSVGElement | undefined;
    if (desc.kind === "trace") {
      if (refinementId) {
        svg = currentProfile instanceof TraceProfile ? currentProfile.svg : undefined;
      } else {
        svg = selectedTrace?.svg;
        if (svg) {
          const adjustment = adjustmentFor(svg);
          svg = adjust(svg, adjustment);
          initialSVG = (currentProfile instanceof TraceProfile ? currentProfile.initialSVG : undefined) || props.initialSVG;
          initialSVG = initialSVG ? adjust(initialSVG, adjustment) : undefined;
          refinementsToUse = refinementsToUse.map(ea => {
            const refSVG = ea.svg ? adjust(ea.svg, adjustment) : undefined;
            return ea.clone({
              svg: refSVG,
              rotations: props.rotations,
            });
          });
        } else {
          if (rotationsToUse === undefined) {
            rotationsToUse = currentProfile instanceof TraceProfile ? currentProfile.rotations : undefined;
          }
          originalFile = await profileFileFor(this.id, currentProfile.axis);
        }
      }
    }
    const templateNotes = currentProfile instanceof TraceProfile ? currentProfile.templateNotes : undefined;
    return await buildProfile(
      currentProfile.axis,
      desc.kind,
      rotationsToUse,
      originalFile,
      svg,
      initialSVG,
      refinementsToUse,
      backing,
      templateNotes
    );
  }

  scale(mesh: BABYLON.Mesh, scalingFactor: number) {
    mesh.scaling = new BABYLON.Vector3(scalingFactor, scalingFactor, scalingFactor);
    if (mesh.subMeshes) mesh.bakeCurrentTransformIntoVertices();
  }

  targetAspectRatioFor(axis: Axis): number | undefined {
    return undefined;
  }

  async cloneWithProfileFrom(props: NewProfileProps): Promise<Component> {
    const { refinementId, currentProfile, selectedTrace, rotations } = props;
    const refinements = props.refinements || currentProfile.refinements;
    const refinementData = refinements.map(ea => {
      const data = ea.toApiProps();
      if (selectedTrace && refinementId && ea.id === refinementId) {
        Object.assign(data, { svg: selectedTrace.svg.outerHTML });
      }
      return data;
    });
    const newRefinements = await buildRefinementsFrom(
      currentProfile.axis,
      refinementData,
    );
    const backing = props.backing || props.currentProfile.backing;
    const newProfile = await this.newProfileFrom(Object.assign({}, props, {
      refinements: newRefinements,
      backing,
    }));
    const useCachedMeshes = false;
    return newProfile ? this.cloneWithProfile(newProfile, useCachedMeshes) : this;
  }

  cloneWithRefinements(
    refinements: TraceProfileRefinement[],
    currentProfile: TraceProfile,
  ): Component {
    const useCachedMeshes = true;
    return this.cloneWithProfile(currentProfile.cloneWithRefinements(refinements), useCachedMeshes);
  }

  cloneAsDoneProfiles(): Component {
    return this.clone({ choseDoneProfiles: true });
  }

  cloneAsNotDoneProfiles(): Component {
    return this.clone({ choseDoneProfiles: false });
  }

  abstract clone<T extends ComponentCloneProps>(props: T): Component;
  abstract cloneWithProfile(newProfile: Profile, useCachedMeshes: boolean): Component;
  abstract cloneWithoutShapesInfo(): Component;

  abstract apiProfiles(): ApiModels.Profiles;
  toApiProps(): ApiModels.Component {
    return {
      kind: this.kind,
      id: this.id,
      profiles: this.apiProfiles(),
      materialOverrides: this.materialOverrides.map(ea => ea.toApiProps()),
      orderedMaterialRefinementIds: this.orderedMaterialRefinementIds,
      templateNotes: this.templateNotes,
      choseDoneProfiles: this.choseDoneProfiles,
    }
  }
}

export function isXYZComponent(component: Component): component is XYZComponent {
  return component.kind === "xyz";
}

export interface XYZComponentConstructorProps extends ComponentConstructorProps {
  x?: Profile,
  y?: Profile,
  z?: Profile,
}

export interface XYZComponentCloneProps extends ComponentCloneProps {
  x?: Profile,
  y?: Profile,
  z?: Profile,
}

export class XYZComponent extends Component {
  kind: ComponentKind = "xyz";
  orderedAxes() {
    return [
      new ZAxis(),
      new XAxis(),
      new YAxis(),
    ];
  }
  x: Profile | undefined;
  y: Profile | undefined;
  z: Profile | undefined;
  isSymmetric = false;

  constructor(props: XYZComponentConstructorProps) {
    super(props);
    this.x = props.x;
    this.y = props.y;
    this.z = props.z;
    this.ensureRefinementIds();
  }

  suggestedYProfileSVGFor(creationVersion: CreationVersion): SVGSVGElement | undefined {
    return creationVersion.decorationMeshes.suggestedYProfileSVG();
  }

  async shouldChooseComponentKind(profile: Profile): Promise<boolean> {
    let hasLateralSymmetry = false;
    const existingProfiles = this.allProfiles();
    const lastProfile = existingProfiles[existingProfiles.length - 1];
    if (lastProfile instanceof TraceProfile && lastProfile.svg) {
      const symmetry = await lateralSymmetryFor(lastProfile.svg);
      hasLateralSymmetry = symmetry > 0.95;
    }
    return (existingProfiles.length === 1) &&
      profile.axis.name !== lastProfile.axis.name &&
      hasLateralSymmetry;
  }

  shouldSuggestAddSolidBacking(): boolean {
    const profiles = this.allProfiles();
    return profiles.length === 1 && profiles[0].shouldSuggestSolidBacking();
  }

  getMainProfile(): Profile | undefined {
    return this.z;
  }

  allProfiles(): Profile[] {
    const profiles: Profile[] = [];
    if (this.z) profiles.push(this.z);
    if (this.x) profiles.push(this.x);
    if (this.y) profiles.push(this.y);
    return profiles;
  }

  sortedProfiles(): Profile[] {
    return this.allProfiles().sort((a, b) => a.sortKey() < b.sortKey() ? 1 : -1)
  }

  targetAspectRatioFor(axis: Axis): number | undefined {
    const profiles: TraceProfile[] = [];
    this.allProfiles().forEach(ea => {
      if (ea instanceof TraceProfile) profiles.push(ea);
    })
    if (profiles.length >= 2) {
      let ratio = axis.aspectRatioForProfiles(profiles);
      const axisProfile = profiles.find(ea => ea.axis.name === axis.name)
      if (ratio && axisProfile && axisProfile.rotations && axisProfile.rotations % 2 === 1) {
        ratio = 1 / ratio;
      }
      return ratio;
    } else {
      return undefined;
    }
  }

  clone(props: XYZComponentCloneProps): Component {
    return new XYZComponent({
      id: this.id,
      x: props.x || (props.useProfileMeshesCache ? this.x : this.x?.cloneWithoutCache()),
      y: props.y || (props.useProfileMeshesCache ? this.y : this.y?.cloneWithoutCache()),
      z: props.z || (props.useProfileMeshesCache ? this.z : this.z?.cloneWithoutCache()),
      choseDoneProfiles: props.choseDoneProfiles === undefined ? this.choseDoneProfiles : props.choseDoneProfiles,
      materialOverrides: props.materialOverrides || this.materialOverrides,
      orderedMaterialRefinementIds: props.orderedMaterialRefinementIds || this.orderedMaterialRefinementIds,
      templateNotes: props.templateNotes || this.templateNotes,
      profileMeshesCache: props.useProfileMeshesCache ? this.profileMeshesCache : undefined,
    })
  }

  cloneWithProfile(newProfile: Profile, useCachedMeshes: boolean): Component {
    let props: XYZComponentCloneProps = { useProfileMeshesCache: useCachedMeshes };
    if (newProfile.axis.name === "x") Object.assign(props, { x: newProfile });
    if (newProfile.axis.name === "y") Object.assign(props, { y: newProfile });
    if (newProfile.axis.name === "z") Object.assign(props, { z: newProfile });
    return this.clone(props);
  }

  cloneWithoutShapesInfo(): Component {
    return this.clone({
      x: this.x?.cloneWithoutShapesInfo(),
      y: this.y?.cloneWithoutShapesInfo(),
      z: this.z?.cloneWithoutShapesInfo(),
    })
  }

  buildProfileMeshes(sortedProfiles: Profile[], scene: BABYLON.Scene): MaterialMesh[] {
    const meshes = sortedProfiles[0].
      buildMeshes(scene, [], [], sortedProfiles.slice(1), true).
      filter(ea => ea.mesh.subMeshes);
    const es = boundingBoxForAll(meshes.map(ea => ea.mesh)).extendSize;
    const maxExtent = Math.max(es.x, es.y, es.z);
    let scalingFactor = 50 / maxExtent;
    meshes.forEach(ea => this.scale(ea.mesh, scalingFactor));
    return meshes;
  }

  buildMeshes(scene: BABYLON.Scene, qualityLevel: QualityLevel): MaterialMeshGroups {
    this.allProfiles().forEach(ea => ea.ensurePreparedForRender(qualityLevel)); // needed to sort profiles correctly
    const sortedProfiles = this.sortedProfiles();
    const result = new MaterialMeshGroups([]);
    if (sortedProfiles.length > 0) {
      if (!this.profileMeshesCache) {
        this.profileMeshesCache = this.buildProfileMeshes(sortedProfiles, scene);
        this.profileMeshesCache.forEach(ea => ea.mesh.name = `cache-${ea.mesh.name}`);
      } else {
        this.profileMeshesCache.forEach(ea => ea.mesh.setEnabled(true));
      }
      const withHoles = this.profileMeshesCache;
      if (withHoles && withHoles.length > 0) {
        const remainderWork = new CSGWork();
        const totalShapeWork = new CSGWork();
        sortedProfiles.forEach(ea => {
          if (ea instanceof TraceProfile) {
            const refMeshes = ea.refinementMeshesFor(scene, withHoles, true, qualityLevel, true);
            refMeshes.forEach(eaRefMesh => {
              this.applyRefinedMesh(eaRefMesh, remainderWork, totalShapeWork, true);
              result.add(eaRefMesh.meshes);
            });
          }
        });
        let csgs = withHoles.map(ea => BABYLON.CSG.FromMesh(ea.mesh));
        csgs.forEach(csg => result.intersectInPlace(totalShapeWork.runOn(csg), scene));
        const remainderCSGs = csgs.map(csg => remainderWork.runOn(csg));
        withHoles.forEach(ea => ea.mesh.setEnabled(false));
        result.add(remainderCSGs.map(ea => {
          return {
            mesh: ea.toMesh(newModelName(), undefined, scene)
          }
        }));
      }
    }
    return result;
  }

  apiProfiles(): ApiModels.Profiles {
    return {
      x: this.x?.toApiProps(),
      y: this.y?.toApiProps(),
      z: this.z?.toApiProps(),
    }
  }

}

export function isSymmetricComponent(component: Component): component is SymmetricComponent {
  return component.isSymmetric;
}

export interface SymmetricComponentConstructorProps extends ComponentConstructorProps {
  mainProfile?: Profile,
  otherProfile?: Profile,
}

export interface SymmetricComponentCloneProps extends ComponentCloneProps {
  mainProfile?: Profile,
  otherProfile?: Profile,
}

export abstract class SymmetricComponent extends Component {
  mainProfile: Profile | undefined;
  otherProfile: Profile | undefined;

  orderedAxes(): Axis[] {
    const mainProfileAxis = this.mainProfile?.axis || new ZAxis();
    if (mainProfileAxis.name === 'z') {
      return [
        mainProfileAxis,
        new YAxis(),
      ];
    } else {
      return [
        new ZAxis(),
        mainProfileAxis,
      ]
    }

  }
  isSymmetric = true;
  abstract sidesText(): string;
  abstract clone(props: SymmetricComponentCloneProps): SymmetricComponent;

  constructor(props: SymmetricComponentConstructorProps) {
    super(props);
    this.mainProfile = props.mainProfile;
    this.otherProfile = props.otherProfile;
    this.ensureRefinementIds();
  }

  cloneWithProfile(newProfile: Profile, useCachedMeshes: boolean): Component {
    let props: SymmetricComponentCloneProps = { useProfileMeshesCache: useCachedMeshes };
    if (this.mainProfile) {
      if (newProfile.axis.name === this.mainProfile.axis.name) {
        props = { mainProfile: newProfile };
      } else {
        props = { otherProfile: newProfile };
      }
    } else {
      props = { mainProfile: newProfile };
    }
    return this.clone(props);
  }

  cloneWithoutShapesInfo(): Component {
    return this.clone({
      mainProfile: this.mainProfile?.cloneWithoutShapesInfo(),
      otherProfile: this.otherProfile?.cloneWithoutShapesInfo(),
    })
  }

  shouldChooseComponentKind(profile: Profile): Promise<boolean> {
    return Promise.resolve(false);
  }

  scalingFactorFor(meshes: BABYLON.Mesh[]): number {
    const es = boundingBoxForAll(meshes).extendSize;
    const maxExtent = Math.max(es.x, es.y, es.z);
    return (targetModelSize / 2) / maxExtent;
  }

  getMainProfile(): Profile | undefined {
    return this.mainProfile;
  }

  allProfiles(): Profile[] {
    const profiles: Profile[] = [];
    if (this.mainProfile) profiles.push(this.mainProfile);
    if (this.otherProfile) profiles.push(this.otherProfile);
    return profiles;
  }

  apiProfiles(): ApiModels.Profiles {
    const result: ApiModels.Profiles = {};
    if (this.mainProfile) result[this.mainProfile.axis.name] = this.mainProfile.toApiProps();
    if (this.otherProfile) result[this.otherProfile.axis.name] = this.otherProfile.toApiProps();
    return result;
  }

  asXYZ(): XYZComponent {
    const props: XYZComponentConstructorProps = {
      id: this.id,
      choseDoneProfiles: false,
      materialOverrides: this.materialOverrides,
      orderedMaterialRefinementIds: this.orderedMaterialRefinementIds,
    };
    if (this.mainProfile) props[this.mainProfile.axis.name] = this.mainProfile;
    // if (this.otherProfile) props[this.otherProfile.axis.name] = this.otherProfile;
    return new XYZComponent(props);
  }

  toApiProps(): ApiModels.Component {
    return Object.assign({}, super.toApiProps(), {
      symmetricAxis: this.mainProfile?.axis.name,
    });
  }
}

export class LatheComponent extends SymmetricComponent {
  kind: ComponentKind = "lathe";
  sidesText(): string {
    return this.otherProfile ? "all other directions" : "all sides";
  }
  supportsRefinementsFor(profile: Profile) {
    return profile.axis.name === this.otherProfile?.axis.name;
  }

  suggestedYProfileSVGFor(creationVersion: CreationVersion): SVGSVGElement | undefined {
    return this.newCircleSVG();
  }

  clone(props: SymmetricComponentCloneProps): LatheComponent {
    return new LatheComponent({
      id: this.id,
      mainProfile: props.mainProfile || this.mainProfile,
      otherProfile: props.otherProfile || this.otherProfile,
      choseDoneProfiles: props.choseDoneProfiles === undefined ? this.choseDoneProfiles : props.choseDoneProfiles,
      materialOverrides: props.materialOverrides || this.materialOverrides,
      orderedMaterialRefinementIds: props.orderedMaterialRefinementIds || this.orderedMaterialRefinementIds,
      templateNotes: props.templateNotes || this.templateNotes,
    })
  }

  tessellationFor(qualityLevel: QualityLevel) {
    switch (qualityLevel) {
      case 'High' || 'Medium':
        return 32;
      case 'Low' || 'Lowest':
        return 16;
    }
  }

  shouldFixUpNormals(): boolean {
    return false;
  }

  buildMeshes(scene: BABYLON.Scene, qualityLevel: QualityLevel): MaterialMeshGroups {
    const result = new MaterialMeshGroups();
    if (this.mainProfile instanceof TraceProfile) {
      const shapesInfo = ShapesInfo.buildFor(this.mainProfile.svg, this.mainProfile.rotationsForLatheShape(), qualityLevel);
      const shapeAlreadySeen = shapesInfo.outsideShape();
      const baseMesh = BABYLON.MeshBuilder.CreateLathe(newModelName(), {
        shape: shapeAlreadySeen.filter(ea => ea.x >= 0),
        closed: true,
        cap: BABYLON.Mesh.CAP_ALL,
        sideOrientation: BABYLON.Mesh.FRONTSIDE,
        tessellation: this.tessellationFor(qualityLevel),
      }, scene);
      this.otherProfile?.axis.rotate(baseMesh);

      let meshes: MaterialMesh[];
      if (this.otherProfile) {
        this.otherProfile.ensurePreparedForRender(qualityLevel);
        meshes = this.otherProfile.buildMeshes(scene, [baseMesh], [this.mainProfile], [], true);
      } else {
        meshes = [{ mesh: baseMesh }];
      }
      if (meshes.length > 0) {
        const scalingFactor = this.scalingFactorFor(meshes.map(ea => ea.mesh));
        meshes.forEach(ea => this.scale(ea.mesh, scalingFactor));
        let csgs = meshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh));
        const remainderWork = new CSGWork();
        const totalShapeWork = new CSGWork();
        if (this.otherProfile) {
          if (this.otherProfile instanceof TraceProfile) {
            const ts = new Date();
            const refMeshes = this.otherProfile.refinementMeshesFor(scene, meshes, true, qualityLevel, false);
            refMeshes.forEach(eaRefMesh => {
              this.applyRefinedMesh(eaRefMesh, remainderWork, totalShapeWork, false);
              result.add(eaRefMesh.meshes);
            });
          }
        }

        csgs.forEach(csg => result.intersectInPlace(totalShapeWork.runOn(csg), scene));
        csgs = csgs.map(csg => remainderWork.runOn(csg));
        meshes.forEach(ea => ea.mesh.dispose());
        const resultMeshes = csgs.map(ea => ea.toMesh(newModelName(), undefined, scene));
        // resultMesh.convertToFlatShadedMesh();
        result.add(resultMeshes.map(ea => {
          return {
            mesh: ea,
          }
        }));
      }

    }
    return result;
  }
}

export class SquaredComponent extends SymmetricComponent {
  kind: ComponentKind = "squared";
  sidesText(): string {
    return this.mainProfile?.axis.name === 'x' ?
      "front, back, top, and bottom" :
      "front, back and sides";
  }

  suggestedYProfileSVGFor(creationVersion: CreationVersion): SVGSVGElement | undefined {
    return this.newSquareSVG()
  }

  clone(props: SymmetricComponentCloneProps): SquaredComponent {
    return new SquaredComponent({
      id: this.id,
      mainProfile: props.mainProfile || this.mainProfile,
      otherProfile: props.otherProfile || this.otherProfile,
      choseDoneProfiles: props.choseDoneProfiles === undefined ? this.choseDoneProfiles : props.choseDoneProfiles,
      materialOverrides: props.materialOverrides || this.materialOverrides,
      orderedMaterialRefinementIds: props.orderedMaterialRefinementIds || this.orderedMaterialRefinementIds,
      templateNotes: props.templateNotes || this.templateNotes,
    })
  }

  materialRefinements() {
    const result = super.materialRefinements();
    result.slice().forEach(({ profile, refinement }) => {
      result.push({ profile, refinement: refinement.cloneAsReflection(true) });
    });
    result.slice().forEach(({ profile, refinement }) => {
      const otherProfile = profile.cloneWithAxis(profile.axis.otherSymmetricAxis());
      result.push({
        profile: otherProfile,
        refinement: refinement.cloneWithAxis(otherProfile.axis),
      });
    });
    return result;
  }

  buildMeshes(scene: BABYLON.Scene, qualityLevel: QualityLevel): MaterialMeshGroups {
    const result = new MaterialMeshGroups();
    if (this.mainProfile) {
      this.mainProfile.ensurePreparedForRender(qualityLevel);
      const otherSymmetricProfile = this.mainProfile.cloneWithAxis(this.mainProfile.axis.otherSymmetricAxis());
      otherSymmetricProfile.ensurePreparedForRender(qualityLevel);
      let sortedProfiles = [this.mainProfile, otherSymmetricProfile];
      if (this.otherProfile) {
        this.otherProfile.ensurePreparedForRender(qualityLevel);
        if (this.otherProfile.axis.name === 'z') {
          sortedProfiles = [this.otherProfile].concat(sortedProfiles);
        } else {
          sortedProfiles = sortedProfiles.concat([this.otherProfile]);
        }
      }
      const meshes = sortedProfiles[0].buildMeshes(scene, [], [], sortedProfiles.slice(1), true);
      const scalingFactor = this.scalingFactorFor(meshes.map(ea => ea.mesh));
      if (meshes.length > 0) {
        meshes.forEach(ea => this.scale(ea.mesh, scalingFactor));
        let csgs = meshes.map(ea => BABYLON.CSG.FromMesh(ea.mesh));
        const remainderWork = new CSGWork();
        const totalShapeWork = new CSGWork();
        sortedProfiles.forEach(ea => {
          if (ea instanceof TraceProfile) {
            const refMeshes = ea.refinementMeshesFor(scene, meshes, true, qualityLevel, false);
            refMeshes.forEach(eaRefMesh => {
              const flipped = eaRefMesh.flipped();

              this.applyRefinedMesh(eaRefMesh, remainderWork, totalShapeWork, false);
              this.applyRefinedMesh(flipped, remainderWork, totalShapeWork, false);

              result.add(eaRefMesh.meshes);
              result.add(flipped.meshes);
            });
          }
        });
        if (totalShapeWork.toIntersect.length > 0 || totalShapeWork.toSubtract.length > 0) {
          csgs.forEach(csg => result.intersectInPlace(totalShapeWork.runOn(csg), scene));
        }
        csgs = csgs.map(csg => remainderWork.runOn(csg));
        meshes.forEach(ea => ea.mesh.dispose());
        result.add(csgs.map(ea => {
          return { mesh: ea.toMesh(newModelName(), undefined, scene) }
        }));
      }

    }
    return result;
  }

}