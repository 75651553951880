import * as BABYLON from '@babylonjs/core';

export default function boundingBoxForAll(meshes: BABYLON.Mesh[]) {
  let minX = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;
  let minZ = Number.POSITIVE_INFINITY;
  let maxZ = Number.NEGATIVE_INFINITY;

  meshes.forEach(ea => {
    const min = ea.getBoundingInfo().boundingBox.minimum;
    const max = ea.getBoundingInfo().boundingBox.maximum;
    if (min.x < minX) minX = min.x;
    if (max.x > maxX) maxX = max.x;
    if (min.y < minY) minY = min.y;
    if (max.y > maxY) maxY = max.y;
    if (min.z < minZ) minZ = min.z;
    if (max.z > maxZ) maxZ = max.z;
  });

  return new BABYLON.BoundingBox(new BABYLON.Vector3(minX, minY, minZ), new BABYLON.Vector3(maxX, maxY, maxZ));
}