export function svgToPngBase64(svgString: string, dimensionsSVG?: SVGSVGElement): Promise<string> {
  return new Promise((res, rej) => {
    let svgStringToUse = svgString;
    const div = document.createElement('div');
    div.innerHTML = svgString;
    const svg = div.getElementsByTagName('svg').item(0);
    if (svg) {
      let svgForDimensions = dimensionsSVG || svg;
      document.body.appendChild(svgForDimensions);
      const rect = svgForDimensions.getBBox();
      document.body.removeChild(svgForDimensions);
      let width = 100;
      let height = 100;
      if (rect) {
        width = rect.width;
        height = rect.height;
      }
      svg.setAttribute("viewBox", `${rect.x} ${rect.y} ${width} ${height}`);
      svg.setAttribute("height", height.toString());
      svg.setAttribute("width", width.toString());
      svgStringToUse = svg.outerHTML;
      const image = new Image();
      const xml = window.btoa(unescape(encodeURIComponent(svgStringToUse)));
      image.src = "data:image/svg+xml;base64," + xml;
      let url = "";
      const targetHeight = Math.min(Math.max(height, 600), 1200);
      const scale = targetHeight / rect.height;
      const targetWidth = width * scale
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.height = targetHeight;
        canvas.width = targetWidth;
        const context = canvas.getContext("2d");
        if (context) {
          context.drawImage(image, 0, 0, targetWidth, targetHeight);
          url = canvas.toDataURL("image/" + "png");
        }
        res(url);
      };
    } else {
      rej("Couldn't build SVG")
    }

  });
};