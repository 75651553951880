import * as BABYLON from '@babylonjs/core';
import update from 'immutability-helper';

import { Creation as LocalCreation, CreationVersion as LocalCreationVersion, MaterialMeshGroups } from './creations/Creation';
import { Axis, AxisName, newAxisNamed, XAxis, YAxis, ZAxis } from './creations/Axis';
import {
  Backing as LocalBacking,
  BackingKind,
  CircleBacking,
  Concavity,
  DefaultConcavity,
  FillHolesBacking,
  HullBacking,
  NoBacking,
} from './creations/Backing';
import {
  Component as LocalComponent,
  ComponentKind,
  LatheComponent,
  MaterialOverride as LocalMaterialOverride,
  SquaredComponent,
  XYZComponent,
} from './creations/Component';
import { buildProfile, ProfileKind } from './creations/Profile';
import { getMaterial, MaterialKind } from './creations/Material';
import { buildRefinementsFrom, RefinementApplicationType } from './creations/TraceProfileRefinement';
import { svgElementFor } from './helpers';
import { QualityLevel } from './models';
import newID from './ids';

export interface User {
  id: string,
  email?: string,
  freeOrderCode?: string,
}

export interface ProfileRefinementTemplateNotes {
  beforeAdded?: string,
  beforeEditing?: string,
  editing?: string,
  beforeMaterialChanged?: string,
  beforeMirrored?: string,
  beforeMirrorFlipped?: string,
  beforeIsSurfaceChanged?: string,
  beforeChangeApplicationType?: string,
}

export interface ProfileRefinement {
  id: string,
  minProportion: number,
  maxProportion: number,
  applicationType?: RefinementApplicationType,
  isSurface?: boolean,
  isMirrored?: boolean,
  isMirrorFlipped?: boolean,
  isDisabled?: boolean,
  material?: MaterialKind,
  svg?: string,
  templateNotes?: ProfileRefinementTemplateNotes,
}

export function applicationTypeFor(ref: ProfileRefinement): RefinementApplicationType {
  return ref.applicationType || (!!ref.isSurface ? 'surface' : 'shape');
}

export interface ProfileTemplateNotes {
  beforeEditing?: string,
  editing?: string,
  choosingRotations?: string,
  beforeChangingTrace?: string,
  changingTrace?: string,
}

export interface BackingTemplateNotes {
  choosing?: string,
}

export interface Backing {
  kind: BackingKind;
  concavity?: Concavity,
  templateNotes?: BackingTemplateNotes,
}

export interface Profile {
  kind: ProfileKind,
  rotations?: number,
  refinements?: ProfileRefinement[],
  backing?: Backing,
  svg?: string,
  initialSVG?: string,
  templateNotes?: ProfileTemplateNotes,
}

export interface Profiles {
  [axis: string]: Profile | undefined,
}

export interface Vector3 {
  x: number,
  y: number,
  z: number,
}

export interface Price {
  currency: string,
  unitAmount: number,
}

export interface MaterialOverrideTemplateNotes {
  beforeAdded?: string,
}

export interface MaterialOverride {
  id: string,
  material: MaterialKind,
  minPoint: Vector3,
  maxPoint: Vector3,
  templateNotes?: MaterialOverrideTemplateNotes,
}

export interface MainMaterialTemplateNotes {
  beforeAdded?: string,
}

export interface CreationTemplateNotes {
  mainMaterial?: MainMaterialTemplateNotes,
  renderQualityLevel?: string,
  start?: string,
  done?: string,
}

export interface Creation {
  creationId: string,
  versionId: string,
  parentVersionId?: string,
  userId?: string,
  name?: string,
  components?: Component[],
  decorationMaterial?: MaterialKind,
  kind?: string,
  email?: string,
  isInTrash?: boolean,
  useNightLighting?: boolean,
  thumbnailGifUrl?: string,
  thumbnailVideoUrl?: string,
  thumbnailPngUrl?: string,
  templateCreationId?: string,
  updatedAt?: string,
  renderQualityLevel?: QualityLevel,
  templateNotes?: CreationTemplateNotes,
}

export interface Label {
  id: string,
  name: string,
}

export interface ComponentKindTemplateNotes {
  beforeChoosing?: string,
  choosing?: string,
}

export interface ComponentTemplateNotes {
  kind?: ComponentKindTemplateNotes,
  choseDoneProfiles?: string,
  materialRefinementOrdering?: string,
}

export interface Component {
  id: string,
  kind: ComponentKind,
  material?: MaterialKind,
  profiles?: Profiles,
  symmetricAxis?: AxisName,
  choseDoneProfiles?: boolean,
  materialOverrides?: MaterialOverride[],
  orderedMaterialRefinementIds?: string[],
  templateNotes?: ComponentTemplateNotes,
}

export interface ThumbnailJob {
  jobId: string,
  creationId: string,
  versionId: string,
  width: number,
  height: number,
  screenshotCount: number,
  isCancelled: boolean,
}

export type ModelRequestType = 'self-print' | 'roblox';

export interface ModelRequest {
  creationVersionId: string,
  requestType: ModelRequestType,
}

export interface CartUpdate {
  items: UpdateCartItem[],
}

export interface ResponseCartItem {
  creationVersion: Creation,
  scale: number,
  price: Price,
  quantity: number,
  baseExtendSize: Vector3,
}

export interface UpdateCartItem {
  creationVersionId: string,
  scale: number,
  price: Price,
  quantity: number,
  baseExtendSize: Vector3,
}

export interface FreeOrderItem {
  description: string,
  creationVersion: Creation,
  scale: number,
  price: Price,
  quantity: number,
}

export interface FreeOrder {
  items: FreeOrderItem[],
}


export function buildMaterialOverrideFrom(data: MaterialOverride): LocalMaterialOverride {
  return new LocalMaterialOverride({
    id: data.id,
    material: getMaterial(data.material),
    minPoint: new BABYLON.Vector3(data.minPoint.x, data.minPoint.y, data.minPoint.z),
    maxPoint: new BABYLON.Vector3(data.maxPoint.x, data.maxPoint.y, data.maxPoint.z),
    templateNotes: data.templateNotes,
  });
}

export function buildBackingFrom(data: Backing | undefined): LocalBacking | undefined {
  if (data === undefined) return undefined;
  switch (data.kind) {
    case 'fill-holes':
      return new FillHolesBacking(data.templateNotes);
    case 'hull':
      return new HullBacking(data.concavity || DefaultConcavity, data.templateNotes);
    case 'circle':
      return new CircleBacking(data.templateNotes);
    case 'chose-none':
      return new NoBacking(data.templateNotes);
    default:
      return undefined;
  }
}

export async function buildComponentFrom(data: Component): Promise<LocalComponent> {
  const id = data.id || newID();
  const p = data.profiles;

  const z = new ZAxis();
  const zSVG = svgElementFor(p?.z?.svg);
  const initialZSVG = svgElementFor(p?.z?.initialSVG);
  const originalZFile = undefined; //await profileFileFor(id, z);
  const zRefinements = await buildRefinementsFrom(z, p?.z?.refinements);
  const zProfile = await buildProfile(
    z,
    p?.z?.kind,
    p?.z?.rotations,
    originalZFile,
    zSVG,
    initialZSVG,
    zRefinements,
    buildBackingFrom(p?.z?.backing),
    p?.z?.templateNotes
  );

  const x = new XAxis();
  const xSVG = svgElementFor(p?.x?.svg);
  const initialXSVG = svgElementFor(p?.x?.initialSVG);
  const originalXFile = undefined; //await profileFileFor(id, x);
  const xRefinements = await buildRefinementsFrom(x, p?.x?.refinements);
  const xProfile = await buildProfile(
    x,
    p?.x?.kind,
    p?.x?.rotations,
    originalXFile,
    xSVG,
    initialXSVG,
    xRefinements,
    buildBackingFrom(p?.x?.backing),
    p?.x?.templateNotes
  );

  const materialOverrides = data.materialOverrides ? data.materialOverrides.map(buildMaterialOverrideFrom) : [];

  const y = new YAxis();
  const ySVG = svgElementFor(p?.y?.svg);
  const initialYSVG = svgElementFor(p?.y?.initialSVG);
  const originalYFile = undefined; //await profileFileFor(id, y);
  const yRefinements = await buildRefinementsFrom(y, p?.y?.refinements);
  const yProfile = await buildProfile(
    y,
    p?.y?.kind,
    p?.y?.rotations,
    originalYFile,
    ySVG,
    initialYSVG,
    yRefinements,
    buildBackingFrom(p?.y?.backing),
    p?.y?.templateNotes
  );

  const orderedMaterialRefinementIds = data.orderedMaterialRefinementIds || [];
  if (data.kind === "lathe") {
    const symmetricAxis = data.symmetricAxis || 'z';
    return new LatheComponent({
      id: id,
      mainProfile: symmetricAxis === 'z' ? zProfile : xProfile,
      otherProfile: symmetricAxis === 'z' ? yProfile : zProfile,
      choseDoneProfiles: !!data.choseDoneProfiles,
      materialOverrides: materialOverrides,
      orderedMaterialRefinementIds: orderedMaterialRefinementIds,
      templateNotes: data.templateNotes,
    });
  } else if (data.kind === "squared") {
    const symmetricAxis = data.symmetricAxis || 'z';
    return new SquaredComponent({
      id: id,
      mainProfile: symmetricAxis === 'z' ? zProfile : xProfile,
      otherProfile: symmetricAxis === 'z' ? yProfile : zProfile,
      choseDoneProfiles: !!data.choseDoneProfiles,
      materialOverrides: materialOverrides,
      orderedMaterialRefinementIds: orderedMaterialRefinementIds,
      templateNotes: data.templateNotes,
    });
  } else {
    return new XYZComponent({
      id: id,
      x: xProfile,
      y: yProfile,
      z: zProfile,
      choseDoneProfiles: !!data.choseDoneProfiles,
      materialOverrides: materialOverrides,
      orderedMaterialRefinementIds: orderedMaterialRefinementIds,
      templateNotes: data.templateNotes,
    });
  }
}

export async function buildCreationFrom(
  data: Creation,
  scene: BABYLON.Scene,
  decorationMeshes: MaterialMeshGroups | undefined,
): Promise<LocalCreationVersion | undefined> {
  const creation = new LocalCreation({
    id: data.creationId,
    userId: data.userId,
  });
  const components = await Promise.all((data.components || []).map(ea => buildComponentFrom(ea)));
  return new LocalCreationVersion({
    creation: creation,
    id: data.versionId,
    parentVersionId: data.parentVersionId,
    userId: data.userId,
    name: data.name,
    scene: scene,
    components: components,
    decorationMaterial: getMaterial(data.decorationMaterial || "charcoal"),
    useNightLighting: !!data.useNightLighting,
    thumbnailGifUrl: data.thumbnailGifUrl,
    thumbnailVideoUrl: data.thumbnailVideoUrl,
    thumbnailPngUrl: data.thumbnailPngUrl,
    templateCreationId: data.templateCreationId,
    templateNotes: data.templateNotes,
    decorationMeshes: decorationMeshes,
    renderQualityLevel: data.renderQualityLevel || 'Low',
    createdAt: data.updatedAt ? new Date(Number.parseInt(data.updatedAt)) : undefined,
  });
}

export function componentFor(creation: Creation | undefined): Component | undefined {
  const components = creation?.components;
  return components ? components[0] : undefined;
}

export function orderedMaterialRefinementsFor(creation: Creation | undefined): ProfileRefinement[] {
  const component = componentFor(creation);
  const ids = component?.orderedMaterialRefinementIds || [];
  const allRefinements = allRefinementsFor(creation);
  const result: ProfileRefinement[] = [];
  ids.forEach(ea => {
    const found = allRefinements.find(ref => ref.id === ea);
    if (found) {
      result.push(found);
    }
  });
  return result;
}

export function allRefinementsFor(creation: Creation | undefined): ProfileRefinement[] {
  const profiles = profilesFor(creation);
  const result: ProfileRefinement[] = [];
  profiles.forEach(ea => {
    result.push(...ea.refinements || []);
  });
  return result;
}

export function profilesFor(creation: Creation | undefined): Profile[] {
  let profiles: Profile[] = [];
  const component = componentFor(creation);
  if (component) {
    if (component && component.profiles) {
      if (component.profiles.x) profiles.push(component.profiles.x);
      if (component.profiles.y) profiles.push(component.profiles.y);
      if (component.profiles.z) profiles.push(component.profiles.z);
    }
  }
  return profiles;
}

export function profileFor(creation: Creation | undefined, axis: Axis): Profile | undefined {
  const component = componentFor(creation);
  if (component) {
    if (component && component.profiles) {
      return component.profiles[axis.name];
    }
  }
  return undefined;
}

export function isSymmetric(creation: Creation): boolean {
  const component = componentFor(creation);
  if (component) {
    return component.kind === 'lathe' || component.kind === 'squared';
  }
  return false;
}

export function overrideFor(overrideId: string, creation: Creation): MaterialOverride | undefined {
  const component = componentFor(creation);
  return component?.materialOverrides?.find(ea => ea.id === overrideId);
}

export function sortedRefinementsFor(profile: Profile | undefined): ProfileRefinement[] {
  if (profile && profile.refinements) {
    const nonMaterial: ProfileRefinement[] = [];
    const material: ProfileRefinement[] = [];
    profile.refinements.forEach(ea => {
      if (ea.applicationType === 'shape' || ea.applicationType === 'surface') {
        nonMaterial.push(ea);
      } else {
        material.push(ea);
      }
    });
    return [...nonMaterial, ...material];
  } else {
    return [];
  }
}

export function sortedRefinementFor(refinementIndex: number, axis: Axis, creation: Creation): ProfileRefinement | undefined {
  const profile = profileFor(creation, axis);
  return sortedRefinementsFor(profile)[refinementIndex];
}

export function withProfileRefinementTemplateNotes(
  notesToMerge: ProfileRefinementTemplateNotes,
  refinement: ProfileRefinement,
  refinementIndex: number,
  axisName: AxisName,
  creation: Creation
): Creation {
  const component = componentFor(creation);
  const axis = newAxisNamed(axisName);
  const profile = profileFor(creation, axis);
  if (component && profile) {
    const refinements = profile?.refinements || [];
    const updatedTemplateNotes: ProfileRefinementTemplateNotes = Object.assign({}, refinement.templateNotes, notesToMerge);
    const newRefinement = Object.assign({}, refinement, { templateNotes: updatedTemplateNotes });
    const updatedRefinements = update(refinements, {
      $splice: [
        [refinementIndex, 1, newRefinement]
      ],
    });
    const updatedProfile = Object.assign({}, profile, { refinements: updatedRefinements });
    const updatedProfiles: Profiles = Object.assign({}, component.profiles, { [axisName]: updatedProfile });
    const updatedComponent = Object.assign({}, component, { profiles: updatedProfiles });
    return Object.assign({}, creation, { components: [updatedComponent] });
  } else {
    return creation;
  }
}